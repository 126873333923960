import isEmpty from 'lodash.isempty';
import * as storage from './storage';
import getRoles from '../utils/get-roles';
import {
  ACCEPT_COOKIES,
  AUTHENTICATE_PENDING,
  AUTHENTICATE_SUCCESS,
  COMPLETE_ONBOARDING,
  END_PENDING_SUBSCRIPTION,
  FETCH_AGENT_SUCCESS,
  FETCH_AMITY_TOKEN,
  FETCH_SEARCH_SUCCESS,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_BROKERAGES_SUCCESS,
  FETCH_LIFESTYLES_SUCCESS,
  FETCH_PERSON_SUCCESS,
  FETCH_SETTINGS_SUCCESS,
  RESIZE_END,
  SET_PENDING_SUBSCRIPTION,
  SCROLLING_END,
  SWITCH_CURRENCY,
  SWITCH_DIRECTION,
  SWITCH_LOCALE,
  SWITCH_MEASURE,
  UPDATE_AGENT_SUCCESS,
  UPDATE_PERSON_SUCCESS,
  UPDATE_SUBSCRIPTION,
  UPDATE_TAGS_SUCCESS,
  UPDATE_PLACE,
  SET_GROUP,
  NEXT_DASHBOARD,
  CLEAR_PASTDUE_PAYMENT,
} from '../actions/types';

import api from '../api';

export default function persistenceHandler(next) {
  return (reducer, initialState) => {
    const store = next(reducer, initialState);
    return {
      ...store,
      dispatch(action) {
        store.dispatch(action);
        if (action) {
          if (
            action.type === '@@router/LOCATION_CHANGE' &&
            !action.payload.isFirstRendering
          ) {
            // storage.put('lastUpdated', Date.now());
          }
          if (action.type === AUTHENTICATE_PENDING) {
            storage.put('lastUpdated', Date.now());
          }
          if (action.type === ACCEPT_COOKIES) {
            storage.put('acceptCookies', action.payload);
          }
          if (action.type === COMPLETE_ONBOARDING) {
            storage.remove('isOnboarding');
          }
          if (action.type === AUTHENTICATE_SUCCESS) {
            const newState = store.getState().application;
            storage.put('token', action.payload.token);
            if (action.payload.rememberMe) {
              storage.put('refreshToken', action.payload.refreshToken);
            }
            api.setToken({
              token: action.payload.token,
              refreshToken: action.payload.rememberMe ? action.payload.refreshToken : undefined,
            });
            storage.put('amityToken', action.payload.amityToken);
            storage.put('me', JSON.stringify(action.payload.user));
            const groups = action.payload.user.groups
              ? JSON.stringify(action.payload.user.groups)
              : null;
            storage.put('groups', groups);

            storage.put('isAdmin', getRoles(action.payload, 'admin'));
            storage.put('isAgent', getRoles(action.payload, 'agent'));
            storage.put(
              'isBillingAdmin',
              getRoles(action.payload, 'billingAdmin')
            );
            storage.put('isConcierge', getRoles(action.payload, 'concierge'));
            storage.put('isDemo', getRoles(action.payload, 'demo'));
            storage.put('isRealmAdmin', getRoles(action.payload, 'realmAdmin'));
            storage.put(
              'isRealmExecutive',
              getRoles(action.payload, 'realmExecutive')
            );
            storage.put('locale', newState.locale);
            storage.put('lastUpdated', Date.now());
          }
          if (action.type === FETCH_AMITY_TOKEN) {
            const newState = store.getState().application;
            storage.put('amityToken', action.payload.amityToken);
          }
          if (action.type === END_PENDING_SUBSCRIPTION) {
            storage.put('hasPendingSubscription', 'false');
          }
          if (action.type === FETCH_AGENT_SUCCESS) {
            const newState = store.getState().session;
            storage.put('agentData', JSON.stringify(action.payload));
          }
          if (action.type === FETCH_AGENT_SUCCESS) {
            const newState = store.getState().session;
            storage.put('agentData', JSON.stringify(action.payload));
            storage.put(
              'avatar',
              newState.avatar || storage.get('avatar') || ''
            );
            storage.put('isDeveloper', String(action.payload.currentBrokerage.isDeveloper))
          }
          if (action.type === FETCH_BROKERAGES_SUCCESS) {
            storage.put(
              'brokerages',
              JSON.stringify(action.payload.brokerages)
            );
            storage.put(
              'totalBrokerages',
              JSON.stringify(action.payload.totalBrokerages)
            );
          }
          if (action.type === FETCH_LIFESTYLES_SUCCESS) {
            storage.put(
              'lifestyles',
              JSON.stringify(action.payload.lifestyles)
            );
            storage.put('allTags', JSON.stringify(action.payload.allTags));
            storage.put('tags', JSON.stringify(action.payload.tags));
          }
          if (action.type === FETCH_PERSON_SUCCESS) {
            const newState = store.getState().session;
            storage.put('me', JSON.stringify(action.payload));
            storage.put('person', JSON.stringify(action.payload.person));
            const groups = action.payload.groups
              ? JSON.stringify(action.payload.groups)
              : null;

            storage.put('groups', groups);
            storage.put(
              'hasCancelledSubscription',
              newState.hasCancelledSubscription
            );
            storage.put(
              'hasSuspendedSubscription',
              newState.hasSuspendedSubscription
            );
            storage.put('hasSubscription', newState.hasSubscription);
            storage.put(
              'hasSubscriptionExpired',
              newState.hasSubscriptionExpired
            );
            storage.put('hasTrialExpired', newState.hasTrialExpired);
            storage.put('hasTrialSubscription', newState.hasTrialSubscription);
            storage.put('hasPastDuePayment', newState.hasPastDuePayment);
            storage.put('isTeamLeadSub', newState.isTeamLeadSub);
+           storage.put('isTeamMemberSub', newState.isTeamMemberSub);
            storage.put('subscription', JSON.stringify(newState.subscription));
            storage.put('avatar', newState.avatar || '');
            const subscription =
              action.payload.subscriptions &&
              action.payload.subscriptions.length > 0
                ? action.payload.subscriptions[0]
                : false;
            if (subscription) {
              storage.put(
                'hasCancelledSubscription',
                String(subscription.status === 'Cancelled')
              );
              storage.put(
                'hasSuspendedSubscription',
                String(subscription.status === 'Suspended')
              );
              storage.put(
                'hasTrialSubscription',
                String(subscription.status === 'Trial')
              );
              storage.put('hasSubscription', String(!isEmpty(subscription)));
              storage.put(
                'hasSubscriptionExpired',
                subscription.endsAt
                  ? String(new Date(subscription.endsAt).getTime() < Date.now())
                  : 'false'
              );
              storage.put(
                'hasTrialExpired',
                subscription.trialExpires
                  ? String(
                      new Date(subscription.trialExpires).getTime() < Date.now()
                    )
                  : 'false'
              );
            }
          }
          if (action.type === FETCH_SETTINGS_SUCCESS) {
            storage.put(
              'maintenanceMode',
              action.payload.maintenanceMode ? 'true' : 'false'
            );
            storage.put('systemMessage', action.payload.message);
            storage.put(
              'showTeamSubscription',
              action.payload.showTeamSubscription ? 'true' : 'false'
            );
            storage.put(
              'showAmitySocial',
              action.payload.showAmitySocial ? 'true' : 'false'
            );
            storage.put(
              'showChat',
              action.payload.showChat ? 'true' : 'false'
            );
            storage.put(
              'showTribe',
              action.payload.showTribe ? 'true' : 'false'
            );
            if (action.payload.usePublicListings) {
              storage.put('usePublicListings', 'true');
            }
          }
          if (action.type === FETCH_SEARCH_SUCCESS) {
            storage.put('searchSettings', action.payload.searchSettings.data);
          }
          if (action.type === NEXT_DASHBOARD) {
            storage.put('dashboardRotation', action.payload);
          }
          if (action.type === FETCH_DASHBOARD_SUCCESS) {
            storage.put('dashboardSettings', action.payload.dashboardSettings.data);
            storage.put('dashboardRotation', action.payload.dashboardRotation);
          }
          if (action.type === SET_PENDING_SUBSCRIPTION) {
            storage.put('hasPendingSubscription', 'true');
          }
          if (action.type === SWITCH_CURRENCY) {
            storage.put('currency', action.payload);
          }
          if (action.type === SWITCH_DIRECTION) {
            storage.put('isRTL', action.payload);
          }
          if (action.type === SWITCH_LOCALE) {
            storage.put('locale', action.payload);
          }
          if (action.type === SWITCH_MEASURE) {
            storage.put('measure', action.payload);
          }
          if (action.type === UPDATE_AGENT_SUCCESS) {
            storage.put('agentData', JSON.stringify(action.payload));
          }
          if (action.type === UPDATE_PERSON_SUCCESS) {
            storage.put('person', JSON.stringify(action.payload));
            const me = JSON.parse(storage.get('me'));
            const newMe = { ...me, person: action.payload };
            storage.put('me', JSON.stringify(newMe));
            if (storage.get('isAgent')) {
              const agentData = JSON.parse(storage.get('agentData'));
              const newAgentData = { ...agentData, person: action.payload };
              storage.put('agentData', JSON.stringify(newAgentData));
            }
          }
          if (action.type === UPDATE_SUBSCRIPTION) {
            storage.put('subscription', JSON.stringify(action.payload));
            storage.put(
              'hasCancelledSubscription',
              String(action.payload.status === 'Cancelled')
            );
            storage.put(
              'hasSuspendedSubscription',
              String(action.payload.status === 'Suspended')
            );
            storage.put(
              'hasTrialSubscription',
              String(action.payload.status === 'Trial')
            );
            storage.put('hasSubscription', String(!isEmpty(action.payload)));
            storage.put(
              'hasSubscriptionExpired',
              action.payload.endsAt
                ? String(new Date(action.payload.endsAt).getTime() < Date.now())
                : 'false'
            );
            storage.put(
              'hasTrialExpired',
              action.payload.trialExpires
                ? String(
                    new Date(action.payload.trialExpires).getTime() < Date.now()
                  )
                : 'false'
            );
          }
          if (action.type === SCROLLING_END || action.type === RESIZE_END) {
            storage.put('lastUpdated', Date.now());
          }
          if (action.type === UPDATE_TAGS_SUCCESS) {
            const newState = store.getState().session;
            storage.put('tags', JSON.stringify(newState.tags));
          }
          if (action.type === SET_GROUP) {
            storage.put('selectedGroup', JSON.stringify(action.payload));
          }
          if (action.type === UPDATE_PLACE) {
            storage.put('pid', JSON.stringify(action.payload))
          }
          if (action.type === CLEAR_PASTDUE_PAYMENT) {
            storage.put('hasPastDuePayment', 'false')
          }
        }
        return action;
      }
    };
  };
}
