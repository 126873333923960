import createReducer from "./create-reducer";
import * as types from "../actions/types";

const initialState = {
  isOpen: false,
  isStuck: false,
}

const actionHandlers = {
  [types.OPEN_DRAWER]: () => ({
    isOpen: true,
  }),

  [types.CLOSE_DRAWER]: () => ({
    isOpen: false,
  }),

  [types.TOGGLE_DRAWER]: (state) => ({
    isOpen: !state.isOpen,
  }),

  [types.STICK_DRAWER]: () => ({
    isStuck: true,
  }),

  [types.UNSTICK_DRAWER]: () => ({
    isStuck: false,
  }),

  [types.TOGGLE_DRAWER_STICK]: (state) => ({
    isStuck: !state.isStuck,
  }),
}

export default createReducer(initialState, actionHandlers);
