/* eslint-disable no-undef */
import mixpanel from 'mixpanel-browser';
import { MIXPANEL_TOKEN, MIXPANEL_DEBUG} from '../constants';
/**
 * TESTING
   if you want to test a specific environment, change the value returned in get-domain.js for the localhost
 * @param {string} page lower case.  Use hypen for disambiguation. e.g., dashboard-header, agents-nav
 * @param {string} action Title case. Use only the event if possible. e.g., Search, Send Email
 * @param {string} description non-PII information such as Street Address of a listing, Name of prospect search, City of a search
 * @param {string} details additional semi-colon separated meta data such as search filter as a string, such as 'tags: skiing, hiking; location: Chicago, Ill;'
 * @param {number} value a numercal value such as the number of tags changed
 * @example
 * //Ex: Dashboard, View My Listings
 * eventTracking( 'dashboard', 'View My Listing', '1234 Main Street, Boulder, CO', 'Display Option 5', 0);
 * 
 */
export default function eventTracking(
  page,
  action,
  description = '',
  details = '',
  value = 0,
) {
  const me = localStorage.getItem('me');
  const isImpersonated = me && me.includes('impersonation');
  const parsedMe = me ? JSON.parse(me) : {};
  const userId = me ? parsedMe._id : 'Not signed in';
  const roles = me ? parsedMe.roles : ['visitor'];
  if (roles.includes('skipGA') || isImpersonated) {
    return;
  }
  let userRole = 'unknown';
  if (roles.includes('admin') || roles.includes('superadmin')) {
    userRole = 'admin';
  } else if (roles.includes('agent')) {
    userRole = 'member';
  } else if (roles.includes('concierge') || roles.includes('realmAdmin')) {
    userRole = 'MET'
  } else if (roles.includes('qa')) {
    userRole = 'QA';
  }

  // mixpanel init
  // Enabling the debug mode flag is useful during implementation,
  // but it's recommended you remove it for production
  const mpt = MIXPANEL_TOKEN;
  mixpanel.init(mpt, { 
    opt_out_tracking_by_default: false, 
    debug: MIXPANEL_DEBUG,
  });
  const optOut = mixpanel.has_opted_out_tracking();

  const urlParts = document.URL.split('//');
  const domainParts = urlParts[1].split('/');
  const domain = `${domainParts[0]
    .replace(':8005', '')
    .replace('.realm-global.com', '')
    .replace('agent', 'prod')}`;
  const pageWithId = `${domain}-${page}-${userId}`;
  // const pageWithId = process.env.NODE_ENV.startsWith('prod')
  //   ? `${page}-${userId}`
  //   : `${process.env.NODE_ENV.toUpperCase()}-${page}-${userId}`
  // eslint-disable-next-line no-undef
  const gaValue = action !== 'Sign In' || optOut === false ? value : 9999;
  const gaAction = description ? `${action}-${description} ${details}` : action;
  ga('send', 'event', pageWithId, gaAction, description.trim(), gaValue);
  //send a gtag event for google analytics with the same data as the ga event
  gtag('event', action, { environment: domain, realm_page: page, user: userId, description, event_detail: details, value: gaValue });

  // mixpanel event

// mixpanel.opt_in_tracking();
  if (me && action === 'Sign In') {
    mixpanel.identify(userId)
  }

  mixpanel.track(action, {
    environment: domain,
    page,
    userId,
    role: userRole,
    description,
    details,
    value,
  }
  );
}
