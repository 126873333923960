/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField
} from '@material-ui/core';
import isFunction from 'lodash.isfunction';

import api from '../../../api';
import LoadingIndicator from '../../../components/LoadingIndicator';
import localstring from '../../../services/Localization';
import { API_ROOT } from '../../../constants';
import isValidEmail from '../../../utils/is-valid-email';

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isError: false,
      isSending: false,
      isSuccess: false,
      isValidForm: false,
      showStatusModal: false,
      email: '',
      message: '',
      yourName: ''
    };
  }

  _delay = null;

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    if (this._delay) {
      clearTimeout(this._delay);
      this._delay = null;
    }
    this._isMounted = false;
  }

  submit = async () => {
    this.setState({
      showStatusModal: true,
      isSending: true
    });
    try {
      const response = await api.post(`${API_ROOT}/user/contact`, {
        siteForm: 'AdminSite',
        subject: 'Help with My Account',
        message: this.createMessage()
      });
      if (response.status === 200 && this._isMounted) {
        this.setState(
          {
            isSending: false,
            isSuccess: true
          },
          () => {
            if (isFunction(this.props.onClose)) {
              this.props.onClose();
            }
          }
        );
      }
    } catch (ex) {
      const msg = ex.response ? ex.response.data.msg : ex.msg;
      console.error(msg);
      this._isMounted &&
        this.setState(
          {
            isSending: false,
            isError: true
          },
          () => {
            if (isFunction(this.props.onClose)) {
              this.props.onClose();
            }
          }
        );
    }
  };

  isValidForm = () => {
    const hasValidRequired =
      isValidEmail(this.state.email) &&
      this.state.yourName !== '' &&
      this.state.message !== '';
    return hasValidRequired;
  };

  createMessage = () => {
    const emailLine = `Message from: ${this.state.yourName}\n`;
    const returnEmailLine = `Return email: ${this.state.email}\n`;
    const subjectLine = this.props.incomingPage
      ? `Subject: Issues with paywall: From ${this.props.incomingPage}\n`
      : 'Subject: Issues with paywall';
    const messageLine = `Message: ${this.state.message}`;
    return emailLine + returnEmailLine + subjectLine + messageLine;
  };

  handleChange = name => e => {
    this.setState(
      {
        [name]: e.target.value
      },
      () => {
        this.setState({
          isValidForm: this.isValidForm()
        });
      }
    );
  };

  selectCancelButton = () => {
    if (isFunction(this.props.onClose)) {
      this.props.onClose();
    }
  };

  close = () => {
    this.setState(
      {
        showStatusModal: false
      },
      () => {
        this._delay = setTimeout(() => {
          this.setState({
            isError: false,
            isSending: false,
            isSuccess: false,
            isValidForm: false,
            showStatusModal: false,
            email: '',
            message: '',
            yourName: ''
          });
        }, 300);
      }
    );
  };

  renderStatusDialogContent = () => {
    const { isError, isSuccess } = this.state;
    return (
      <>
        {isError && <DialogTitle>Error sending your email.</DialogTitle>}
        {isSuccess && <DialogTitle>Success! Your email was sent.</DialogTitle>}
        <DialogContent className="realm--modal-content">
          {isSuccess && (
            <div>
              Thank you for your message. Please expect a response from a REALM
              representative within 1-2 business days.
            </div>
          )}
          {isError && (
            <div>
              Your email could not be sent at this time. Please either try again
              or contact a REALM representative directly at{' '}
              <a
                style={{ textDecoration: 'underline' }}
                href="mailto:concierge@realm-global.com"
              >
                concierge@realm-global.com
              </a>{' '}
              for further assistance. We apologize for the inconvenience.
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.close} color="primary" variant="contained">
            {localstring.onboarding.ok}
          </Button>
        </DialogActions>
      </>
    );
  };

  render() {
    return (
      <>
        <Dialog
          aria-describedby="contact-help-dialog"
          aria-labelledby="contact-help-dialog-title"
          onClose={this.props.onClose}
          open={this.props.open}
          fullWidth
          size="medium"
        >
          <DialogTitle style={{ minWidth: 300 }} id="contact-help-dialog-title">
            Contact the Concierge
          </DialogTitle>
          <DialogContent style={{ color: '#fff' }}>
            <div style={{ marginBottom: 10 }}>
              <TextField
                id="yourName"
                fullWidth
                helperText=""
                label={localstring.your_name}
                margin="dense"
                name="yourName"
                onChange={this.handleChange('yourName')}
                required
                type="text"
                value={this.state.yourName}
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <TextField
                id="email"
                fullWidth
                helperText=""
                label={localstring.email_address}
                margin="dense"
                name="email"
                onChange={this.handleChange('email')}
                required
                type="text"
                value={this.state.email}
              />
            </div>
            <div>
              <p style={{ marginBottom: 6 }}>{localstring.contact_message}</p>
              <textarea
                rows="12"
                style={{ width: '100%', padding: 12 }}
                onInput={this.handleChange('message')}
                value={this.state.message}
                placeholder="Please type your message here..."
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.selectCancelButton}
              color="default"
              variant="contained"
            >
              {localstring.cancel}
            </Button>
            <Button
              onClick={this.submit}
              color="primary"
              variant="contained"
              disabled={!this.state.isValidForm}
            >
              {localstring.contact_send}
            </Button>
          </DialogActions>
        </Dialog>

        {/* email status dialog */}
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="md"
          open={this.state.showStatusModal}
        >
          {this.state.isSending && (
            <div
              className="realm--modal-content"
              style={{ position: 'relative' }}
            >
              <div style={{ width: '100%', textAlign: 'center' }}>
                <p style={{ color: '#fff' }}>Sending email ...</p>
                <div style={{ width: 32, margin: '0 auto' }}>
                  <LoadingIndicator style={{ marginLeft: -16 }} />
                </div>
              </div>
            </div>
          )}
          {!this.state.isSending && this.renderStatusDialogContent()}
        </Dialog>
      </>
    );
  }
}

ContactUs.propTypes = {
  incomingPage: PropTypes.string,
  language: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

export default ContactUs;
