import * as types from '../actions/types';

const initialState = {
  propertyIds: [],
  filters: ""
};

const propertiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_PROPERTY_IDS:
      return {
        ...state,
        propertyIds: action.payload
      };
    case types.SET_FILTERS:
      return {
        ...state,
        filters: action.payload
      };
    default:
      return state;
  }
};

export default propertiesReducer;
