import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import LoginForm from '../../components/Login/LoginForm';

import api from '../../api';
import { API_ROOT } from '../../constants';
import getParameterByName from '../../utils/get-parameter-by-name';

class Welcome extends Component {
  constructor(props) {
    super(props);
    // const id = getParameterByName('memberId');
    const id = getParameterByName('utm_campaign');
    this.state = {
      id,
      // firstName: null,
      showPage: Boolean(id),
      username: null
    };
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    const app = document.getElementById('app');
    app.style.overflow = 'hidden';
    if (this.state.id !== 'user-Welcome') {
      this.goToLoginPage();
    }
    // this.getMemberInfo();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  goToLoginPage = () => {
    this.props.push('/login');
  };

  getMemberInfo = async () => {
    try {
      const response = await api.get(
        `${API_ROOT}/user/welcome/${this.state.id}`
      );
      const { data } = response.data;
      if (data.err) {
        this.goToLoginPage();
        return;
      }
      if (this._isMounted) {
        this.setState({
          firstName: data.data.firstName,
          showPage: true,
          username: data.data.email
        });
      }
    } catch (err) {
      this.goToLoginPage();
    }
  };

  render() {
    const { session } = this.props;
    return (
      <div
        className={`realm--login-screen${this.state.showPage ? ' fadeIn' : ''}`}
      >
        <header role="banner" className="realm--login-screen-header">
          <span className="realm--login-screen-header-logo">
            <a href="https://www.realm-global.com/">
              <img
                src="https://wp-realm-marketing.s3.amazonaws.com/images/REALM_LogoWhite_R.png"
                alt="REALM"
              />
            </a>
          </span>
        </header>
        <div className="realm--login-screen-content-wrapper">
          <div className="realm--login-screen-backdrop">
            <div className="realm--login-screen-content-container">
              <div className="realm--login-screen-pic-overlay" />
              <div className="realm--login-screen-content">
                <main
                  className="realm--login-screen-main"
                  role="main"
                  itemProp="mainContentOfPage"
                >
                  <div className="realm--login-screen-container">
                    <div className="realm--login-screen-wrapper">
                      <h1
                        className="realm--login-screen-headline"
                        itemProp="headline"
                      >
                        Welcome to the new{' '}
                        <span className="realm--login-screen-realm">REALM</span>{' '}
                        <br />
                        of luxury real estate, <br />
                        we are glad you are here.
                      </h1>
                      <div className="realm--login-screen-divider-container">
                        <div className="realm--login-screen-divider" />
                      </div>
                      <div className="realm--login-screen-actions form-container">
                        <LoginForm
                          actions={this.props.actions}
                          authenticationFailed={session.authenticationFailed}
                          errorMessage={session.errorMessage}
                          forceChangePassword
                          username={this.state.username || ''}
                        />
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Welcome.propTypes = {
  actions: PropTypes.object.isRequired,
  push: PropTypes.func,
  session: PropTypes.object.isRequired
};

export default connect(null, { push })(Welcome);
