import React, { Component, createRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import {
  AppBar,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Toolbar,
  Typography,
  Avatar,
  withStyles,
  Button,
  Select
  //Badge
} from '@material-ui/core';

import localstring from '../../services/Localization';
import styles from './styles';
import logoImage from '../../images/logos/R_Wordmark.svg';
import DropdownIcon from '../../images/icons/R_Icon_Chevron_Down.svg';
import CloseIcon from '../../images/icons/R_Icon_Menu_Close.svg';
import MenuIcon from '../../images/icons/R_Icon_Menu.svg';
import StickIcon from '../../images/icons/R_Icon_Menu_Stick.svg';
import UnstickIcon from '../../images/icons/R_Icon_Menu_Unstick.svg';
import SearchIcon from '../../images/icons/R_Icon_Search.svg';
import * as drawerActions from '../../actions/drawer';

import { API_ROOT, subscriptionTypes } from '../../constants';
import api from '../../api';
import eventTracking from '../../utils/event-tracking';
import getPage from '../../utils/getPage';
import GlobalSearch from '../GlobalSearch';
import * as storage from '../../persistence/storage';
import { AccountMenu } from './components';
import Chat from '../Chat';
import SnackMessage from '../Dialogs/SnackMessage';
import AccountBadge from './components/AccountBadge';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DynamicMenuIcon from '../DynamicMenuIcon/DynamicMenuIcon';

const GroupSelect = withStyles({
  root: {
    color: 'var(--input-placeholder)',
    display: 'flex',
    fontSize: 15,
    height: '100%',
    justifyContent: 'flex-start',
    paddingRight: 25,
    textAlign: 'right',
    width: '100%',

    '& > span': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      width: '100%'
    }
  },
  icon: {
    color: 'var(--input-placeholder)',
    pointerEvents: 'none',
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)'
  }
})(Select);

class Header extends Component {
  constructor(props) {
    super(props);
    const { subscription } = props;
    const initials = `${props.currentPerson.name.firstName[0]}${props.currentPerson.name.lastName[0]}`.replace(
      /undefined/g,
      ''
    );

    this.mobileSearchRef = createRef();

    const list = props.myGroups
      ? props.myGroups.sort((a, b) => {
          const textA = a.name.toUpperCase();
          const textB = b.name.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        })
      : [];

    this.state = {
      anchorEl: null,
      groupOpen: false,
      photoURL: props.avatar || null,
      messageDrawerOpen: false,
      loadingMessages: true,
      messageCount: 0,
      hasPhoto: props.avatar || false,
      limitedAccess:
        !subscription ||
        subscription.membershipModel === subscriptionTypes.LISTINGS,
      initials,
      groupList: list,
      displayMessages: false,
      unreadChats: 0,
      snackOpen: false,
      snackMessage: '',

      isMobileSearchOpen: false
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.getAgentInfo();
      if (!this.props.session.hasOwnProperty('showAmitySocial')) {
        this.props.actions.getSystemSettings();
      }
    }, 5000);
  }

  getPage = () => {
    const urlParts = document.URL.split('//');
    return urlParts[3] || 'Dashboard';
  };

  getAgentInfo = async () => {
    const { agentData } = this.props;
    // this.setState({ loadingMessages: true });
    // try {
    //   const response = await api.get(
    //     `${API_ROOT}/agents/data/${agentData._id}?dashboard=true`
    //   );
    //   const {
    //     newAlerts,
    //     profileViews,
    //     untaggedClients,
    //     newlyMatchedListings,
    //     listingsMatches
    //   } = response.data.data.data;

    //   // see if there are any messages!
    //   const untaggedClient = untaggedClients.featured;
    //   const unTagged = untaggedClient && untaggedClient.fullName ? 1 : 0;
    //   const newMatches =
    //     newlyMatchedListings && newlyMatchedListings.total
    //       ? newlyMatchedListings.total
    //       : 0;
    //   const newListingsMatches = listingsMatches ? listingsMatches.total : 0;
    //   this.setState({
    //     messageCount:
    //       newAlerts + profileViews + unTagged + newListingsMatches + newMatches
    //   });

    //   this.setState({
    //     loadingMessages: false,
    //     newAlerts,
    //     profileViews,
    //     untaggedClient,
    //     newlyMatchedListings, // agent's listings with new matches
    //     listingsMatches // other agent's listings with agent's clients that match
    //   });
    // } catch (e) {
    //   this.setState({ loadingMessages: false });
    // }
    return null;
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isResizing !== this.props.isResizing) {
      // todo resizing?
    }
    if (prevProps.avatar !== this.props.avatar) {
      this.fixPhoto(this.props.avatar);
      this.getAgentInfo();
    }
    if (
      this.state.messageDrawerOpen === true &&
      prevState.messageDrawerOpen === false
    ) {
      this.getAgentInfo();
    }
    if (
      this.props.myGroups &&
      this.props.myGroups.length !== prevProps.myGroups.length
    ) {
      const groupList = this.props.groups
        ? this.props.groups.sort((a, b) => {
            const textA = a.name.toUpperCase();
            const textB = b.name.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          })
        : [];
      this.setState({ groupList });
    }
  }

  switchGroup = group => {
    if (this.props.actions.setGroup) {
      this.props.actions.setGroup(group);
      eventTracking(`${getPage()}-Header`, 'Switch Group');
    }
  };

  toggleMessageDrawer = () => {
    if (!this.state.messageDrawerOpen) {
      eventTracking(`${getPage()}-Header`, 'Notifications');
    }
    this.setState({
      messageDrawerOpen: !this.state.messageDrawerOpen
    });
  };

  handleMenu = (event, param) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  fixPhoto = (image = null) => {
    this.setState({
      photoURL: image || null,
      hasPhoto: image || false
    });
  };

  setUnreadChats = (unreadChats, isSameCount, isSameTime) => {
    const s = this.state;
    if (unreadChats > s.unreadChats) {
      const p = this.props;
      if (!isSameTime || !isSameCount) {
        this.setState({
          snackOpen: true,
          snackMessage: `You have a new DM (${unreadChats} unread)`
        });
      }
    }
    this.setState({ unreadChats });
  };

  eventTrackingWrapper = (onClickFunction, gaAction) => {
    eventTracking(`${getPage()}-Header`, gaAction);
    if (onClickFunction) onClickFunction();
  };

  render() {
    const {
      agentData,
      classes,
      currentPerson,
      isAdmin,
      isConcierge,
      isOnboarding,
      isDrawerOpen,
      isDrawerStuck,
      isMobile,
      myGroups,
      selectedGroup,
      toggleDrawer,
      toggleDrawerStick,
      closeDrawer
    } = this.props;

    const { anchorEl, photoURL } = this.state;
    const impersonating = JSON.parse(storage.get('me'))?.impersonation;

    return (
      <>
        <div
          className={classNames(
            classes.shadow,
            (isDrawerOpen || isDrawerStuck) && classes.shadowSlideIn,
            isDrawerOpen && !isDrawerStuck && classes.shadowShow,
            isDrawerOpen && isMobile && classes.shadowWithPointer
          )}
          onClick={isMobile ? closeDrawer : undefined}
        />
        <div
          className={classNames(
            classes.appBar,
            isMobile && classes.appBarMobile,
            this.state.isMobileSearchOpen && 'isMobileOpen'
          )}
        >
          <div
            className={classNames(classes.overlay, 'showOnFocus', 'overlay')}
          />

          <div
            className={classNames(
              classes.leftMenu,
              'hideOnFocus',
              isMobile && classes.leftMenuMobile,
              isMobile && isDrawerOpen && classes.leftMenuMobileOpen
            )}
          >
            <div
              className={classNames(
                classes.drawerHeader,
                isDrawerOpen && classes.open,
                isDrawerStuck && classes.stuck,
                !isDrawerOpen && isMobile && classes.drawerHeaderMobile
              )}
            >
              {!isDrawerStuck && <div className={classes.drawerHeaderSpacer} />}
              {!isDrawerStuck && (
                <button
                  className={classNames(classes.toggle, classes.toggleLeft)}
                  onClick={isMobile ? toggleDrawer : undefined}
                  type="button"
                >
                  <DynamicMenuIcon isOpen={isDrawerOpen} />
                </button>
              )}
              <Hidden smDown>
                {isDrawerOpen && !isDrawerStuck && (
                  <button
                    className={classes.toggle}
                    type="button"
                    onClick={toggleDrawerStick}
                  >
                    <img src={StickIcon} />
                  </button>
                )}
                {isDrawerStuck && (
                  <>
                    <Link to="/dashboard">
                      <img
                        className={classNames(
                          classes.logoImage,
                          classes.padded
                        )}
                        src={logoImage}
                        alt="REALM"
                      />
                    </Link>
                    <button
                      className={classes.toggle}
                      type="button"
                      onClick={toggleDrawerStick}
                    >
                      <img src={UnstickIcon} />
                    </button>
                  </>
                )}
              </Hidden>
            </div>

            {!isDrawerStuck && !isMobile && (
              <div className={classes.logo}>
                <Link to="/dashboard">
                  <img
                    className={classes.logoImage}
                    src={logoImage}
                    alt="REALM"
                  />
                </Link>
                {!isMobile && myGroups && myGroups.length > 0 && (
                  <GroupSelect
                    value={selectedGroup ? selectedGroup.name : ''}
                    disableUnderline
                    displayEmpty
                    IconComponent={({ className }) => (
                      <img className={className} src={DropdownIcon} alt="" />
                    )}
                    renderValue={value => <span>{value || 'All REALM'}</span>}
                    keepMounted
                    onChange={event => {
                      const newGroup =
                        this.state.groupList &&
                        this.state.groupList.find(
                          group => group.name === event.target.value
                        );

                      if (newGroup) {
                        this.switchGroup(newGroup);
                      } else {
                        this.switchGroup(null);
                      }
                    }}
                  >
                    {this.state.groupList &&
                      this.state.groupList.length > 0 &&
                      this.state.groupList.map(group => (
                        <MenuItem value={group.name}>{group.name}</MenuItem>
                      ))}
                    <MenuItem value="">All REALM</MenuItem>
                  </GroupSelect>
                )}
              </div>
            )}
          </div>

          {isMobile && (
            <div className={classNames(classes.logo, classes.logoMobile)}>
              <Link to="/dashboard">
                <img
                  className={classNames(
                    classes.logoImage,
                    classes.logoImageMobile
                  )}
                  src={logoImage}
                  alt="REALM"
                />
              </Link>
            </div>
          )}

          {!isMobile && (
            <div className={classes.globalSearch}>
              <GlobalSearch
                agentId={this.props.agentData?._id}
                isAgent={this.props.isAgent}
                currentPerson={this.props.currentPerson}
                push={this.props.push}
                isAdmin={this.props.isAdmin}
              />
            </div>
          )}

          {isMobile && (
            <div>
              <IconButton
                variant="text"
                onClick={() => {
                  const input = this.mobileSearchRef.current.querySelector(
                    'input'
                  );

                  if (input) {
                    input.focus();
                  }

                  this.setState(previousState => ({
                    isMobileSearchOpen: !previousState.isMobileSearchOpen
                  }));
                }}
              >
                <img src={SearchIcon} />
              </IconButton>
            </div>
          )}

          {isMobile && (
            <div
              className={classNames(
                classes.mobileSearch,
                this.state.isMobileSearchOpen && classes.mobileSearchOpen
              )}
              ref={this.mobileSearchRef}
            >
              <div
                className={classNames(
                  classes.mobileSearchHeader,
                  this.state.isMobileSearchOpen &&
                    classes.mobileSearchHeaderOpen
                )}
              >
                <GlobalSearch
                  agentId={this.props.agentData?._id}
                  isAgent={this.props.isAgent}
                  currentPerson={this.props.currentPerson}
                  push={this.props.push}
                  isAdmin={this.props.isAdmin}
                  hideCategories
                  isPhone={true}
                  onResultClick={() => {
                    this.setState(
                      {
                        isMobileSearchOpen: false
                      },
                      () => {
                        queueMicrotask(() => {
                          const input = document.querySelector(':focus');

                          if (input) {
                            input.blur();
                          }
                        });
                      }
                    );
                  }}
                />
              </div>
              <div
                onClick={() => {
                  this.setState(
                    {
                      isMobileSearchOpen: false
                    },
                    () => {
                      const input = document.querySelector(':focus');

                      if (input) {
                        input.blur();
                      }
                    }
                  );
                }}
                className={classNames(
                  classes.mobileSearchOverlay,
                  this.state.isMobileSearchOpen &&
                    classes.mobileSearchOverlayOpen
                )}
              ></div>
            </div>
          )}

          <div
            className={classNames(
              classes.rightMenu,
              isMobile && classes.rightMenuMobile,
              'hideOnFocus'
            )}
          >
            {impersonating && (
              <div className={classes.impersonating}>
                <span>{localstring.logo_impersonation}:</span>
                <span>{agentData?.fullName ?? '-'}</span>
              </div>
            )}
            <AccountBadge
              eventTrackingWrapper={this.eventTrackingWrapper}
              showAmitySocial={this.props.session?.showAmitySocial}
              unreadChats={this.state.unreadChats}
            />
            {currentPerson && (
              <AccountMenu
                agentData={agentData}
                anchorEl={anchorEl}
                classes={classes}
                currentPerson={currentPerson}
                eventTrackingWrapper={this.eventTrackingWrapper}
                groupOpen={this.state.groupOpen}
                handleClose={this.handleClose}
                handleMenu={this.handleMenu}
                handleReferralDialog={this.props.actions.handleReferralDialog}
                initials={this.state.initials}
                isAdmin={isAdmin}
                isConcierge={isConcierge}
                isOnboarding={isOnboarding}
                photoURL={photoURL}
                showAmitySocial={this.props.session?.showAmitySocial}
                showChat={this.props.session?.showChat}
                unreadChats={this.state.unreadChats}
              />
            )}
          </div>

          <div
            className={classNames(
              classes.rightMenu,
              classes.rightCloseIcon,
              'showOnFocus'
            )}
          >
            <img src={CloseIcon} alt="close" />
          </div>
        </div>

        {this.props.session && this.props.session.showChat && (
          <Chat setUnreadChats={this.setUnreadChats} isMobile={isMobile} />
        )}
        <SnackMessage
          open={this.state.snackOpen}
          message={this.state.snackMessage}
          hideDuration={3750}
          marginTop={12}
          onClose={() => {
            this.setState({ snackOpen: false });
          }}
        />
      </>
    );
  }
}

Header.propTypes = {
  agentData: PropTypes.object,
  classes: PropTypes.object,
  currentPerson: PropTypes.object,
  isAdmin: PropTypes.bool,
  isAgent: PropTypes.bool,
  isConcierge: PropTypes.bool,
  isMobile: PropTypes.bool,
  isMobileOnly: PropTypes.bool,
  isOnboarding: PropTypes.bool,
  isResizing: PropTypes.bool,
  isRealmAdmin: PropTypes.bool,
  isRealmExecutive: PropTypes.bool,
  location: PropTypes.string,
  subscription: PropTypes.object
};

export default withStyles(styles)(
  connect(
    state => ({
      isDrawerOpen: state.drawer.isOpen,
      isDrawerStuck: state.drawer.isStuck
    }),
    dispatch => bindActionCreators(drawerActions, dispatch)
  )(Header)
);
