import React, { memo } from 'react';

const SubscriptionAgreement = props => (
  <p>
    {props.listingsOnly ? (
      <>
        <p>
          <small>
            This Software as a Service and Confidentially Agreement (the
            "Agreements") are binding contracts between you ("Customer," "you,"
            or "your") and REALM IP LLC and REALM LLC ("Provider," "we," or
            "us"). This Agreement governs your access to and use of the Cloud
            Services and participation in REALM.
          </small>
        </p>
        <p>
          <small>
            These Agreements take effect when you click the "PAY NOW" button
            below or by accessing or using the Cloud Services (the "Effective
            Date"). By clicking on the "PAY NOW" button below or by accessing or
            using the Cloud Services you (A) Acknowledge that you have read and
            understand these agreements; (B) Represent and warrant that you have
            the right, power, and authority to enter into these Agreements and,
            if entering into this Agreement for an organization, that you have
            the legal authority to bind that organization; and (C) Accept this
            Agreement and agree that you are legally bound by its terms.
          </small>
        </p>
      </>
    ) : (
      <>
        <p>
          <small>By clicking PAY NOW below you agree to the <a href={props.userAgreementPdf} target="_blank" rel="noreferrer" style={{ 'text-decoration': 'underline' }}>Agreements</a>.</small>
        </p>
        <p>
          <small>
            This Software as a Services Agreement, Membership Agreement and
            Confidentially Agreement (the "Agreements") are binding contracts
            between you ("Customer," "you," or "your") and Realm IP LLC and Ream
            LLC ("Provider," "we," or "us"). This Agreement governs your access
            to and use of the Cloud Services and participate in REALM activities
          </small>
        </p>
        <p>
          <small>
            These Agreements take effect when you click the "
            {props.buttonName ? props.buttonName : 'SUBMIT'}" button below or by
            accessing or using the Cloud Services (the "Effective Date"). By
            clicking on the "{props.buttonName ? props.buttonName : 'SUBMIT'}"
            button below or by accessing or using the Cloud Services you (A)
            Acknowledge that you have read and understand these agreements; (B)
            Represent and warrant that you have the right, power, and authority
            to enter into these Agreements and, if entering into this Agreement
            for an organization, that you have the legal authority to bind that
            organization; and (C) Accept this Agreement and agree that you are
            legally bound by its terms.
          </small>
        </p>
      </>
    )}
  </p>
);

export default memo(SubscriptionAgreement);
