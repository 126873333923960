import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  typography: {
    fontSize: 24,
    h5: {
      fontSize: '2.25rem',
      fontWeight: 500,
      lineHeight: '1.16667em'
    }
  },
  palette: {
    type: 'light',
    default: {
      main: '#eef',
      light: '#f0f',
      dark: '#888'
    },
    primary: {
      light: '#fff',
      // main: '#479acc', // $leather
      main: '#479acc', // $leather
      dark: '#086ba5'
      // dark: '#6e4218' // $leatherdark
    },
    secondary: {
      light: '#d4d4f9',
      main: '#9999b8',
      dark: '#4f4f63',
      contrastText: '#ffffff'
    },
    appBar: {
      color: 'primary'
    },
    overrides: {
      MuiFormLabel: {
        root: {
          color: '#ccf',
          fontWeight: 400,
          fontSize: 16
        }
      },
      MuiInput: {
        underline: {
          '&:before': {
            borderBottom: '#fff'
          },
          '&:hover:not($disabled):before': {
            borderBottom: '#f00'
          }
        }
      }
    }
  },
  overrides: {
    MuiButton: {
      text: {
        '&$disabled$colorInherit': {
          color: 'inherit',
          cursor: 'not-allowed',
          filter: 'brightness(0.7)',
          opacity: 0.7,
        }
      }
    }
  }
});

export default theme;
