import React from 'react';
import { Redirect, Route, Switch, BrowserRouter } from 'react-router-dom';
import Agreements from './routes/Agreements';
// import SubscriptionsAgents from './routes/SubscriptionsAgents';
import SubscriptionsStripeOnly from './routes/SubscriptionsStripeOnly';
import SubscriptionsComplete from './routes/SubscriptionsComplete';
import SubscriptionsListing from './routes/SubscriptionsListing';
import SubscriptionsOptions from './routes/SubscriptionsOptions';
// import SubscriptionsPending from './routes/SubscriptionsPending';
// import SubscriptionsTeams from './routes/SubscriptionsTeams';
import Logout from '../Logout';
import OverduePayment from './routes/OverduePayment';

const SubscriptionPlans = storeProps => (
  <Switch>
    <Route
      exact
      path="/"
      render={props => (
        <Agreements
          actions={storeProps.actions}
          currentPerson={storeProps.session.currentPerson}
          history={props.history}
          isMobile={storeProps.application.isMobile}
          isRenewing={storeProps.session.isRenewing}
          language={storeProps.application.locale}
          subscription={storeProps.session.subscription}
          session={storeProps.session}
        />
      )}
    />
    <Route
      exact
      path="/agreements"
      render={props => (
        <Agreements
          actions={storeProps.actions}
          currentPerson={storeProps.session.currentPerson}
          history={props.history}
          isMobile={storeProps.application.isMobile}
          isRenewing={storeProps.session.isRenewing}
          language={storeProps.application.locale}
          subscription={storeProps.session.subscription}
          isAgent={storeProps.session.isAgent}
          session={storeProps.session}
        />
      )}
    />
    <Route
      exact
      path="/subscription-options"
      render={props => (
        <SubscriptionsOptions
          hasSubscription={storeProps.session.hasSubscription}
          history={props.history}
          isRenewing={storeProps.session.isRenewing}
          language={storeProps.application.locale}
          currentPerson={storeProps.session.currentPerson}
          isAgent={storeProps.session.isAgent}
          isTeamMemberSub={storeProps.session.isTeamMemberSub}
          isTeamLeadSub={storeProps.session.isTeamLeadSub}
        />
      )}
    />
    <Route
      exact
      path="/subscription-listing"
      render={props => (
        <SubscriptionsListing
          actions={storeProps.actions}
          agentData={storeProps.session.agentData}
          currentPerson={storeProps.session.currentPerson}
          hasSubscription={storeProps.session.hasSubscription}
          hasSubscriptionExpired={storeProps.session.hasSubscriptionExpired}
          history={props.history}
          isAdmin={storeProps.session.isAdmin}
          isAgent={storeProps.session.isAgent}
          isConcierge={storeProps.session.isConcierge}
          isRenewing={storeProps.session.isRenewing}
          language={storeProps.application.locale}
        />
      )}
    />
    <Route
      exact
      path="/subscription-agents"
      render={props => (
        <SubscriptionsStripeOnly
          actions={storeProps.actions}
          agentData={storeProps.session.agentData}
          createTeam={false}
          currentPerson={storeProps.session.currentPerson}
          hasPendingSubscription={storeProps.session.hasPendingSubscription}
          hasSubscription={storeProps.session.hasSubscription}
          hasSubscriptionExpired={storeProps.session.hasSubscriptionExpired}
          history={props.history}
          isRenewing={storeProps.session.isRenewing}
          language={storeProps.application.locale}
          subscription={storeProps.session.subscription}
        />
      )}
    />
    <Route
      exact
      path="/subscription-teams"
      render={props => (
        <SubscriptionsStripeOnly
          actions={storeProps.actions}
          agentData={storeProps.session.agentData}
          createTeam={false}
          currentPerson={storeProps.session.currentPerson}
          hasPendingSubscription={storeProps.session.hasPendingSubscription}
          hasSubscription={storeProps.session.hasSubscription}
          hasSubscriptionExpired={storeProps.session.hasSubscriptionExpired}
          history={props.history}
          isRenewing={storeProps.session.isRenewing}
          language={storeProps.application.locale}
          subscription={storeProps.session.subscription}
        />
      )}
    />
    <Route
      exact
      path="/subscription-complete"
      render={props => (
        <SubscriptionsComplete
          actions={storeProps.actions}
          currentPerson={storeProps.session.currentPerson}
          hasPendingSubscription={storeProps.session.hasPendingSubscription}
          history={props.history}
          isOnboarding={storeProps.session.isOnboarding}
          language={storeProps.application.locale}
          subscription={storeProps.session.subscription}
        />
      )}
    />
    <Route
      exact
      path="/collect-payment"
      render={props => (
        <OverduePayment
          location={props.location}
          actions={storeProps.actions}
          // hasPendingSubscription={storeProps.session.hasPendingSubscription}
          history={props.history}
          language={storeProps.application.locale}
          session={storeProps.session}
        />
      )}
    />
    <Route
      path="/logout"
      render={props => (
        <Logout
          {...props}
          actions={storeProps.actions}
          clearSubscribeLink={storeProps.clearSubscribeLink}
          session={storeProps.session}
          bg="transparent"
        />
      )}
    />
    <Redirect from="*" to="/agreements" />
  </Switch>
);

export default SubscriptionPlans;
