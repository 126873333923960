/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import FormControl from '../Users/FormControl';
import LoadingIndicator from '../LoadingIndicator';
import ResetPassword from './ResetPasswordRequest';
import ChangePassword from './ChangePassword';
import ErrorDialog from '../Dialogs/ErrorDialog';
import ContactUs from '../Dialogs/ContactUs';

import api from '../../api';
import getParameterByName from '../../utils/get-parameter-by-name';
import localstring from '../../services/Localization';
import { API_ROOT, IS_TEMP_MAINTENANCE_MODE } from '../../constants';

class LoginForm extends Component {
  constructor(props) {
    super(props);
    const verificationCode = getParameterByName('verificationCode') || '';
    const verificationCodeIsValid = verificationCode.length === 6;
    this.state = {
      attemptRetry: false,
      errorMessage: '',
      isError: false,
      isLoading: false,
      isValid: false,
      newPassword: '',
      password: '',
      passwordConfirm: '',
      showStepCheckEmail: false,
      showStepRequestNewPassword: false,
      showStepChangePassword:
        verificationCodeIsValid && Boolean(verificationCode),
      showPassword: false,
      showPasswordRequestSuccess: false,
      username: props.username || '',
      verificationCode: verificationCodeIsValid ? verificationCode : ''
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.username !== this.props.username) {
      this.setUsername(this.props.username);
    }
  }

  setUsername = username => {
    this.setState({ username });
  };

  goToLogin = () => {
    window.location.replace('/login');
  };

  handleChange = e => {
    this.setState(
      {
        [e.target.id]: e.target.value
      },
      () => {
        this.setState({ isValid: this.validateForm() });
      }
    );
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ isLoading: true });
    const { forceChangePassword } = this.props;
    const authenticationData = forceChangePassword
      ? {
          newPassword: this.state.newPassword,
          password: this.state.password,
          username: this.state.username.toLowerCase()
        }
      : {
          password: this.state.password,
          username: this.state.username.toLowerCase()
        };
    this.props.actions.authenticate(authenticationData);
  };

  handleClose = () => {
    this.props.actions.retryAuthentication();
    this.setState({
      attemptRetry: false,
      errorMessage: '',
      isError: false,
      isLoading: false,
      showStepRequestNewPassword: false,
      showStepChangePassword: false,
      showPasswordRequestSuccess: false
    });
  };

  /* force change password stuff */
  toggleShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    });
  };

  /* validation */
  passwordIsStrong = () => {
    const strongRegex = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
    );
    return strongRegex.test(this.state.newPassword);
  };

  validateEmail = () => {
    const re = /\S+@\S+\.\S+/;
    return re.test(this.state.username);
  };

  validatePasswordMatch = () =>
    this.state.newPassword === this.state.passwordConfirm &&
    this.passwordIsStrong();

  validateExtendedForm = () =>
    this.validatePasswordMatch() &&
    this.validateEmail() &&
    this.state.password.length > 0;

  validateSimpleForm = () =>
    this.validateEmail() && this.state.password.length > 0;

  validateForm = () =>
    this.props.forceChangePassword
      ? this.validateExtendedForm()
      : this.validateSimpleForm();

  /* reset password stuff */
  requestResetPassword = () => {
    this.setState({
      showStepRequestNewPassword: true
    });
  };

  cancelResetPassword = () => {
    this.setState({
      showStepRequestNewPassword: false,
      showStepCheckEmail: false
    });
  };

  onRetryPassword = () => {
    this.setState({
      attemptRetry: false,
      errorMessage: '',
      isError: false,
      showStepChangePassword: true
    });
  };

  resetPassword = async emailAddress => {
    this.setState({
      showStepRequestNewPassword: true
    });
    try {
      const response = await api.post(`${API_ROOT}/user/reset-password`, {
        username: emailAddress
      });
      if (response.status === 200) {
        this.setState({
          showStepRequestNewPassword: false,
          showStepCheckEmail: true
        });
      }
    } catch (ex) {
      const msg = ex.response ? ex.response.data.msg : ex.msg;
      this.setState({
        errorMessage: msg,
        isError: true,
        showStepRequestNewPassword: false
      });
    }
  };

  changePassword = async authObj => {
    if (!authObj.password || !authObj.username) {
      return null;
    }
    try {
      const response = await api.post(`${API_ROOT}/user/confirm-password`, {
        code: this.state.verificationCode,
        password: authObj.password,
        username: authObj.username
      });
      if (response.status === 200) {
        this.setState({
          showPasswordRequestSuccess: true
        });
      }
    } catch (ex) {
      const msg = ex.response ? ex.response.data.msg : ex.msg;
      this.setState({
        errorMessage: msg,
        isError: true,
        showStepChangePassword: false,
        attemptRetry: true
      });
    }
    return null;
  };

  render() {
    const { isValid } = this.state;
    const passwordIsStrong = this.passwordIsStrong();
    const passwordsMatch = this.validatePasswordMatch();
    const formIsVisible =
      this.props.forceChangePassword && this.state.password.length >= 8;
    return (
      <>
        <form onSubmit={this.handleSubmit}>
          <div className="form-group form-group-lg">
            <label className="control-label" htmlFor="username">
              {localstring.onboarding.email}
            </label>
            <FormControl
              autoFocus={!this.props.username}
              id="username"
              type="text"
              disabled={this.props.username}
              value={this.state.username}
              onChange={this.handleChange}
            />
          </div>
          <div className="form-group form-group-lg">
            <label className="control-label" htmlFor="password">
              {localstring.onboarding.password}
            </label>
            <FormControl
              autoFocus={this.props.username}
              id="password"
              value={this.state.password}
              onChange={this.handleChange}
              type={this.state.showPassword ? 'text' : 'password'}
              style={{ paddingRight: 44 }}
            />
            <IconButton
              aria-label="Toggle View Password"
              onClick={this.toggleShowPassword}
              tabIndex="-1"
              style={{
                position: 'absolute',
                color: '#808080',
                right: 0,
                top: 24
              }}
            >
              {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </div>
          {this.props.forceChangePassword && (
            <div
              style={{
                padding: formIsVisible ? '6px 0' : 0,
                height: formIsVisible ? 230 : 0,
                overflow: 'hidden',
                transition: 'all 500ms ease-in-out',
                opacity: formIsVisible ? 1 : 0
              }}
            >
              <div className="form-group form-group-lg ">
                <label className="control-label" htmlFor="newPassword">
                  Create New Password
                </label>
                <FormControl
                  id="newPassword"
                  value={this.state.newPassword}
                  onChange={this.handleChange}
                  tabIndex={formIsVisible ? '0' : '-1'}
                  type={this.state.showPassword ? 'text' : 'password'}
                  style={{
                    paddingRight: 44,
                    background: passwordIsStrong
                      ? 'white'
                      : 'rgba(255,210,210,1)'
                  }}
                />
                <IconButton
                  aria-label="Toggle View Password"
                  onClick={this.toggleShowPassword}
                  tabIndex="-1"
                  style={{
                    position: 'absolute',
                    color: '#808080',
                    right: 0,
                    top: 24
                  }}
                >
                  {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
                <div
                  className={`realm--text-helper${
                    passwordIsStrong ? '' : ' error'
                  }`}
                >
                  {passwordIsStrong
                    ? 'Your password is strong!'
                    : 'Password should contain at least one capital letter, one number and one special character (! @ # $ % ^ & *).'}
                </div>
              </div>
              <div className="form-group form-group-lg">
                <label className="control-label" htmlFor="passwordConfirm">
                  Confirm New Password
                </label>
                <FormControl
                  id="passwordConfirm"
                  value={this.state.passwordConfirm}
                  onChange={this.handleChange}
                  tabIndex={formIsVisible ? '0' : '-1'}
                  type={this.state.showPassword ? 'text' : 'password'}
                  style={{
                    paddingRight: 44,
                    background: passwordIsStrong
                      ? 'white'
                      : 'rgba(255,210,210,1)'
                  }}
                />
                <IconButton
                  aria-label="Toggle View Password"
                  onClick={this.toggleShowPassword}
                  tabIndex="-1"
                  style={{
                    position: 'absolute',
                    color: '#808080',
                    right: 0,
                    top: 24
                  }}
                >
                  {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
                <div
                  className={`realm--text-helper${
                    passwordsMatch ? '' : ' error'
                  }`}
                >
                  {passwordsMatch ? 'Passwords match!' : 'Passwords must match'}
                </div>
              </div>
            </div>
          )}
          {this.state.isLoading ? (
            <div className="realm--loading-minwidth">
              <LoadingIndicator message={localstring.signing_in} />
            </div>
          ) : (
            <div style={{ textAlign: 'center', marginBottom: 20 }}>
              <Button
                disabled={!isValid}
                fullWidth
                color={isValid ? 'primary' : 'default'}
                variant="contained"
                size="large"
                style={{
                  margin: '30px 0 20px',
                  opacity: this.state.isValid ? '1' : '.5',
                  pointerEvents: this.state.isValid ? 'auto' : 'none'
                }}
                type="submit"
              >
                {IS_TEMP_MAINTENANCE_MODE
                  ? localstring.login_under_maintenance
                  : localstring.login}
              </Button>

              {!this.props.forceChangePassword && (
                <ResetPassword
                  forgot
                  handleOpen={this.requestResetPassword}
                  handleClose={this.cancelResetPassword}
                  handleReset={this.resetPassword}
                  open={this.state.showStepRequestNewPassword}
                  showButton
                  showStepCheckEmail={this.state.showStepCheckEmail}
                  small
                />
              )}
              <ContactUs
                addUserInformation
                linkText="Trouble Logging In?"
                subject="Help with My Account"
              />
            </div>
          )}
        </form>
        <ChangePassword
          handleReset={this.changePassword}
          isSuccess={this.state.showPasswordRequestSuccess}
          onSuccess={this.goToLogin}
          open={this.state.showStepChangePassword}
        />
        <ErrorDialog
          handleClose={this.handleClose}
          message={this.props.errorMessage}
          open={this.props.authenticationFailed}
        />
        <ErrorDialog
          handleClose={this.handleClose}
          handleRetry={
            this.state.attemptRetry ? this.onRetryPassword : undefined
          }
          message={this.state.errorMessage}
          open={this.state.isError}
        />
      </>
    );
  }
}

LoginForm.propTypes = {
  actions: PropTypes.object,
  authenticationFailed: PropTypes.bool,
  autoGeneratedPassword: PropTypes.string,
  errorMessage: PropTypes.string,
  forceChangePassword: PropTypes.bool,
  username: PropTypes.string
};

export default LoginForm;
