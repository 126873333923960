// Extracts subdomain from TLD e.g. ('dev.realm-global.com') -> 'dev'
function extractSubDomain() {
  const url = window.location.hostname
  const useForLocalHost = 'localhost';  // for testing could be dev, staging, agent

  // If this app is running on localhost return local host
  if (url.includes('localhost')) return useForLocalHost;

  // Get the subdomain - e.g. staging 
  let subDomain = url.split('.')[0];
  return (subDomain);
}

export {
  extractSubDomain
};