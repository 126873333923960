/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash.isfunction';

const FormControl = ({ value: defaultValue, onChange, ...rest }) => {
  const [value, changeValue] = useState(defaultValue);

  const handleChange = e => {
    changeValue(e.target.value);
    if (isFunction(onChange)) {
      onChange(e);
    }
  };

  return (
    <input
      {...rest}
      className="form-control"
      onChange={handleChange}
      value={value}
    />
  );
};

FormControl.propTypes = {
  value: PropTypes.string
};

export default FormControl;
