const SEARCH_KEY = 'SEARCH_DISPLAY';
const DETAIL_KEY = 'DETAIL_PAGE_VIEWED';

const sendSearchResultsMetric = (listingKeys) => {
  if (listingKeys?.length > 0) {
    const keys = listingKeys.map((providerId) => ( { lkey: providerId } ));
    window.lh('submit', SEARCH_KEY, keys);
  }
};

const sendDetailsOpenMetric = (listingKey) => {
  window.lh('submit', DETAIL_KEY, { lkey: listingKey });
};

export {
  sendSearchResultsMetric,
  sendDetailsOpenMetric,
};
