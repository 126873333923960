import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import persistenceStore from './persistence/store';
import createRootReducer from './reducers';

export const history = createBrowserHistory();

const initialState = {};
const middleware = [
  applyMiddleware(thunk),
  applyMiddleware(routerMiddleware(history)),
  persistenceStore
];

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const buildMiddleware = () => middleware;
const finalCreateStore = composeEnhancers(...buildMiddleware())(createStore);
const configureStore = initialState =>
  finalCreateStore(createRootReducer(history), initialState);

export default configureStore(initialState);
