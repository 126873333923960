import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField
} from '@material-ui/core';
import localstring from '../../services/Localization';
import LoadingIndicator from '../LoadingIndicator';

class ResetPasswordRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailAddress: '',
      isLoading: false,
      isValid: false,
      fromAccount: props.fromAccount || false,
    };
    this._delay = null;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.resetLoading();
    }
  }

  componentWillUnmount() {
    if (this._delay) {
      clearTimeout(this._delay);
      this._delay = null;
    }
  }

  resetLoading = () => {
    if (this.state.isLoading) {
      this._delay = setTimeout(() => {
        this.setState({ isLoading: false });
      }, 750);
    }
  };

  onInput = e => {
    this.setState(
      {
        emailAddress: e.target.value
      },
      () => {
        this.setState({
          isValid: this.validateEmail()
        });
      }
    );
    return null;
  };

  // for now -- need better validation
  validateEmail = () => {
    const re = /\S+@\S+\.\S+/;
    return re.test(this.state.emailAddress);
  };

  confimReset = () => {
    this.setState({ isLoading: true });
    this.props.handleReset(this.state.emailAddress.toLowerCase());
  };

  render() {
    const { forgot, forName, showButton } = this.props;
    return (
      <>
        {showButton && (
          <div style={{ display: this.props.block ? 'block' : 'inline-block' }}>
            <Button
              style={
                this.props.block
                  ? {
                    display: 'block',
                    margin: '0 auto'
                  }
                  : {}
              }
              color={forgot || this.state.fromAccount ? 'primary' : 'default'}
              onClick={this.props.handleOpen}
              variant={this.props.block || this.state.fromAccount ? 'contained' : undefined}
            >
              {forgot ? (
                <span>{localstring.onboarding.forgot_password}</span>
              ) : (
                <span>
                  {localstring.onboarding.account_form_change_password}
                </span>
              )}
            </Button>
          </div>
        )}
        <Dialog
          aria-describedby="delete-dialog"
          aria-labelledby="delete-dialog-title"
          onClose={this.props.handleClose}
          open={this.props.open || false}
        >
          <DialogTitle id="delete-dialog-title">
            {forgot ? (
              <span>{localstring.onboarding.reset_password}</span>
            ) : (
              <span>{localstring.onboarding.account_form_change_password}</span>
            )}
          </DialogTitle>
          <div
            style={{
              color: '#fff',
              fontSize: 18,
              paddingLeft: 24,
              marginTop: -20
            }}
          >
            {forName ? <span>for {forName}</span> : null}
          </div>
          <DialogContent
            className="realm--modal-content"
            style={{ position: 'relative' }}
          >
            {this.state.isLoading ? (
              <div style={{ width: '100%', textAlign: 'center' }}>
                <p style={{ color: '#fff' }}>Sending request ...</p>
                <div style={{ width: 32, margin: '0 auto' }}>
                  <LoadingIndicator style={{ marginLeft: -16 }} />
                </div>
              </div>
            ) : (
              <>
                <TextField
                  style={{
                    minWidth: 250,
                    background: 'transparent'
                  }}
                  id="email-address"
                  type="email"
                  label="Your email address:"
                  helperText="Please use the email address you sign in with."
                  margin="dense"
                  onChange={e => this.onInput(e, 'emailAddress')}
                  value={this.state.emailAddress}
                />
                <br />
                <br />
                {localstring.onboarding.account_form_request_change_password}
              </>
            )}
          </DialogContent>

          <DialogActions>
            <Button
              onClick={this.props.handleClose}
              color="default"
              variant="contained"
            >
              {localstring.cancel}
            </Button>
            <Button
              onClick={this.confimReset}
              color="primary"
              variant="contained"
              disabled={!this.state.isValid}
            >
              {localstring.onboarding.continue}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          aria-describedby="password-change-check-email-dialog"
          aria-labelledby="password-change-check-email-title"
          open={this.props.showStepCheckEmail}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <DialogTitle id="password-change-check-email-title">
            Check your email
          </DialogTitle>
          <DialogContent>
            <div
              style={{
                color: '#fff',
                fontSize: 14,
                textAlign: 'center'
              }}
            >
              {!forgot && (
                <span style={{ display: 'block', marginBottom: 5 }}>
                  To complete the password change process, you will be logged
                  out of the system.
                </span>
              )}
              Check your email at <strong>{this.state.emailAddress}</strong> for
              a link to reset your password.
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={
                forgot ? this.props.handleClose : this.props.handleLogout
              }
              color="primary"
              variant="contained"
            >
              {forgot ? localstring.onboarding.ok : localstring.logout}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

ResetPasswordRequest.propTypes = {
  forgot: PropTypes.bool,
  forName: PropTypes.string,
  handleClose: PropTypes.func,
  handleLogout: PropTypes.func,
  handleOpen: PropTypes.func,
  handleReset: PropTypes.func,
  showButton: PropTypes.bool,
  showStepCheckEmail: PropTypes.bool
};

export default ResetPasswordRequest;
