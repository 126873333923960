import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { IS_MAINTENANCE_MODE, VERSION } from '../../constants';
import ContactUs from '../../components/Dialogs/ContactUs';

import localstring from '../../services/Localization';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMaintenance: props.session.maintenanceMode ? props.session.maintenanceMode : false,
    }
  }

  componentDidMount = () => {
    if (!this.props.session.hasOwnProperty('maintenanceMode')) {
      this.props.actions.getSystemSettings()
    }
  }


  componentDidUpdate(prevProps) {
    if (this.props.session.hasOwnProperty('maintenanceMode')) {
      if (!prevProps.session.hasOwnProperty('maintenanceMode')) {
        this.setState({ showMaintenance: this.props.session.maintenanceMode });
      } else if (this.props.session.maintenanceMode !== prevProps.session.maintenanceMode) {
        this.setState({ showMaintenance: this.props.session.maintenanceMode });
      }
    }
  }

  goToLoginPage = e => {
    e.preventDefault();
    this.props.push('/login');
  };
 



  render() {
    const systemMessage = this.props.session.systemMessage && this.props.session.systemMessage !== '' ? this.props.session.systemMessage : false;
    const paddingTop = systemMessage ? 48 : 0;

    return (
      <div className="realm--login-screen fadeIn" style={{paddingTop}}>
        {systemMessage && (
          <div className='realm--system-message'>
            {systemMessage}
          </div>
        )}
        <header role="banner" className="realm--login-screen-header">
          <span className="realm--login-screen-header-logo">
            <a href="https://www.realm-global.com/">
              <img
                src="https://wp-realm-marketing.s3.amazonaws.com/images/REALM_LogoWhite_R.png"
                alt="REALM"
              />
            </a>
          </span>
          {!IS_MAINTENANCE_MODE && (
            <nav className="realm--login-screen-header-signin">
              <a href="/login" onClick={this.goToLoginPage}>
                {this.state.showMaintenance
                  ? localstring.login_under_maintenance
                  : localstring.login}
              </a>
            </nav>
          )}
        </header>
        <div className="realm--login-screen-content-wrapper">
          <div className="realm--login-screen-backdrop">
            <div className="realm--login-screen-content-container">
              <div className="realm--login-screen-pic-overlay" />
              <div className="realm--login-screen-content">
                <main
                  className="realm--login-screen-main"
                  role="main"
                  itemProp="mainContentOfPage"
                >
                  <div className="realm--login-screen-container">
                    <div className="realm--login-screen-wrapper">
                      {IS_MAINTENANCE_MODE ? (
                        <h1
                          className="realm--login-screen-headline"
                          itemProp="headline"
                        >
                          <span className="realm--login-screen-realm">
                            REALM
                          </span>{' '}
                          is conducting scheduled maintenance. We expect to be
                          back shortly. Thank you for your patience.
                        </h1>
                      ) : (
                        <h1
                          className="realm--login-screen-headline"
                          itemProp="headline"
                        >
                          Welcome to{' '}
                          <span className="realm--login-screen-realm">
                            REALM
                          </span>
                          , <br /> we're delighted you're here.
                        </h1>
                      )}
                      <div className="realm--login-screen-divider-container">
                        <div className="realm--login-screen-divider" />
                      </div>
                      <section
                        itemScope="itemscope"
                        itemType="https://schema.org/CreativeWork"
                      >
                        <div
                          className="realm--login-screen-copy"
                          itemProp="text"
                        >
                          <h3>
                            Welcome to REALM, the first and only globally
                            connected luxury real estate community. Beyond this
                            page is a collaborative matching platform, client
                            research and curated luxury content all designed to
                            help you make connections and deepen relationships
                            with your clients, and with other real estate agents
                            around the world.
                            <br />
                            <br />
                            Meet the next generation of global real estate
                            &#8212; led by the current generation of real estate
                            leaders. Meet REALM.
                          </h3>
                        </div>
                      </section>
                      <div className="realm--login-screen-actions">
                        <a
                          className="realm--login-screen-learn-more"
                          href="https://www.realm-global.com/"
                        >
                          Learn More
                        </a>
                      </div>
                      <div className="realm--login-screen-footer">
                        &#169; {localstring.footer_copy1}{' '}
                        {new Date().getFullYear()}, {localstring.footer_copy2}
                        <span style={{ margin: '0 10px' }}>|</span>
                        <ContactUs addUserInformation showText />
                        <div className="realm--login-version">Version {VERSION}</div>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Home.propTypes = {
  push: PropTypes.func
};

export default connect(null, { push })(Home);
