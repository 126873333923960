/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash.isfunction';
import { Button } from '@material-ui/core';
import ContentDialog from './ContentDialog';

import localstring from '../../services/Localization';

class CookieWarning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      storySlug: '',
      showContentDialog: false
    };
  }

  handleClick = e => {
    e.preventDefault();
    if (isFunction(this.props.acceptCookies)) {
      this.props.acceptCookies();
    }
  };

  loadContent = e => {
    e.preventDefault();
    this.setState(
      {
        storySlug: e.target.name
      },
      () => {
        this.toggleContentDialog();
      }
    );
  };

  toggleContentDialog = () => {
    this.setState({
      showContentDialog: !this.state.showContentDialog
    });
  };

  render() {
    return (
      <div className="realm--cookie-warning">
        <p className="realm--cookie-warning-message">
          REALM relies on cookies to collect and process data. We may use this
          data to make assumptions on what content may interest you. By clicking{' '}
          <strong>"Continue and Accept All"</strong>, you agree to allow cookies
          to be placed. To get more information about these cookies and the
          processing of your personal data, check our{' '}
          <a
            href="/"
            name="marketing-company-privacy-policy"
            onClick={this.loadContent}
          >
            Privacy Policy
          </a>{' '}
          &amp;{' '}
          <a href="/" name="marketing-company-gdpr" onClick={this.loadContent}>
            Cookie Policy
          </a>
          .
        </p>
        <div className="realm--cookie-warning-actions">
          <Button
            onClick={this.props.acceptCookies}
            color="primary"
            variant="contained"
          >
            {localstring.continue_and_accept}
          </Button>
        </div>
        <ContentDialog
          apiType="fullPost"
          language={this.props.language}
          onClose={this.toggleContentDialog}
          open={this.state.showContentDialog}
          slug={this.state.storySlug}
        />
      </div>
    );
  }
}

CookieWarning.propTypes = {
  acceptCookies: PropTypes.func,
  language: PropTypes.string
};

export default CookieWarning;
