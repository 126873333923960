/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import isEmpty from 'lodash.isempty';
import isFunction from 'lodash.isfunction';
import NumberFormat from 'react-number-format';
import ClearIcon from '@material-ui/icons/Clear';
import axios from 'axios';
import Resizer from 'react-image-file-resizer';
import { Link } from 'react-router-dom';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  IconButton,
  Tooltip
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import FileDrop from '../../routes/EditImages/FileDrop';

import api from '../../api';
import {
  API_ROOT,
  REALM_ASSETS_BUCKET,
  MAX_UPLOAD_MEGABYTES
} from '../../constants';
import trim from '../../utils/trim';
import localstring from '../../services/Localization';

import AlertDialog from './AlertDialog';
import ContentDialog from './ContentDialog';
import ErrorDialog from './ErrorDialog';
import LoadingIndicator from '../LoadingIndicator';

import isValidZip from '../../utils/is-valid-zip';
import USStates from '../../data/us-states';
import countries from '../../data/countries';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...rest } = props;

  return (
    <NumberFormat
      {...rest}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      thousandSeparator
      prefix="$"
    />
  );
}

class AddListingDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      city: '',
      dateAvailable: moment().format('YYYY-MM-DD'),
      //fileToUpload: [],
      pictures: [],
      uploadProgress: '0',
      //videoURL: '',
      //imageURL: [],
      listingAddress: '',
      listingType: 'Manual',
      isRental: false,
      listingUrl: '',
      price: '',
      propertyId: '',
      st: '',
      status: 'Active',
      tags: [],
      tagNames: [],
      lifestyles: [],
      lifestyleNames: [],
      termsAccepted: false,
      title: '',
      unit: '',
      zip: '',
      country: 'United States',
      description: {
        en: ''
      },
      floorPlan: '',
      floorPlanList: [],
      mlsId: null,
      errorMessage: '',
      imageError: false,
      isCreatingListing: false,
      isDirty: false,
      isError: false,
      isListingTypeSelected: !!this.props.isListingsOnly,
      isValidForm: false,
      itemDropped: false,
      showContentDialog: false,
      showSaveSuccessful: false,
      showUnsavedWarning: false,
      archived: this.props.archived ? this.props.archived : null,
      showFloorPlans: false,
      firstUpdateComplete: false
    };
    this._delay = null;
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this._delay) {
      clearTimeout(this._delay);
      this._delay = null;
    }
  }

  componentDidUpdate(prevProps) {
    const p = this.props;
    let setData = false;
    if (!isEmpty(this.props.propertyData) && isEmpty(prevProps.propertyData)) {
      this.setExisitingPropertyValues();
      setData = true;
    }
    if (this.props.archived !== prevProps.archived) {
      this.setState({ archived: this.props.archived });
    }
    if (
      setData === false &&
      !isEmpty(this.props.propertyData) &&
      prevProps.propertyData
    ) {
      if (prevProps.propertyData._id !== this.props.propertyData._id) {
        this.setExisitingPropertyValues();
        setData = true;
      }
    }
    if (!setData && !this.state.firstUpdateComplete) {
      this.setExisitingPropertyValues();
      this.setState({ firstUpdateComplete: true });
    }
    if (!this.props.isEditing && prevProps.isEditing) {
      this.clearState();
    }
  }

  // FORM HANDLING START

  handleChange = name => event => {
    let newState = name === 'st' ? event.target.value : this.state.st;
    if (name === 'country' && event.target.value !== 'United States') {
      newState = '';
    }
    this.setState(
      {
        [name]: event.target.value,
        st: newState,
        isDirty: true
      },
      () => {
        this.checkForValidForm();
        // this.checkForValidZip();
      }
    );
  };

  handleDescriptionChange = () => event => {
    this.setState(
      {
        description: {
          en: event.target.value
        },
        isDirty: true
      },
      () => {
        this.checkForValidForm();
        // this.checkForValidZip();
      }
    );
  };

  handleTags = (tagNames = [], tags = []) => {
    this.setState(
      {
        tags,
        tagNames
      },
      () => {
        this.checkForValidForm();
      }
    );
  };

  onChecked = e => {
    this.setState(
      {
        isDirty: true,
        termsAccepted: e.target.checked
      },
      () => {
        this.checkForValidForm();
      }
    );
  };

  selectListingType = e => {
    this.setState({
      listingType: e.target.value
    });
  };
  acceptedDrop = files => {
    this.handleDrop(files);
  };

  resizeFile = file =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        'JPEG',
        100,
        0,
        image => {
          resolve(image);
        },
        'file'
      );
    });

  rejectedDrop = files => {
    files.map(file => {
      file.errors.forEach(err => {
        if (err.message.includes('File is larger')) {
          err.message = `File is larger than the maximum allowed ${MAX_UPLOAD_MEGABYTES}MB`;
        }
      });
      this.setState({
        isError: true,
        errorMessage:
          file.errors.length === 1
            ? `${file.file.name} failed to upload. ${file.errors[0].message}.`
            : `${file.file.name} failed to upload. ${file.errors[0].message} and ${file.errors[1].message}.`
      });
    });
  };
  handleDrop = files => {
    const { agentId } = this.props;
    this.setState({
      itemDropped: true
    });
    // handle multiple images
    let totalUploadSize = 0;
    let totalUploaded = 0;
    Array.from(files).forEach(
      file => (totalUploadSize += totalUploadSize + file.size)
    );
    const myUploadProgress = file => progress => {
      //console.log(this.state.propertyId)
      //this.checkForValidForm();
      totalUploaded += progress.loaded;
      //let percentage = Math.floor((progress.loaded * 100) / progress.total)
      // console.log(file.name)
      // console.log(percentage)
      const percentageUp = Math.floor(totalUploaded / totalUploadSize) * 10;
      this.setState({
        uploadProgress: percentageUp
      });
    };

    Array.from(files).forEach((file, index) => {
      const strippedName = file.name.replace(/[^A-Z0-9.]/gi, '_');
      const strippedAddress =
        this.state.listingAddress &&
        this.state.listingAddress.replace(/[^A-Z0-9.]/gi, '_');
      const folder =
        strippedAddress && strippedAddress !== ''
          ? strippedAddress
          : Math.floor(Math.random() * 1000000);
      const newImage = {
        key: agentId + moment(),
        fileName: strippedName,
        isPrimary: this.state.pictures.length === 0,
        name: strippedName,
        pictureUrl: `${REALM_ASSETS_BUCKET}/${agentId}/${folder}/${strippedName}`,
        thumbnailUrl: `${REALM_ASSETS_BUCKET}/${agentId}/${folder}/thumbnail/${strippedName}`,
        uploadSuccess: false
      };
      this.setState({
        pictures: [...this.state.pictures, newImage]
      });

      this.resizeFile(file).then(uri => {
        axios
          .request({
            method: 'put',
            url: newImage.thumbnailUrl,
            data: uri
            // onUploadProgress: myUploadProgress(file)
          })
          .then(
            data => {},
            error => {
              //console.log("failed", error)
              this.setState(prevState => ({
                pictures: prevState.pictures.map((el, index) =>
                  el.key === newImage.key
                    ? { ...el, uploadSuccess: false, pictureUrl: null }
                    : el
                ),
                uploadProgress: '0'
              }));
            }
          )
          .catch(err => {
            console.error(err);
          });
      });

      axios
        .request({
          method: 'put',
          url: newImage.pictureUrl,
          data: file,
          onUploadProgress: myUploadProgress(file)
        })
        .then(
          data => {
            //console.log(data)
            this.setState(prevState => ({
              pictures: prevState.pictures.map(el =>
                el.key === newImage.key
                  ? { ...el, uploadSuccess: true, pictureUrl: data.config.url }
                  : el
              ),
              uploadProgress: '0',
              isDirty: true
            }));
          },
          error => {
            //console.log("failed", error)
            this.setState(prevState => ({
              pictures: prevState.pictures.map((el, index) =>
                el.key === newImage.key
                  ? { ...el, uploadSuccess: false, pictureUrl: null }
                  : el
              ),
              uploadProgress: '0'
            }));
          }
        )
        .catch(err => {
          console.error(err);
          this.setState(prevState => ({
            pictures: prevState.pictures.map((el, index) =>
              el.key === newImage.key
                ? { ...el, uploadSuccess: false, pictureUrl: null }
                : el
            ),
            uploadProgress: '0'
          }));
        })
        .finally(() => {
          this.checkForValidForm();
        });
    });
  };

  removeFileDropped = e => {
    const { id } = e.currentTarget;
    const updatedPictures = this.state.pictures.filter(
      file => file.pictureUrl != id
    );
    updatedPictures.map((pic, index) => {
      pic.key = index;
      pic.isPrimary = index === 0;
    });
    this.setState(
      {
        pictures: updatedPictures
      },
      () => {
        const droppedIsEmpty = updatedPictures.length > 0;
        this.checkForValidForm();
        this.setState({
          itemDropped: droppedIsEmpty
          //imageURL: ''
        });
      }
    );
  };
  // use this to reset state to original
  // if cancel on editing listing
  resetState = () => {
    this.setState(
      {
        errorMessage: '',
        imageError: false,
        isDirty: false,
        isError: false,
        isValidForm: false,
        itemDropped: false,
        showUnsavedWarning: false
      },
      () => {
        this.setExisitingPropertyValues();
      }
    );
  };

  // use this to wipe out state after
  // create listing or cancel on create
  clearState = () => {
    if (this._isMounted) {
      this.setState(
        {
          errorMessage: '',
          imageError: false,
          isDirty: false,
          isError: false,
          isValidForm: false,
          showUnsavedWarning: false
        },
        () => {
          if (!this.props.isEditing) {
            this.setState({
              isListingTypeSelected: false,
              itemDropped: false,
              city: '',
              dateAvailable: moment().format('YYYY-MM-DD'),
              //fileToUpload: null,
              //imageURL: '',
              //videoURL: '',
              listingAddress: '',
              listingType: 'Manual',
              isRental: false,
              listingUrl: '',
              pictures: [],
              price: '',
              floorPlan: '',
              floorPlanList: [],
              propertyId: '',
              st: '',
              status: 'Active',
              tags: [],
              tagNames: [],
              termsAccepted: false,
              title: '',
              unit: '',
              zip: '',
              showFloorPlans: false,
              country: 'United States',
              description: {
                en: ''
              },
              mlsId: null
            });
          }
        }
      );
    }
  };

  setExisitingPropertyValues() {
    const { propertyData, tagsList } = this.props;
    if (!propertyData || Object.keys(propertyData).length === 0) {
      return null;
    }
    //const tags = [...propertyData.realmData.tags]
    const tags = propertyData.realmData.tags
      ? [...propertyData.realmData.tags]
      : [];
    const rentalTag = tagsList.Listing.Property.Type.find(
      el => el.tagName === 'rental_property'
    );
    const isRental = tags.find(el => el.tag === rentalTag.tagId) !== undefined;
    this.setState(
      {
        isListingTypeSelected: true,
        city:
          propertyData.property && propertyData.property.city
            ? propertyData.property.city
            : '',
        country:
          propertyData.property && propertyData.property.country
            ? propertyData.property.country
            : 'United States',
        dateAvailable: propertyData.dateAvailable
          ? moment(propertyData.dateAvailable).format('YYYY-MM-DD')
          : moment(propertyData.dateListed).format('YYYY-MM-DD') ||
            moment().format('YYYY-MM-DD'),
        // imageURL:
        //   propertyData.pictures && propertyData.pictures.length > 0
        //     ? propertyData.pictures[0].pictureUrl
        //     : '',
        //videoURL: propertyData.video
        itemDropped: '',
        //propertyData.pictures && propertyData.pictures.length > 0 &&
        //propertyData.pictures[0].pictureUrl,
        listingAddress: propertyData.property.streetAddress || '',
        listingType: propertyData.listingType || '',
        isRental,
        listingUrl: propertyData.listingUrl || '',
        pictures:
          propertyData.pictures && propertyData.pictures.length > 0
            ? propertyData.pictures.map(obj => ({
                ...obj,
                previousUpload: true
              }))
            : [],
        price: propertyData.price ? propertyData.price.amount : '',
        floorPlan: propertyData.floorPlan || '',
        floorPlanList:
          propertyData.parentListing &&
          propertyData.parentListing.property &&
          propertyData.parentListing.property.details
            ? propertyData.parentListing.property.details.floorPlans
            : [],
        propertyId: propertyData._id,
        st: propertyData.property.state || '',
        status: propertyData.status || 'Active',
        showFloorPlans: Boolean(
          propertyData.parentListing || propertyData.parentListing?._id
        ),
        tags: propertyData.realmData && propertyData.realmData.tags,
        tagNames: propertyData.realmData && propertyData.realmData.tagNames,
        termsAccepted: false,
        title: isEmpty(propertyData.title) ? '' : propertyData.title.en || '',
        unit: propertyData.property.unitNumber || '',
        zip: propertyData.property.zipcode || '',

        mlsId: propertyData.mlsId || null,
        description: propertyData.description || { en: '' }
      },
      () => {
        this.checkForValidForm();
      }
    );
    return null;
  }

  // FORM HANDLING END

  // VERIFICATION START

  checkForValidForm = () => {
    const isValid = this.isValidForm();
    if (isValid !== this.state.isValidForm) {
      this.setState({
        isValidForm: isValid
      });
    }
  };

  checkForValidZip = () => {
    if (isValidZip(this.state.zip)) {
      this.state.showZipMessage && this.setState({ showZipMessage: false });
    } else {
      !this.state.showZipMessage && this.setState({ showZipMessage: true });
    }
  };

  isValidForm = () => {
    // const tagsHaveLength = this.state.isEditing || this.state.tags.length > 0;
    const hasValidRequiredPocket =
      this.state.isDirty &&
      this.state.termsAccepted &&
      this.state.city &&
      this.state.country &&
      this.state.listingAddress &&
      this.state.description.en &&
      typeof this.state.isRental === 'boolean' &&
      //this.state.price &&
      // tagsHaveLength &&
      this.state.dateAvailable !== 'Invalid date';

    const hasValidRequiredProspect =
      this.state.isDirty &&
      this.state.city &&
      this.state.country &&
      this.state.listingAddress &&
      this.state.description.en &&
      //this.state.price &&
      // tagsHaveLength &&
      this.state.dateAvailable !== 'Invalid date';

    const hasValidRequired =
      this.state.listingType === 'Prospect'
        ? hasValidRequiredProspect
        : hasValidRequiredPocket;
    return hasValidRequired;
  };

  // VERIFICATION END

  getTagsArray = () => {
    const { tags } = this.state; //
    const { tagsList } = this.props; //get all the tag names

    //match the dev and rental tags with the db
    const newDevTag = tagsList.Listing.Property.Type.find(
      el => el.tagName === 'new_development'
    );
    const rentalTag = tagsList.Listing.Property.Type.find(
      el => el.tagName === 'rental_property'
    );

    //check if the property is a rental already
    const isRental = tags.find(el => el.tag === rentalTag.tagId) !== undefined;

    //if creating listing, add appropriate tag
    if (!this.props.isEditing) {
      if (this.props.isDevelopment) {
        this.setState(prevState => ({
          tagNames: [...prevState.tagNames, 'new_development']
        }));
        tags.push(newDevTag.tagId);
      }
      if (this.state.isRental) {
        this.setState(prevState => ({
          tagNames: [...prevState.tagNames, 'rental_property']
        }));
        tags.push(rentalTag.tagId);
      }
      if (this.state.isRental && this.props.isDevelopment) {
        this.setState(prevState => ({
          tagNames: [
            ...prevState.tagNames,
            'rental_property',
            'new_development'
          ]
        }));
        tags.push(rentalTag.tagId, newDevTag.tagId);
      }
      return tags.map(tag => ({
        tag,
        visible: true,
        modifiedByPerson: this.props.personId,
        modifyDate: new Date().toISOString()
      }));
    }
    //check if rental status has changed while editing
    if (isRental !== this.state.isRental) {
      //add rental tags to existing listing
      if (this.state.isRental) {
        this.setState(prevState => ({
          tagNames: [...prevState.tagNames, 'rental_property']
        }));
        const newTag = {
          tag: rentalTag.tagId,
          visible: true,
          modifiedByPerson: this.props.personId,
          modifyDate: new Date().toISOString()
        };
        return [...tags, newTag];
      }
      //remove rental tags from existing listing
      if (!this.state.isRental) {
        const currentTagNames = [...this.state.tagNames];
        const i = currentTagNames.indexOf('rental_property');
        i && i > -1 && currentTagNames.splice(i, 1);
        this.setState(prevState => ({
          tagNames: currentTagNames
        }));
        const currentTags = tags.filter(el => el.tag !== rentalTag.tagId);
        return currentTags;
      }
    } else {
      return tags;
    }
  };
  getListingData = async () => {
    const { isDevelopment } = this.props;
    const { developmentListing } = this.props.brokerage || '';

    const title = {};
    const { agentId, propertyData } = this.props;
    const p = this.props;
    const teamData = await api.get(`${API_ROOT}/teams/myTeam/${agentId}`);
    let listingAgents = [this.props.agentId];
    // TODO.. really check for valid team data.. this won't work.

    if (teamData && teamData.data.data.data) {
      listingAgents = teamData.data.data.data.agents.map(agent => agent._id);
    }
    title[this.props.language || 'en'] = this.state.title;
    let description = this.state.description || '';
    if (description.en) {
      description = description.en;
    }
    const baseData = {
      dateListed: new Date().toISOString(),
      listingAgents,
      listingBrokerage:
        this.props.brokerage && this.props.brokerage._id
          ? this.props.brokerage._id
          : propertyData.listingBrokerage._id,
      listingType: this.state.listingType,
      listingUrl: trim(this.state.listingUrl),
      listingAgentId: this.props.agentId,
      property: {
        streetAddress: trim(this.state.listingAddress),
        unitNumber: trim(this.state.unit),
        city: trim(this.state.city),
        state: trim(this.state.st),
        zipcode: trim(this.state.zip),
        country: trim(this.state.country)
      },
      pictures: this.state.pictures,
      title,
      archived: this.state.archived,
      mlsId: this.state.mlsId,
      description: {
        en: description
      }
    };
    if (this.state.showFloorPlans && this.state.floorPlan) {
      baseData.floorPlan = this.state.floorPlan;
    }
    const createData = {
      realmData: {
        tags: this.getTagsArray(),
        tagNames: this.state.tagNames,
        lifestyles: this.props.isEditing
          ? this.props.propertyData.realmData.lifestyles
          : this.state.lifestyles,
        lifestyleNames: this.props.isEditing
          ? this.props.propertyData.realmData.lifestyleNames
          : this.state.lifestyleNames
      }
    };

    const pocketData = {
      dateAvailable: new Date(this.state.dateAvailable).toISOString(),
      price: {
        amount: this.state.price ? trim(this.state.price.toString()) : '0.00',
        currency: 'USD'
      },
      status: this.state.status
    };
    const listingData =
      !this.state.listingType !== 'Prospect'
        ? { ...baseData, ...createData, ...pocketData }
        : baseData;

    if (isDevelopment && developmentListing) {
      listingData.parentListing = developmentListing;
    }
    return listingData;
  };

  createListing = async () => {
    this.setState(prevState => ({
      isCreatingListing: true
    }));
    await this.writeListingToAPI();
  };

  tryNewImage = () => {
    this.setState({
      errorMessage: '',
      isCreatingListing: false,
      isError: false,
      imageError: false,
      itemDropped: false
      //fileToUpload: null,
      // imageURL: ''
    });
  };

  tryWithoutImage = () => {
    this.setState(
      {
        isCreatingListing: false,
        isError: false,
        imageError: false,
        itemDropped: false
        //fileToUpload: null,
        // imageURL: ''
      },
      () => {
        this.writeListingToAPI();
      }
    );
  };

  writeListingToAPI = async () => {
    const listingData = await this.getListingData();
    try {
      this.props.isEditing
        ? await api.put(
            `${API_ROOT}/listings/${this.state.propertyId}`,
            listingData
          )
        : await api.post(`${API_ROOT}/listings/`, listingData);
      this.showSuccessDialog();
      if (this._isMounted) {
        this._delay = setTimeout(() => {
          this.clearState();
        }, 2000);
      }
    } catch (err) {
      const errMsg =
        err.response && err.response.data ? err.response.data.msg || err : err;
      this.setState({
        isCreatingListing: false,
        errorMessage: `There was an error: ${errMsg}`,
        isError: true
      });
    }
  };

  // DIALOGS
  showSuccessDialog = () => {
    if (this._isMounted) {
      this.setState(
        { isCreatingListing: false, showSaveSuccessful: true },
        () => {
          setTimeout(() => {
            if (this._isMounted) {
              this.setState({ showSaveSuccessful: false });
            }
            if (isFunction(this.props.handleClose)) {
              this.props.handleClose(true);
            }
          }, 1500);
        }
      );
    }
  };

  showContentDialog = e => {
    e.preventDefault();
    this.setState({ showContentDialog: true });
  };

  hideContentDialog = () => {
    this.setState({ showContentDialog: false });
  };

  closeErrorDialog = () => {
    this.setState({
      errorMessage: '',
      isError: false
    });
  };

  cancelAddListing = () => {
    if (isFunction(this.props.handleClose)) {
      this.props.handleClose(false);
      this.props.isEditing ? this.resetState() : this.clearState();
    }
  };

  showUnsavedWarning = () => {
    this.setState({
      showUnsavedWarning: true
    });
  };

  closeUnsavedWarning = () => {
    this.setState({
      showUnsavedWarning: false
    });
  };

  // RENDER
  getStates = () => {
    const states = Object.keys(USStates);
    return states.map(key => (
      <MenuItem key={key} value={key}>
        {key}
      </MenuItem>
    ));
  };

  getCountries = () =>
    countries.map(country => (
      <MenuItem key={country} value={country}>
        {country}
      </MenuItem>
    ));
  renderForm = () => {
    if (this.props.isEditing) {
      return this.renderCreateForm();
    }
    return this.state.isListingTypeSelected || this.props.isListingsOnly
      ? this.renderCreateForm()
      : this.renderSelectListingForm();
  };

  renderSelectListingForm = () => (
    <div style={{ fontSize: 18, lineHeight: 1.2 }}>
      <h4>Select your listing type:</h4>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="Listing Type"
          name="listingType"
          value={this.state.listingType}
          onChange={this.selectListingType}
        >
          <FormControlLabel
            value="Manual"
            control={<Radio color="primary" />}
            label="Public Listing: This listing type will be displayed to all REALM
        users. You have exclusive rights to list this property."
          />
          <FormControlLabel
            value="Pocket"
            control={<Radio color="primary" />}
            label="REALM Listing: This listing type will be displayed to all REALM users
          but the property is only available on REALM, not other real estate
          sites."
          />
          <FormControlLabel
            value="Prospect"
            control={<Radio color="primary" />}
            label="Prospect Listing: This listing type will not be displayed to REALM
            users."
          />
        </RadioGroup>
      </FormControl>
    </div>
  );

  renderCreateForm = () => {
    const { country, isRental, floorPlanList, showFloorPlans } = this.state;
    const { isDevelopment } = this.props;
    return (
      <>
        <div style={{ marginBottom: 20, fontSize: 18, lineHeight: 1.5 }}>
          <span>
            Enter your {!isDevelopment ? 'listing' : 'development'} details and
            allow REALM to showcase possible client matches for your prospective
            property.
          </span>
        </div>
        <Grid container spacing={4}>
          {/* <Grid item xs={12} lg={this.props.isEditing ? 8 : 6}> */}
          <Grid item xs={12} xl={8}>
            <Grid xs={12}>
              <h3>{!isDevelopment ? 'Property' : 'Development'} Details</h3>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="flex-start"
              spacing={4}
            >
              <Grid item xs={12} sm={8}>
                <Grid spacing={4} style={{ marginTop: 20 }}>
                  {!this.props.isListingsOnly && (
                    <Grid item xs={12} sm={5}>
                      <FormControl style={{ display: 'block', width: '100%' }}>
                        <InputLabel htmlFor="listingType">
                          {localstring.listing_type}
                        </InputLabel>
                        <Select
                          fullWidth
                          value={this.state.listingType}
                          onChange={this.handleChange('listingType')}
                          inputProps={{
                            name: 'listingType',
                            id: 'listingType'
                          }}
                        >
                          <MenuItem value="Manual">Public</MenuItem>
                          <MenuItem value="Pocket">REALM</MenuItem>
                          <MenuItem value="Prospect">Prospect</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    sm={this.props.isEditing ? 12 : 9}
                    style={{ marginTop: 20 }}
                  >
                    <TextField
                      fullWidth
                      id="title"
                      label="Title"
                      type="text"
                      name="title"
                      onChange={this.handleChange('title')}
                      value={this.state.title}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4} style={{ marginTop: 20 }}>
                  <Grid item xs={12} sm={9}>
                    <TextField
                      fullWidth
                      helperText="e.g. 123 Main St."
                      id="listingAddress"
                      label="Street Address"
                      required
                      type="text"
                      name="listingAddress"
                      onChange={this.handleChange('listingAddress')}
                      value={this.state.listingAddress}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      fullWidth
                      id="unit"
                      label="Unit #"
                      type="text"
                      name="unit"
                      onChange={this.handleChange('unit')}
                      value={this.state.unit}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={7}>
                    <TextField
                      fullWidth
                      id="city"
                      label={localstring.city}
                      required
                      type="text"
                      name="city"
                      onChange={this.handleChange('city')}
                      value={this.state.city}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    {this.state.country === 'United States' && (
                      <FormControl style={{ display: 'block', width: '100%' }}>
                        <InputLabel htmlFor="st">
                          {localstring.state}
                        </InputLabel>
                        <Select
                          id="stateSelector"
                          fullWidth
                          value={this.state.st}
                          onChange={this.handleChange('st')}
                          inputProps={{
                            name: 'st',
                            id: 'st'
                          }}
                        >
                          {this.getStates()}
                        </Select>
                      </FormControl>
                    )}
                    {this.state.country !== 'United States' && (
                      <TextField
                        fullWidth
                        id="st"
                        label={localstring.state}
                        type="text"
                        name="st"
                        onChange={this.handleChange('st')}
                        value={this.state.st}
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      fullWidth
                      id="zip"
                      label={localstring.zip}
                      type="text"
                      name="zip"
                      onChange={this.handleChange('zip')}
                      value={this.state.zip}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl style={{ display: 'block', width: '100%' }}>
                      <InputLabel htmlFor="country">
                        {localstring.country} *
                      </InputLabel>
                      <Select
                        fullWidth
                        required
                        value={this.state.country}
                        onChange={this.handleChange('country')}
                        inputProps={{
                          name: 'country',
                          id: 'country'
                        }}
                      >
                        {this.getCountries()}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid item xs={12} lg={this.props.isEditing ? 4 : 3}> */}
              <Grid item xs={12} sm={4}>
                <Grid
                  container
                  spacing={4}
                  style={{ marginTop: 0, marginBottom: 20 }}
                >
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="price"
                      label="Price"
                      type="text"
                      name="price"
                      onChange={this.handleChange('price')}
                      value={this.state.price}
                      InputProps={{
                        inputComponent: NumberFormatCustom
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {/* <FormControlLabel
                      control={
                        <Checkbox
                          id='Rental'
                          label="Rental?"
                          name="rental"
                          onChange={() => this.setState({isRental: !this.state.isRental})}
                          value={this.state.isRental} />
                      }
                      label="Rental Property"/> */}
                  </Grid>
                  {/* {!this.props.isListingsOnly &&
                    this.state.listingType !== 'Public' &&
                    this.state.listingType !== '' && (
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          style={{ display: 'block', width: '100%' }}
                        >
                          <InputLabel htmlFor="listingType">
                            {!isDevelopment ? 'Listing Type' : 'Development Type'}
                          </InputLabel>
                          <Select
                            fullWidth
                            value={this.state.listingType}
                            onChange={this.handleChange('listingType')}
                            inputProps={{
                              name: 'listingType',
                              id: 'listingType'
                            }}
                          >
                            <MenuItem value="Manual">Public</MenuItem>
                            <MenuItem value="Pocket">REALM</MenuItem>
                            <MenuItem value="Prospect">Prospect</MenuItem>
                          </Select>
                        </FormControl> */}
                  {/* )} */}
                  {!this.props.isListingsOnly && (
                    <Grid item xs={12} md={6}>
                      <FormControl style={{ display: 'block', width: '100%' }}>
                        <InputLabel htmlFor="status">Status</InputLabel>
                        <Select
                          fullWidth
                          value={this.state.status}
                          onChange={this.handleChange('status')}
                          inputProps={{
                            name: 'status',
                            id: 'status'
                          }}
                        >
                          <MenuItem value="Active">Active</MenuItem>
                          <MenuItem value="Coming Soon">Coming Soon</MenuItem>
                          <MenuItem value="Pending">Pending</MenuItem>
                          <MenuItem value="Under Contract">
                            Under Contract
                          </MenuItem>
                          {/* <MenuItem value="Inactive">Inactive</MenuItem> */}
                          <MenuItem value="hidden">Archived</MenuItem>
                          {/* <MenuItem value="None">None</MenuItem> */}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="dateAvailable"
                      label="Date Available"
                      type="date"
                      name="dateAvailable"
                      required
                      onChange={this.handleChange('dateAvailable')}
                      value={this.state.dateAvailable}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    {!isDevelopment ? (
                      <TextField
                        fullWidth
                        id="mlsId"
                        label={'MLS ID'}
                        type="text"
                        name="mlsId"
                        onChange={this.handleChange('mlsId')}
                        value={this.state.mlsId}
                      />
                    ) : (
                      ''
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="listingUrl"
                      label={localstring.listingUrl}
                      type="text"
                      name="listingUrl"
                      onChange={this.handleChange('listingUrl')}
                      value={this.state.listingUrl}
                    />
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="Rental"
                          label="Rental?"
                          name="rental"
                          onChange={() =>
                            this.setState({
                              isRental: !this.state.isRental,
                              isDirty: true
                            })
                          }
                          checked={isRental}
                          value={isRental}
                        />
                      }
                      label="Rental Property"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ padding: '8px 16px' }}>
                <div>
                  <TextField
                    fullWidth
                    id="listingDescription"
                    label={localstring.description}
                    type="text"
                    required
                    multiline
                    name="description"
                    onChange={this.handleDescriptionChange()}
                    value={this.state.description.en}
                  />
                </div>
              </Grid>
              {showFloorPlans && (
                <Grid item xs={12} style={{ padding: '16px 16px' }}>
                  {floorPlanList && floorPlanList.length > 0 ? (
                    <FormControl style={{ minWidth: 200 }}>
                      <InputLabel id="floorplan-label">Floorplan</InputLabel>
                      <Select
                        value={this.state.floorPlan}
                        onChange={this.handleChange('floorPlan')}
                        id="floorPlan"
                        name="floorPlan"
                        autoWidth
                        // inputProps={{
                        //   name: 'floorplan',
                        //   id: 'floorplan'
                        // }}
                      >
                        <MenuItem value={''} key={`floorplan-none`}></MenuItem>
                        {floorPlanList.map(plan => (
                          <MenuItem
                            value={plan.name}
                            key={`floorplan-${plan.name}`}
                          >
                            {plan.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <div style={{ margin: '1rem 0rem' }}>
                      <h4>Floorplan</h4>
                      <Link to={`match-detail/?id=${this.state.propertyId}`}>
                        Please visit the property page to add or edit floor
                        plans.{' '}
                      </Link>
                    </div>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} xl={8}>
            <Grid item xs={12} sm={7}>
              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <h3>Upload Photo</h3>
                <p>
                  Drag and drop a photo, or click on the box below to select
                  images to upload.
                </p>
              </div>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="flex-start"
            >
              <Grid item xs={12} sm={6}>
                <div style={{ marginTop: 20 }}>
                  <FileDrop
                    acceptedDrops={this.acceptedDrop}
                    rejectedDrops={this.rejectedDrop}
                    show={this.state.listingAddress !== ''}
                  />
                  {/* REMOVED IMAGE URL UPLOAD */}
                  {/* <div style={{ marginTop: 10, marginBottom: 20 }}>
                    <TextField
                      fullWidth
                      id="imageURL"
                      label="Enter image URL(single images only)"
                      type="text"
                      name="imageURL"
                      onChange={this.handleChange('imageURL')}
                      value={this.state.imageURL}
                    />
                  </div> */}

                  {/* PLACEHOLDER FOR VIDEO UPLOAD */}
                  {/* <div style={{ marginTop: 10, marginBottom: 20 }}>
                    <TextField
                      fullWidth
                      id="videoURL"
                      label="Enter URL of Video to upload"
                      type="text"
                      name="videoURL"
                      onChange={this.handleChange('videoURL')}
                      value={this.state.videoURL}
                    />
                  </div>
                  */}
                </div>
              </Grid>
              <Grid item xs={12} sm={5}>
                <div style={{ marginTop: 20, marginBottom: 4 }}>
                  <div
                    style={{
                      border: '2px solid #C5C3BC',
                      boxSizing: 'border-box',
                      borderRadius: 8,
                      marignTop: 20,
                      maxHeight: 250,
                      overflow: 'auto'
                    }}
                  >
                    {this.state.itemDropped ? (
                      Object.values(this.state.pictures).map(i => (
                        <React.Fragment key={i.pictureUrl}>
                          {!i.previousUpload ? (
                            <div
                              style={{
                                display: 'flex',
                                flexFlow: 'row nowrap',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                border: '1px grey solid',
                                borderRadius: 8,
                                margin: 2
                              }}
                              key={i.key}
                            >
                              <div>
                                {/* preview image */}
                                {i.uploadSuccess ? (
                                  <img
                                    alt=""
                                    style={{
                                      height: 30,
                                      margin: 5,
                                      marginRight: 10
                                    }}
                                    src={i.pictureUrl}
                                  />
                                ) : (
                                  <CircularProgress
                                    color="primary"
                                    size={20}
                                    thickness={2}
                                    className="circular-progress"
                                  />
                                )}
                                <span>
                                  <small>{i.name}</small>
                                </span>
                              </div>
                              <div>
                                <Tooltip title="Delete">
                                  <IconButton
                                    aria-label="delete"
                                    id={i.pictureUrl}
                                    onClick={this.removeFileDropped}
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                        </React.Fragment>
                      ))
                    ) : (
                      <div>
                        <p style={{ textAlign: 'center' }}>
                          Images to upload will appear here
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={7} style={{ marignTop: 20 }}>
            {this.state.listingType !== 'Prospect' ? (
              <FormControl
                component="fieldset"
                style={{ display: 'block', width: '100%', marginTop: 32 }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.termsAccepted}
                        color="primary"
                        id="termsAccepted"
                        name="termsAccepted"
                        required
                        onChange={this.onChecked}
                      />
                    }
                    label={
                      <span>
                        By submitting this listing, you agree that you have
                        exclusive rights to list this property and to the{' '}
                        <a
                          href="/"
                          style={{
                            color: '#d3d3d3',
                            textDecoration: 'underline'
                          }}
                          onClick={this.showContentDialog}
                        >
                          REALM Terms & Conditions
                        </a>
                        .
                      </span>
                    }
                  />
                </FormGroup>
              </FormControl>
            ) : (
              <Grid item xs={12}>
                {!this.props.isMobile && <div style={{ height: 54 }} />}
              </Grid>
            )}
          </Grid>

          <Grid container spacing={4}></Grid>
        </Grid>
      </>
    );
  };

  render() {
    return (
      <>
        <Dialog
          aria-describedby="listing-dialog"
          aria-labelledby="listing-dialog-title"
          open={this.props.open || false}
          PaperProps={{ style: { maxWidth: '85%' } }}
          fullScreen={this.props.isMobile ? true : false}
        >
          {this.state.isCreatingListing && (
            <div className="realm--loading-container fixed overlay">
              <LoadingIndicator />
            </div>
          )}

          <DialogTitle id="listing-dialog-title">
            {this.props.isEditing
              ? 'Edit Listing'
              : this.props.isDevelopment
              ? 'Create New Development Unit'
              : 'Create New Listing'}
            <IconButton
              onClick={
                this.state.isDirty ||
                (!this.props.isEditing && this.state.isListingTypeSelected)
                  ? this.showUnsavedWarning
                  : this.cancelAddListing
              }
              aria-label="Close"
              style={{
                float: 'right',
                margin: '0px 8px',
                backgroundColor: '#64626286'
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className="realm--modal-content">
            {this.state.isLoading ? (
              <div className="realm--loading-container">
                <LoadingIndicator />
              </div>
            ) : (
              this.renderForm()
            )}

            <DialogActions>
              <Button
                onClick={
                  this.state.isDirty ||
                  (!this.props.isEditing && this.state.isListingTypeSelected)
                    ? this.showUnsavedWarning
                    : this.cancelAddListing
                }
                color="default"
                variant="contained"
              >
                {localstring.cancel}
              </Button>
              {this.state.isListingTypeSelected ? (
                <Button
                  onClick={this.createListing}
                  color="primary"
                  variant="contained"
                  disabled={!this.state.isValidForm}
                >
                  {localstring.onboarding.save}
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    this.setState({ isListingTypeSelected: true });
                  }}
                  color="primary"
                  variant="contained"
                >
                  {localstring.onboarding.continue}
                </Button>
              )}
            </DialogActions>
          </DialogContent>
        </Dialog>

        <ContentDialog
          language={this.props.language}
          onClose={this.hideContentDialog}
          open={this.state.showContentDialog}
          slug="terms-and-conditions"
        />
        <AlertDialog
          aria-describedby="success-dialog"
          open={this.state.showSaveSuccessful}
          message={localstring.successChanges}
          title={localstring.success}
        />
        <AlertDialog
          aria-describedby="unsaved-warning-dialog"
          open={this.state.showUnsavedWarning}
          message={`${localstring.unsaved_changes_msg1}<br />${localstring.unsaved_changes_msg2}`}
          title={localstring.unsaved_changes}
          actionsMap={[
            {
              onClick: this.closeUnsavedWarning,
              text: localstring.no
            },
            {
              color: 'primary',
              onClick: this.cancelAddListing,
              text: localstring.yes
            }
          ]}
        />
        <ErrorDialog
          handleRetry={this.state.imageError ? this.tryWithoutImage : undefined}
          handleClose={
            this.state.imageError ? this.tryNewImage : this.closeErrorDialog
          }
          message={this.state.errorMessage}
          okBtnOverride={this.state.imageError ? 'Save Without Image' : 'Close'}
          open={this.state.isError}
        />
      </>
    );
  }
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

AddListingDialog.propTypes = {
  agentId: PropTypes.string,
  isDevelopment: PropTypes.bool,
  brokerage: PropTypes.object,
  handleClose: PropTypes.func,
  isEditing: PropTypes.bool,
  isMobile: PropTypes.bool,
  language: PropTypes.string,
  personId: PropTypes.string,
  propertyData: PropTypes.object,
  open: PropTypes.bool,
  tagsList: PropTypes.object
};

export default AddListingDialog;
