import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, InputAdornment, MenuItem, TextField } from '@material-ui/core';
import { useQuery } from '@tanstack/react-query';
import { QRCodeCanvas } from 'qrcode.react';
import AlertDialog from '../Dialogs/AlertDialog';
import api from '../../api';
import { API_ROOT } from '../../constants';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';

const resetData = {
  agentId: null,
  agentFullName: null
};
const ReferralDialog = ({
  agentId,
  agentFullName,
  handleReferralDialog,
  fromAdmin = false
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const { data, isLoading, isError } = useQuery(
    ['referralCode', agentId],
    async () => {
      const response = await api.get(
        `${API_ROOT}/shortCodes/referral/${agentId}`
      );
      return response.data.data;
    },
    { enabled: !!agentId, refetchOnWindowFocus: false }
  );
  const referralUrl = data?.url || null;

  const copyToClipboard = async text => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text);
      setIsCopied(true);
    } else {
      document.execCommand('copy', true, text);
      setIsCopied(true);
    }
  };
  const handleClose = () => {
    handleReferralDialog({ ...resetData });
    setIsCopied(false);
  };
  const downloadQRCode = () => {
    const canvas = document.getElementById(`qrCode-${agentFullName}`);
    const qrImage = canvas.toDataURL();
    const imageLink = document.createElement('a');
    imageLink.download = `realm-referral-${agentFullName}`;
    imageLink.href = qrImage;
    imageLink.click();
  };
  return (
    <AlertDialog
      labeledby=""
      describedby="refer-a-friend-dialog"
      showCloseButton={true}
      onClose={handleClose}
      className="realm--referralDialog"
      open={agentId || false}
      title="Refer a Member"
      actionsMap={[
        {
          color: 'primary',
          onClick: handleClose,
          text: 'Close'
        }
      ]}
    >
      <div className="realm--referralDialog-body">
        <div className="realm--referralDialog-copy">
          {fromAdmin ? (
            <p style={{ marginBlockStart: 0 }}>
              Each REALM Member has a unique Referral Code. You can share both
              the QR code and link with this Member, which allows us to
              correlate the Referring Member to the Prospective Member. As a
              reminder, if you create a new Member based on a referral, you need
              to add the Referral Code when you invite the member (or create the
              Team for Manual subscriptions).
            </p>
          ) : (
            <>
              <p style={{ marginBlockStart: 0 }}>
                REALM is a membership of the most accomplished luxury real
                estate professionals ever assembled. Fostering community between
                our members is key, and as such, quality referrals are vitally
                important to continuing to build meaningful connections within
                REALM.
              </p>
              <p>
                Welcome to the REALM referral platform, where technology and
                community intersect seamlessly to provide opportunities for
                existing members to track their suggested members journey to
                joining REALM. The unique QR code and link below should be
                provided to the ideal REALM candidate, so that the REALM team
                can monitor and assist with their onboarding.
              </p>
            </>
          )}
        </div>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <div className="realm--referralDialog-qrContainer">
              <QRCodeCanvas
                value={referralUrl}
                id={`qrCode-${agentFullName}`}
              />
              <Button
                variant="outlined"
                size="small"
                // color="secondary"
                onClick={downloadQRCode}
              >
                Download QR Code
              </Button>
            </div>
            <div className="realm--referralDialog-qrContainer">
              <TextField
                variant="outlined"
                id="referral-link"
                fullWidth
                label="Referral Link"
                defaultValue={referralUrl}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        variant="contained"
                        style={{
                          background: '#6817d5'
                        }}
                        size="small"
                        color="secondary"
                        onClick={() => copyToClipboard(referralUrl)}
                      >
                        {isCopied ? 'Copied!' : 'Copy to clipboard'}
                      </Button>
                    </InputAdornment>
                  )
                }}
                size="small"
              />
            </div>
          </>
        )}
      </div>
    </AlertDialog>
  );
};
ReferralDialog.propTypes = {
  agentId: PropTypes.string,
  agentFullName: PropTypes.string,
  handleReferralDialog: PropTypes.func
};
export default ReferralDialog;
