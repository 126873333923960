import React from 'react';
import {useDropzone} from 'react-dropzone';
import AlertDialog from '../../components/Dialogs/AlertDialog';
import { MAX_UPLOAD_MEGABYTES, MAX_UPLOAD_SIZE } from '../../constants';

function FileDrop(props) {
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    show
  } = useDropzone({
    accept: 'image/jpeg, image/png, image/jpg',
    minSize: 0,
    maxSize: MAX_UPLOAD_SIZE,
    multiple: true,
    onDropAccepted: files => props.acceptedDrops(files),
    onDropRejected: files => props.rejectedDrops(files),
    disabled: !props.show
  });
  const acceptedFileItems = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));
  // const passFiles= (files) => {
  //   this.props.callbackFromParent(files)
  // }

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      <ul>
        {errors.map(e => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  return (
    <section className="container">
      <div {...getRootProps({ className: 'dropzone realm-onboarding--dropzone' })}>
        <input {...getInputProps()} />
        {!isDragActive && 
          <>
            {props.show ? (
              <div>
                <p>Drop images here <br /> or click to add new images<br /></p> 
                <small>
                  Only jpg, jpeg, and png files will be accepted. <br /> Max file size: {MAX_UPLOAD_MEGABYTES}MB
                </small>
              </div>
            ) : (
              <div>
                <p>Please enter a street address before uploading images<br /></p> 
              </div>
            )}
          </>
        }
        {isDragActive && !isDragReject && 
        <div>
        <p>Drop it like it's hot.<br /></p> 
        <small>
          Only jpg, jpeg, and png files will be accepted. <br /> Max file size: {MAX_UPLOAD_MEGABYTES}MB
          </small>
          </div>
        }
        {isDragReject && 
        <div>
        <p>File type or size not accepted, sorry.</p> 
        <p>
          Only jpg, jpeg, and png files will be accepted. <br /> Max file size: {MAX_UPLOAD_MEGABYTES}MB
          </p>
          </div>
        }
      </div>
    </section>
  );
}

export default FileDrop;