import parsePhoneNumber from 'libphonenumber-js'

const isUsNumber = parsedNumber => (
  parsedNumber.country === "US"
);

export default function phoneFormatter(phoneNumber) {
  if (phoneNumber === undefined || phoneNumber === null) {
    return phoneNumber
  }

  const numberWithoutSpaces = phoneNumber.replace(' ', '');

  if (numberWithoutSpaces.startsWith("+") || numberWithoutSpaces.startsWith("(+")) {
    const parsedNumber = parsePhoneNumber(numberWithoutSpaces);

    if (parsedNumber) {
      if (isUsNumber(parsedNumber)) {
        return parsedNumber.formatNational();
      }

      return parsedNumber.formatInternational()
    }

    return phoneNumber;
  }

  const parsedNumber = parsePhoneNumber(`+1${numberWithoutSpaces}`);

  if (parsedNumber) {
    if (isUsNumber(parsedNumber)) {
      return parsedNumber.formatNational();
    }

    return parsedNumber.formatInternational()
  }

  return phoneNumber;
}
