/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '@material-ui/core';
import localstring from '../../../services/Localization';

const PaymentFailedDialog = props => (
  <Dialog open={props.open}>
    <DialogTitle>Return to Payment Options</DialogTitle>
    <DialogContent className="realm--modal-content">
      <div>
        <p style={{ marginTop: 0 }}>
          If you entered credit card information, we are sorry but your payment
          was not able to be processed at this time. Either try again, or if you need further assistance, contact your
          financial institution. If you continue to have issues, please contact a REALM representative
          directly at{' '}
          <a
            style={{ textDecoration: 'underline' }}
            href="mailto:concierge@realm-global.com"
          >
            concierge@realm-global.com
          </a>
          . We apologize for the inconvenience.
        </p>
        <p>
          If you did not enter your credit card information, you may update your
          billing terms (annual or monthly) on the Payment Options page. Once you have 
          updated your billing terms, select the “Pay Now” button.
        </p>
      </div>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.onClose} color="primary" variant="contained">
        {localstring.onboarding.ok}
      </Button>
    </DialogActions>
  </Dialog>
);

PaymentFailedDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};

export default PaymentFailedDialog;
