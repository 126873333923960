import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Select,
  MenuItem,
  FilledInput,
  makeStyles,
  Popover,
  ListItem,
  ListItemText,
  List,
  Paper,
  ListItemAvatar,
  Avatar,
  Divider,
  IconButton,
  useTheme
  //Divider
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
//import FilterListIcon from '@material-ui/icons/FilterList';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
//import { Wrapper } from '@googlemaps/react-wrapper';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { useGlobalSearch } from './useGlobalSearch';
import ErrorDialog from '../Dialogs/ErrorDialog';

import SearchIcon from '../../images/icons/R_Icon_Search.svg';
import DropdownIcon from '../../images/icons/R_Icon_Chevron_Down.svg';
import eventTracking from '../../utils/event-tracking';
import getPage from '../../utils/getPage';

const defaultForm = { category: 'all', search: '' };
const searchCategories = [
  {
    value: 'all',
    label: 'All',
    isDefault: true
  },
  {
    value: 'location',
    label: 'Location'
  },
  {
    value: 'developments',
    label: 'Developments'
  },
  {
    value: 'properties',
    label: 'Properties'
  },
  {
    value: 'clients',
    label: 'Clients'
  },
  {
    value: 'agents',
    label: 'Members'
  }
];
const useStyles = makeStyles(() => ({
  form: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  },
  search: {
    alignItems: 'center',
    display: 'grid',
    columnGap: 15,
    gridTemplateColumns: 'minmax(0, 1fr) auto auto auto',
    backgroundColor: 'var(--input-background)',
    borderRadius: '26px',
    paddingInline: '20px 25px',
    height: 44,
    justifyContent: 'center',
    width: 'min(530px, 100%)'
  },
  searchMobile: {
    paddingInlineEnd: 0
  },
  spacer: {
    width: 1,
    height: 20,
    opacity: 0.15,
    backgroundColor: 'var(--black)'
  },
  link: {
    color: 'var(--black)'
  },
  searchListItem: {
    color: 'var(--black)'
  }
}));

const useInputClasses = makeStyles(theme => ({
  root: {
    backgroundColor: 'transparent',
    color: 'var(--black)',
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '15px',
    lineHeight: '18px',
    height: '18px',

    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  focused: {
    '&&': {
      backgroundColor: 'transparent'
    }
  },
  input: {
    padding: 0,
    '&:-webkit-autofill': {
      '-webkit-text-fill-color': 'var(--black)',
      '-webkit-box-shadow': '0 0 0 1000px var(--input-background) inset',
      animationName: 'none',
      backgroundColor: 'transparent',
      caretColor: 'var(--black)'
    },

    '&::placeholder': {
      color: 'var(--input-placeholder)',
      opacity: 1
    }
  }
}));

const useSelectClasses = makeStyles(() => ({
  root: {
    color: 'var(--input-placeholder)',
    fontSize: 15,
    paddingRight: 25,
    display: 'flex',
    textAlign: 'right',
    justifyContent: 'flex-end',
    height: '100%'
  },
  icon: {
    color: 'var(--input-placeholder)',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    pointerEvents: 'none',
    right: 0
  }
}));

const GlobalSearch = ({
  agentId,
  isAgent,
  currentPerson,
  push,
  hideCategories,
  isAdmin,
  isPhone,
  onResultClick
}) => {
  const [formState, setFormState] = useState({ ...defaultForm });
  const [locationObject, setLocationObject] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState({ ...defaultForm });
  const locationRef = useRef(null);
  const formRef = useRef(null);

  const isMobile = useSelector(state => state.application.isMobile);

  // Data Fetching Hook
  const {
    agents,
    properties,
    clients,
    developments,
    brokerages,
    isLoading,
    isError,
    isSuccess,
    clearError
  } = useGlobalSearch(
    search.category,
    search.search,
    agentId,
    currentPerson._id,
    isAdmin
  );

  const closeErrorDialog = () => {
    clearError();
  };
  // Redirect to page search if a specific category is selected
  useEffect(() => {
    if (search.search) {
      eventTracking(
        `${getPage()}-Header`,
        'Global Search',
        `${search.category}: ${search.search}`
      );
    }
  }, [search.search, search.category]);

  const classes = useStyles();
  const inputClasses = useInputClasses();
  const selectClasses = useSelectClasses();

  const theme = useTheme();

  const autocompleteClasses = useMemo(
    () => ({
      container: baseStyles => ({
        ...baseStyles,
        width: '100%',
        minWidth: 190
      }),
      input: baseStyles => ({
        ...baseStyles
      }),
      control: baseStyles => ({
        ...baseStyles,
        backgroundColor: 'transparent',
        borderWidth: 0,
        boxShadow: 'none',
        height: '26px',
        minHeight: 0,
        outline: 'none',

        '&& > *': {
          padding: 0
        }
      }),
      placeholder: baseStyles => ({
        ...baseStyles,
        color: 'var(--input-placeholder)',
        fontFamily: theme.typography.fontFamily,
        fontSize: '15px',
        fontWeight: theme.typography.fontWeightRegular,
        height: '18px',
        lineHeight: '18px'
      }),
      singleValue: baseStyles => ({
        ...baseStyles,
        color: 'var(--black)',
        fontFamily: theme.typography.fontFamily,
        fontSize: '15px',
        fontWeight: theme.typography.fontWeightRegular,
        height: '18px',
        lineHeight: '18px'
      }),
      menu: baseStyles => ({
        ...baseStyles,
        zIndex: theme.zIndex.drawer + 1
      })
    }),
    [theme]
  );

  //locally handles changes to location
  useEffect(() => {
    if (formState.category === 'location' && formState.search) {
      setLocationObject(
        formState.search
          ? {
              label: formState.search,
              value: { label: formState.search, place_id: '' }
            }
          : ''
      );
    }
  }, [formState]);

  // Triggers search api calls
  const applySearch = useCallback(
    (payload = null) => {
      setSearch(payload ? { ...payload } : { ...formState });
    },
    [formState]
  );

  // Handle search input change
  const handleSearchChange = e => {
    if (e.target.name === 'category' && e.target.value === 'location') {
      //clears data to make autocomplete cleaner
      setLocationObject('');
      setFormState({ search: '', [e.target.name]: e.target.value });
      return;
    }
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };
  const handleClear = () => {
    //console.log('clearing');
    setFormState({ ...defaultForm, category: formState.category });
    setLocationObject('');
    setAnchorEl(null);
    applySearch({ ...defaultForm, category: formState.category });
  };
  //passes location up the top component
  const handleLocationChange = (value, reason) => {
    if (reason.action === 'clear') {
      handleClear();
      return;
    }
    const newState = { ...formState, search: value.label };
    setFormState(newState);
    setLocationObject(value);
    setAnchorEl(locationRef.current);
    applySearch(newState);
  };
  const handleSearch = async e => {
    e.preventDefault();
    //disable unless a location is chosen ensuring select is used
    if (formState.category === 'location' && formState.search === '') {
      return;
    }
    if (!anchorEl)
      setAnchorEl(
        isPhone && formRef?.current ? formRef.current : e.currentTarget
      );
    applySearch();
  };
  const handleKey = e => {
    if (e.key === 'Enter') {
      handleSearch(e);
      setAnchorEl(e.currentTarget);
    }
  };
  const searchInput = () => (
    <form className={classes.form} onKeyDown={handleKey} ref={formRef}>
      <div
        className={classNames(classes.search, isMobile && classes.searchMobile)}
        style={isPhone ? { width: '100%' } : {}}
        ref={locationRef}
      >
        {formState.category === 'location' ? (
          <GooglePlacesAutocomplete
            apiKey="AIzaSyBgNxnzkj4dxJzZpyPgEvwmtOEIJcZ6oMs"
            autocompletionRequest={{ types: ['geocode'] }}
            minLengthAutocomplete={2}
            selectProps={{
              placeholder: 'Search...',
              isClearable: true,
              styles: autocompleteClasses,
              components: {
                IndicatorSeparator: () => null,
                IndicatorsContainer: () => null
              },
              value: locationObject,
              onChange: handleLocationChange
            }}
          />
        ) : (
          <FilledInput
            id="page-search"
            type="text"
            name="search"
            classes={inputClasses}
            aria-label="page search"
            placeholder="Search..."
            disableUnderline
            value={formState.search || ''}
            onChange={handleSearchChange}
          />
        )}
        {!hideCategories && (
          <Select
            id="search-category"
            value={formState.category}
            name="category"
            disableUnderline
            onChange={handleSearchChange}
            classes={selectClasses}
            IconComponent={
              searchCategories && searchCategories.length <= 1
                ? () => null
                : ({ className }) => (
                    <img className={className} src={DropdownIcon} alt="" />
                  )
            }
            size="small"
            inputProps={{
              'aria-label': 'Search category'
            }}
          >
            {searchCategories &&
              searchCategories.length > 0 &&
              searchCategories.map(category => (
                <MenuItem
                  value={category.value}
                  key={`clients-search-${category.value}`}
                >
                  {category.label}
                </MenuItem>
              ))}
          </Select>
        )}
        <div className={classes.spacer} />
        <IconButton
          aria-label="search"
          onClick={handleSearch}
          variant="text"
          size="small"
        >
          <img src={SearchIcon} alt="search" />
        </IconButton>
      </div>
    </form>
  );

  const skeletonHelper = (numRows = 3, type, w, h) =>
    new Array(numRows)
      .fill({})
      .map((el, index) => (
        <Skeleton
          key={`skeleton-${type}-${index}`}
          height={h}
          style={{ minWidth: w, margin: '2px 0px', padding: 0 }}
        />
      ));
  const isListingMine = property => {
    let answer = false;
    if (
      property.listingAgents &&
      property.listingAgents.length > 0 &&
      property.listingAgentsPerson &&
      property.listingAgentsPerson.length > 0
    ) {
      property.listingAgentsPerson.map(agent => {
        if (!answer) {
          answer = currentPerson._id === agent._id || agentId === agent._id;
        }
        return null;
      });
    }
    return answer;
  };
  const handleResultClick = (data, type) => {
    setAnchorEl(null);
    let url = '/';
    if (type === 'client') {
      url = `/client-profile?id=${data._id}&pid=${data.person._id}`;
    }
    if (type === 'agent') {
      url = `/agent-profile?id=${data._id}`;
    }
    if (type === 'property') {
      url =
        isAgent && isListingMine(data)
          ? `/match-detail/?id=${data._id}`
          : `/view-property?id=${data._id}`;
    }
    if (type === 'development') {
      url = `/development-home?id=${data._id}`;
    }
    if (type === 'brokerage') {
      url = `/brokerages/list?category=name&name=${data.name}`;
    }
    push(url);
    onResultClick?.();
  };
  const renderBrokerageResult = useMemo(() => {
    if (!isAdmin && !isLoading.brokerages && brokerages?.length === 0)
      return null;
    return (
      <div className="globalSearch--section">
        <h3>BROKERAGES</h3>
        <List dense>
          {isLoading.brokerages
            ? skeletonHelper(3, 'dev', 320, 25)
            : brokerages.map(brokerage => (
                <ListItem
                  button
                  onClick={() => handleResultClick(brokerage, 'brokerage')}
                  key={`brokerage-${brokerage._id}`}
                  className={classes.searchListItem}
                >
                  <ListItemText
                    id={'add-id'}
                    primary={
                      typeof brokerage.listingsCount === 'number'
                        ? `${brokerage.name} (${brokerage.listingsCount})`
                        : brokerage.name
                    }
                  />
                </ListItem>
              ))}
          <ListItem>
            <Link
              to={`/brokerages/list?category=${search.category}&name=${search.search}`}
              className={classes.link}
            >
              View All Brokerages <ArrowForwardIcon color="inherit" />
            </Link>
          </ListItem>
        </List>
      </div>
    );
  }, [brokerages, isLoading.brokerages]);
  const renderClientResult = useMemo(() => {
    if (!isLoading.clients && clients?.length === 0) return null;
    return (
      <div style={{ maxWidth: 320 }}>
        <h3>CLIENTS</h3>
        <List dense>
          {isLoading.clients
            ? skeletonHelper(3, 'dev', 320, 25)
            : clients.map(client => (
                <ListItem
                  button
                  onClick={() => handleResultClick(client, 'client')}
                  key={`client-${client._id}`}
                  className={classes.searchListItem}
                >
                  <ListItemText id={'add-id'} primary={client.fullName} />
                </ListItem>
              ))}
          <ListItem>
            <Link
              to={`/my-clients/list?category=${search.category}&name=${search.search}&p=1`}
              className={classes.link}
            >
              View All Clients <ArrowForwardIcon color="inherit" />
            </Link>
          </ListItem>
        </List>
      </div>
    );
  }, [clients, isLoading.clients]);
  const renderAgentResult = useMemo(() => {
    if (!isLoading.agents && agents.length === 0) return null;
    return (
      <div className="globalSearch--section">
        <h3>MEMBERS</h3>
        <List dense>
          {isLoading.agents
            ? skeletonHelper(3, 'dev', 320, 75)
            : agents.map(agent => (
                <ListItem
                  button
                  onClick={() => handleResultClick(agent, 'agent')}
                  style={{ padding: '5px 10px' }}
                  key={`agent-${agent._id}`}
                  className={classes.searchListItem}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt={`photo of ${agent?.fullName || 'n/a'}`}
                      src={agent.person.realmData.photoUrl}
                    />
                  </ListItemAvatar>
                  {isLoading.agents ? (
                    <ListItemText id={'add-id'} primary={<div>Loading</div>} />
                  ) : (
                    <ListItemText
                      id={'add-id'}
                      primary={
                        <>
                          <div className="globalSearch--card_line">
                            {agent.fullName}
                          </div>
                          <div
                            style={{ fontSize: 12 }}
                            className="globalSearch--card_line"
                          >
                            {agent.currentBrokerage.name || ''}
                          </div>
                          <div className="globalSearch--card_line">
                            Email: {agent?.person?.primaryEmail || ''}
                          </div>
                        </>
                      }
                    />
                  )}
                </ListItem>
              ))}
          <ListItem>
            <Link
              to={`/agents?category=${search.category}&name=${search.search}`}
              className={classes.link}
            >
              View All Members <ArrowForwardIcon color="inherit" />
            </Link>
          </ListItem>
        </List>
      </div>
    );
  }, [agents, isLoading.agents]);
  const renderPropertyImage = imageArray => {
    if (imageArray.length > 0) {
      const hasImageUrl = imageArray.filter(el => el.pictureUrl);
      const primaryIndex = hasImageUrl.findIndex(el => el.isPrimary === true);
      if (
        primaryIndex &&
        hasImageUrl[primaryIndex] &&
        hasImageUrl[primaryIndex].pictureUrl
      )
        return hasImageUrl[primaryIndex].pictureUrl;
      if (hasImageUrl.length > 0 && hasImageUrl[0].pictureUrl)
        return hasImageUrl[0].pictureUrl;
    }
    return 'http://localhost:8005/static/media/estateblur2.54cc5ed9f1f993b37633.jpg';
  };

  const renderPropertyResult = useMemo(() => {
    if (!isLoading.properties && properties.length === 0) return null;
    return (
      <div className="globalSearch--section_wide">
        <h3>PROPERTIES</h3>
        <List dense>
          {isLoading.properties
            ? skeletonHelper(3, 'dev', 500, 50)
            : properties.map(listing => (
                <ListItem
                  button
                  onClick={() => handleResultClick(listing, 'property')}
                  className={classes.searchListItem}
                  key={`listing-${listing._id}`}
                >
                  <div className="globalSearch--card">
                    <img
                      src={renderPropertyImage(listing.pictures)}
                      alt={'property'} // To Do get this right
                      className="globalSearch--card_imageProperty"
                    />
                    <div className="globalSearch--card_block1">
                      <span className="globalSearch--card_line">
                        {listing.property?.streetAddress || ''}
                      </span>
                      <br />
                      <span className="globalSearch--card_line">
                        {listing.property?.city || ''},{' '}
                        {listing.property?.state || ''}
                      </span>
                      <br />
                      <span className="globalSearch--card_line">
                        {listing.property?.country || ''}
                      </span>
                    </div>
                    {!isPhone && (
                      <div className="globalSearch--card_block2">
                        <div className="globalSearch--card_line">
                          Status: {listing.status}
                        </div>
                        <div className="globalSearch--card_line">
                          Listed at: ${listing.price.amount}
                        </div>
                        <div className="globalSearch--card_line">
                          {listing?.listingBrokerage?.name}
                        </div>
                      </div>
                    )}
                  </div>
                </ListItem>
              ))}
          <ListItem>
            <Link
              to={`/properties/list?category=${search.category}&name=${search.search}&p=1`}
              className={classes.link}
            >
              View All Properties <ArrowForwardIcon color="inherit" />
            </Link>
          </ListItem>
        </List>
      </div>
    );
  }, [properties, isLoading.properties]);

  const renderDevelopmentResult = useMemo(() => {
    if (!isLoading.developments && developments.length === 0) return null;
    return (
      <div className="globalSearch--section_wide">
        <h3>DEVELOPMENTS</h3>
        <List dense>
          {isLoading.developments
            ? skeletonHelper(3, 'dev', 500, 90)
            : developments.map(development => (
                <ListItem
                  button
                  onClick={() => handleResultClick(development, 'development')}
                  className={classes.searchListItem}
                  key={`devlopment-${development._id}`}
                >
                  <div className="globalSearch--card">
                    <img
                      width="125"
                      height="90"
                      src={renderPropertyImage(development.pictures)}
                      alt={'development'}
                      className="globalSearch--card_imageDevelopment"
                    />
                    <div className="globalSearch--card_block1">
                      <div className="globalSearch--card_line">
                        Presented By:
                      </div>
                      <div className="globalSearch--card_line">
                        {development.listingBrokerage?.name || ''}
                      </div>
                      <div className="globalSearch--card_line">
                        Starting at ${development.price.amount}
                      </div>
                      <div className="globalSearch--card_line">
                        Status: {development.status}
                      </div>
                    </div>
                    {!isPhone && (
                      <div className="globalSearch--card_block2">
                        <span className="globalSearch--card_line">
                          {development.property.streetAddress}
                        </span>
                        <br />
                        <span className="globalSearch--card_line">
                          {development.property.city},{' '}
                          {development.property.state}
                        </span>
                        <br />
                        <span className="globalSearch--card_line">
                          {development.property.country}
                        </span>
                      </div>
                    )}
                  </div>
                </ListItem>
              ))}
          <ListItem>
            <Link to={`/developments`} className={classes.link}>
              View All Developments <ArrowForwardIcon color="inherit" />
            </Link>
          </ListItem>
        </List>
      </div>
    );
  }, [developments, isLoading.developments]);
  const widthHelper = () => {
    if (isPhone) return 300;
    if (
      isLoading.properties ||
      isLoading.developments ||
      (isSuccess.properties && properties.length > 0) ||
      (isSuccess.developments && developments.length > 0)
    ) {
      return 600;
    }
    return 300;
  };
  const noResults = !!(
    !isLoading.agents &&
    !isLoading.clients &&
    !isLoading.properties &&
    !isLoading.developments &&
    !isLoading.brokerages &&
    agents.length === 0 &&
    clients.length === 0 &&
    properties.length === 0 &&
    developments.length === 0 &&
    brokerages.length === 0
  );

  const hasPropertyResults = !!(
    isLoading.properties ||
    isLoading.developments ||
    (isSuccess.properties && properties.length > 0) ||
    (isSuccess.developments && developments.length > 0)
  );

  const hasPeopleResults = !!(
    isLoading.agents ||
    isLoading.clients ||
    (isSuccess.agents && agents.length > 0) ||
    (isSuccess.clients && clients.length > 0)
  );

  const renderResults = () => (
    <Popover
      id={'global-search-results'}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      className="globalSearch"
      marginThreshold={isPhone ? 0 : 60}
      style={isPhone ? { maxHeight: '75vh' } : {}}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: isPhone ? 'center' : 'right'
      }}
      transformOrigin={
        isPhone
          ? {
              vertical: 'top',
              horizontal: 'center'
            }
          : {
              vertical: 'bottom',
              horizontal: 'right'
            }
      }
    >
      <Paper
        style={{
          minWidth: widthHelper(),
          minHeight: 300,
          padding: isPhone ? '1rem' : '3rem',
          maxWidth: '100%',
          overflow: 'auto'
        }}
        elevation={3}
      >
        {isPhone ? (
          <div className="globalSearch--results center">
            <h2 style={{ textAlign: 'right' }}>RESULTS</h2>
            <IconButton onClick={() => setAnchorEl(null)}>
              <CloseIcon />
            </IconButton>
          </div>
        ) : (
          <h2 style={{ textAlign: 'right' }}>RESULTS</h2>
        )}
        <div className="globalSearch--results">
          {(isLoading.brokerages ||
            (isSuccess.brokerages && brokerages.length > 0)) && (
            <div
              className="globalSearch--results_column"
              style={isPhone ? {} : { marginRight: '1rem' }}
            >
              {renderBrokerageResult}
            </div>
          )}
          {hasPeopleResults && (
            <div className="globalSearch--results_column">
              {renderAgentResult}
              {renderClientResult}
            </div>
          )}
          {hasPropertyResults && (
            <div
              className="globalSearch--results_column"
              style={isPhone ? {} : { marginLeft: '1rem' }}
            >
              {renderDevelopmentResult}
              {renderPropertyResult}
              <div style={{ maxWidth: 500, fontSize: 14 }}>
                <div className="globalSearch--results_help">
                  <i
                    className="fas fa-lightbulb"
                    style={{
                      fontSize: 32,
                      marginRight: '1rem',
                      color: 'var(--black)'
                    }}
                  />
                  <p>
                    Didn’t find what you are looking for? Try selecting a
                    specific category in the drop-down above, or for Location
                    try searching by state abbreviation.
                  </p>
                </div>
              </div>
            </div>
          )}
          {noResults && (
            <div style={{ maxWidth: 550 }}>
              <h3>No results found.</h3>
              <div className="globalSearch--results_help">
                <i
                  className="fas fa-lightbulb"
                  style={{
                    fontSize: 32,
                    marginRight: '1rem',
                    color: 'var(--black)'
                  }}
                />
                <p>
                  Didn’t find what you are looking for? Try selecting a specific
                  category in the drop-down above, or for Location try searching
                  by state abbreviation.
                </p>
              </div>
            </div>
          )}
        </div>
      </Paper>
    </Popover>
  );
  return (
    <>
      <div
        role="form"
        id="global-search"
        aria-label="Search"
        className="globalSearch--component"
      >
        {searchInput()}
        {renderResults()}
      </div>
      <ErrorDialog
        handleClose={closeErrorDialog}
        message={isError}
        open={isError}
      />
    </>
  );
};
GlobalSearch.propTypes = {
  agentId: PropTypes.string.isRequired,
  isAgent: PropTypes.bool,
  currentPerson: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  hideCategories: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isPhone: PropTypes.bool,
  onResultClick: PropTypes.func
};
export default GlobalSearch;
