const styles = theme => ({
  appBar: {
    alignItems: 'center',
    backgroundColor: 'var(--background)',
    borderBottomColor: 'var(--border)',
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    display: 'grid',
    gridAutoFlow: 'column dense',
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    height: '100%',
    paddingInline: '0 20px',
    width: '100%',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(
      ['box-shadow', 'width', 'margin', 'background-color', 'height'],
      {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }
    ),

    '&:hover': {
      boxShadow: '0 8px 17px 0 rgba(0, 0, 0, 0.05)'
    },

    '&:has(input:focus), &.isMobileOpen': {
      backgroundColor: 'var(--white)',
      boxShadow: '0 8px 17px 0 rgba(0, 0, 0, 0.05)',
      height: 'calc(100% + 18px)'
    },

    '&:has(input:focus) .hideOnFocus, &.isMobileOpen .hideOnFocus': {
      opacity: 0,
      pointerEvents: 'none'
    },

    '&:has(input:focus) .showOnFocus, &.isMobileOpen .showOnFocus': {
      opacity: 1
    },

    '&:has(input:focus) .overlay.showOnFocus, &.isMobileOpen .overlay.showOnFocus': {
      insetBlock: '80px 0'
    }
  },
  appBarMobile: {
    gridTemplateColumns: 'minmax(0, 1fr) max-content max-content',
    position: 'relative'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    insetBlock: '64px 0',
    insetInline: 0,
    pointerEvents: 'none',
    position: 'fixed',
    zIndex: theme.zIndex.drawer,
    opacity: 0,
    transition: theme.transitions.create(['opacity', 'inset-block'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  globalSearch: {
    gridColumn: '2 / span 1',
    gridRow: '1 / span 1',
    justifySelf: 'stretch'
  },
  mobileSearch: {
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    inset: 0,
    paddingInline: 16,
    pointerEvents: 'none',
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 3
  },
  mobileSearchOpen: {},
  mobileSearchHeader: {
    alignItems: 'center',
    height: 64,
    opacity: 0,
    pointerEvents: 'none',
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr)',
    width: '100%'
  },
  mobileSearchHeaderOpen: {
    height: 80,
    pointerEvents: 'auto',
    opacity: 1,
    transition: theme.transitions.create(['opacity', 'height'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  mobileSearchOverlay: {
    flexGrow: 1,
    pointerEvents: 'none'
  },
  mobileSearchOverlayOpen: {
    pointerEvents: 'auto'
  },
  rightMenu: {
    alignItems: 'center',
    columnGap: 8,
    display: 'flex',
    justifySelf: 'flex-end',
    gridColumn: '3 / span 1',
    gridRow: '1 / span 1',
    transition: theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  rightMenuMobile: {
    zIndex: 1
  },
  rightCloseIcon: {
    opacity: 0,

    '& img': {
      width: 12,
      height: 12
    }
  },
  menuButton: {
    marginLeft: -6,
    '&:hover': {
      background: 'transparent!important',
      cursor: 'pointer'
    }
  },
  hide: {
    display: 'none'
  },
  menu: {
    marginTop: 32
  },
  menucount: {
    left: 45,
    top: 28,
    fontSize: 9,
    padding: '1px 3px',
    borderRadius: 2,
    backgroundColor: '#479acc'
  },
  closedmenucount: {
    position: 'absolute'
  },
  olbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    backgroundColor: '#000'
  },
  leftMenu: {
    alignItems: 'center',
    display: 'flex',
    columnGap: 20,
    gridColumn: '1 / span 1',
    gridRow: '1 / span 1',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    transition: theme.transitions.create(['opacity', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  leftMenuMobile: {
    columnGap: 0,
    zIndex: 2,
    width: 60
  },
  leftMenuMobileOpen: {
    width: 235
  },
  logo: {
    alignItems: 'center',
    columnGap: 26,
    display: 'grid',
    gridTemplateColumns: 'max-content minmax(0, max-content)',
    height: '100%',
    overflow: 'hidden',
    position: 'absolute',
    insetInlineStart: 82,
    insetInlineEnd: 26,
    insetBlock: 0,
    zIndex: 1
  },
  logoMobile: {
    insetInlineEnd: 'unset',
    insetInlineStart: 62,
    display: 'flex'
  },
  logoImage: {
    height: '20px',
    objectFit: 'contain'
  },
  logoImageMobile: {
    height: '18px'
  },
  padded: {
    paddingInlineStart: 20
  },
  impersonating: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '0.75em',
    justifyContent: 'center',
    rowGap: 4,

    '& > span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  },
  logoBox: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: -10
  },
  appName: {
    display: 'inline-block',
    fontSize: 24,
    fontWeight: 300,
    paddingTop: 15
  },
  toolbarBG: {
    backgroundColor: '#000'
  },
  mobileMenuItem: {
    color: '#fff',
    fontSize: 22,
    marginBottom: 8,
    '&:hover': {
      color: '#ccf'
    }
  },
  mobileMenuItemActive: {
    color: '#fff',
    backgroundColor: 'rgba(255,255,255,.25)',
    fontSize: 22,
    marginBottom: 8,
    borderLeft: '4px solid #479acc',
    '&:hover': {
      color: '#fff'
    }
  },
  messageIcon: {
    marginRight: 4
  },
  messagesTitle: {
    padding: 12,
    fontSize: 18,
    textTransform: 'uppercase',
    borderBottom: '1px solid gray',
    textAlign: 'center',
    fontFamily: '"Roboto", sans-serif'
  },
  messagesDiv: {
    borderBottom: '1px solid gray',
    padding: 18,
    fontSize: 16,
    color: '#479acc',
    '&:a': {
      color: '#479acc',
      textDecoration: 'underline'
    },
    '&:hover': {
      color: '#ccf',
      textDecoration: 'underline'
    }
  },
  messagesListing: {
    paddingLeft: 68,
    textIndent: -34
  },
  messagesLink: {
    color: '#479acc',
    '&:a': {
      color: '#479acc',
      textDecoration: 'underline'
    },
    '&:hover': {
      color: '#ccf',
      textDecoration: 'underline'
    }
  },

  shadow: {
    backgroundColor: 'var(--black)',
    opacity: 0,
    pointerEvents: 'none',
    position: 'fixed',
    insetBlock: 0,
    insetInline: '60px 0',
    transitionProperty: 'opacity, inset-inline',
    transitionDuration: '400ms',
    transitionTimingFunction: 'ease',
    zIndex: theme.zIndex.drawer + 2
  },
  shadowShow: {
    opacity: 0.1
  },
  shadowSlideIn: {
    insetInline: '235px 0'
  },
  shadowWithPointer: {
    pointerEvents: 'auto'
  },

  drawerHeader: {
    backgroundColor: 'var(--background)',
    borderRightColor: 'var(--border)',
    borderRightStyle: 'solid',
    borderRightWidth: 1,
    transitionProperty:
      'background-color, border-right-color, width, min-width',
    transitionDuration: '400ms',
    transitionTimingFunction: 'ease',
    width: 60,
    minWidth: 60,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'hidden',
    zIndex: 2
  },
  drawerHeaderMobile: {
    borderRightColor: 'transparent'
  },
  drawerHeaderSpacer: {
    width: 60,
    height: 62
  },
  open: {
    backgroundColor: 'var(--white)',
    width: 235,
    minWidth: 235
  },
  stuck: {
    backgroundColor: 'var(--background)',
    width: 235,
    minWidth: 235
  },
  toggle: {
    alignItems: 'center',
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
    outline: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
    height: 62,
    justifyContent: 'center',
    width: 60,

    '& > img': {
      height: 20,
      width: 20
    }
  },
  toggleLeft: {
    position: 'absolute',
    left: 0,
    top: 0
  }
});

export default styles;
