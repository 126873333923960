import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import api from '../../api';
import actions from '../../actions';
import { API_ROOT } from '../../constants';

import LoadingIndicator from '../../components/LoadingIndicator';
import SubscriptionRoutes from './SubscriptionRoutes';

import headerStyles from '../../components/Header/styles';
import logoImage from '../../images/logos/R_Wordmark.svg';

import classes from './Subscriptions.module.scss';
import { AccountMenu } from '../../components/Header/components';
import { withStyles } from '@material-ui/core';

class Subscriptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      team: null,
      anchorEl: null
    };
  }

  componentDidMount() {
    const { actions, session } = this.props;
    if (session.isAgent && !session.agentData) {
      !session.agentData && actions.getAgentData();
    }
    this.checkForTeam();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.session.agentData && this.props.session.agentData) {
      this.checkForTeam();
    }
  }

  checkForTeam = async () => {
    const { session } = this.props;
    if (!session.agentData) {
      return null;
    }
    try {
      const response = await api.get(
        `${API_ROOT}/teams/myTeam/${session.agentData._id}`
      );
      const { data: team } = response.data.data || null;
      this.setState({
        team
      });
    } catch (err) {
      console.error(err);
    }
    return null;
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { session } = this.props;

    const {
      currentPerson,
      agentData,
      isAdmin,
      isAgent,
      isConcierge,
      isOnboarding,
      avatar
    } = session;

    if (!isAgent && !agentData) {
      return (
        <div className={classes.main}>
          <div className={classes.full}>
            <div className="realm--loading-container">
              <LoadingIndicator />
            </div>
          </div>
        </div>
      );
    }

    const initials = `${currentPerson.name.firstName[0]}${currentPerson.name.lastName[0]}`.replace(
      /undefined/g,
      ''
    );

    return (
      <div className={classes.main}>
        <div className={classes.header}>
          <img
            className={classes.logoImage}
            src={logoImage}
            alt="REALM"
          />
          {currentPerson && (
            <AccountMenu
              agentData={agentData}
              anchorEl={this.state.anchorEl}
              classes={this.props.classes}
              currentPerson={currentPerson}
              eventTrackingWrapper={() => {}}
              groupOpen={false}
              handleClose={this.handleClose}
              handleMenu={this.handleMenu}
              handleReferralDialog={this.props.actions.handleReferralDialog}
              initials={initials}
              isAdmin={isAdmin}
              isConcierge={isConcierge}
              isOnboarding={isOnboarding}
              isSubscribing
              photoURL={avatar || null}
              showAmitySocial={false}
              showChat={false}
              unreadChats={0}
            />
          )}
        </div>
        <div className={classes.content}>
          <SubscriptionRoutes {...this.props} team={this.state.team} />
        </div>
      </div>
    );
  }
}

Subscriptions.propTypes = {
  actions: PropTypes.object,
  application: PropTypes.object,
  session: PropTypes.object
};

export default withStyles(headerStyles)(
  connect(
    state => ({
      application: state.application,
      session: state.session
    }),
    dispatch => ({ actions: bindActionCreators(actions, dispatch) })
  )(Subscriptions)
);
