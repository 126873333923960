import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash.isfunction';
import merge from 'lodash.merge';
import { Checkbox, CircularProgress } from '@material-ui/core';

import ContentDialog from '../../../components/Dialogs/ContentDialog';
import RealmButton from '../components/RealmButton';

import localization from '../../../services/Localization';
import LoadingIndicator from '../../../components/LoadingIndicator/LoadingIndicator';

const Agreements = ({
  currentPerson,
  history,
  isRenewing,
  isAgent,
  subscription,
  actions,
  isMobile,
  language,
  session
}) => {
  const [contentSlug, setContentSlug] = useState(null);
  const [termsAccepted, setTermsAccepted] = useState(false);
  // const [termsDeclined, setTermsDeclined] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    let doneTerms = true;
    if (session.hasPastDuePayment) {
      history.push('/collect-payment')
      return null
    }
    if (currentPerson?.realmData) {
      if (currentPerson?.realmData?.userAccount?.acceptedTermsAt) {
        history.push('/subscription-options');
      }
      if (
        !currentPerson.realmData.userAccount ||
        !currentPerson.realmData.userAccount.acceptedTermsAt
      ) {
        doneTerms = false;
      }
      if (doneTerms) {
        if (!isRenewing) {
          //Agent without a sub needs to hit the paywall
          if (isAgent && Object.keys(subscription).length === 0) {
            console.log('NO SUB!!!');
            history.push('/subscription-options');
          }
          if (
            currentPerson?.realmData?.userAccount?.onBoardedAgentAt &&
            Object.keys(subscription).length !== 0
          ) {
            history.push('/subscription-complete');
          }
        }
      }
    }
    const el = document.getElementById('page-wrapper');
    if (el && isFunction(el.scrollTo)) {
      el.scrollTo(0, 0);
    }
  }, []);
  const handleChange = e => {
    setTermsAccepted(e.target.checked);
  };
  const showContentDialog = e => {
    e.preventDefault();
    setContentSlug(e.target.name);
  };
  const hideContentDialog = () => {
    setContentSlug(null);
  };
  const goToAdminSite = async () => {
    setIsLoading(true)
    actions.completeOnboarding();
    setTimeout(history.push('/'), 750);
  };
  const updatePerson = useCallback(async () => {
    const updatedDate = new Date().toISOString();
    const updatedPerson =
      subscription && subscription.status === 'Active'
        ? merge(currentPerson.realmData, {
            userAccount: {
              acceptedTermsAt: updatedDate,
              onBoardedAgentAt: updatedDate
            }
          })
        : merge(currentPerson.realmData, {
            userAccount: {
              acceptedTermsAt: updatedDate
            }
          });
    await actions.updatePerson({
      realmData: updatedPerson
    });
    if (subscription) {
      switch (subscription.status) {
        case 'Active':
          goToAdminSite();
          break;
        default:
          history.push('/subscription-options');
      }
    }
  }, [currentPerson, history, subscription, actions, goToAdminSite]);

  const { onboarding: lang } = localization;
  // console.log(lang.agreements_title)
  return (
    <div className="realm--subscription">
      {!isMobile && <div style={{ paddingTop: 80, width: '100%' }} />}
      {/* CAN THIS BE A NORMAL H1? */}
      <h1
        className="realm-onboarding--title centered"
        dangerouslySetInnerHTML={{
          __html: `${lang.agreements_title}, ${currentPerson.name.firstName}`
        }}
      ></h1>
      <div className="realm-onboarding--paragraph-group">
        <p className="realm-onboarding--welcome">
          Welcome to the next generation of global real estate &#8212; led by
          the current generation of real estate leaders. REALM is the only
          globally connected luxury real estate community. Our collaborative
          matching platform is the new way for real estate professionals to
          create relationships and match qualified buyers through lifestyle
          experiences and passions, rather than search criteria. REALM is also
          luxury events, client research, curated luxury content and personal
          conversations all designed to help you connect and inform your
          clients, and yourself.
        </p>
        <p className="realm-onboarding--welcome">
          Get ready to have some fun. Let&#8217;s get there together.
        </p>
      </div>
      <div className="realm-onboarding--form centered">
        <div className="realm-onboarding--input-group checkbox wide">
          <Checkbox
            checked={termsAccepted}
            color="primary"
            name="accept"
            onChange={handleChange}
            value="checkedB"
          />
          I agree to the{' '}
          <a
            name="marketing-company-terms-and-conditions"
            onClick={showContentDialog}
            role="dialog"
          >
            {lang.agreements_terms}
          </a>
          ,{' '}
          <a
            name="marketing-company-privacy-policy"
            onClick={showContentDialog}
            role="dialog"
          >
            {lang.agreements_privacy}
          </a>{' '}
          and{' '}
          <a
            name="marketing-company-gdpr"
            onClick={showContentDialog}
            role="dialog"
          >
            {lang.agreements_cookies}
          </a>
          .
        </div>
      </div>
      <div className="realm-onboarding--actions">
        <RealmButton
          disabled={!termsAccepted}
          onClick={updatePerson}
        >
          {isLoading ? <CircularProgress size={14} /> : lang.continue}
        </RealmButton>
      </div>
      <ContentDialog
        apiType="fullPost"
        language={language}
        onClose={hideContentDialog}
        open={!!contentSlug}
        slug={contentSlug}
      />
    </div>
  );
};
Agreements.propTypes = {
  actions: PropTypes.object,
  currentPerson: PropTypes.object,
  history: PropTypes.object,
  isMobile: PropTypes.bool,
  language: PropTypes.string,
  subscription: PropTypes.object
};
export default Agreements;
