import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from '../Home';
import Login from './Login';
import Welcome from './Welcome';

const LoginRoutes = storeProps => (
  <Switch>
    <Route
      exact
      path="/"
      render={props => <Home {...props} {...storeProps} />}
    />
    <Route
      exact
      path="/login"
      render={props => <Login {...props} {...storeProps} />}
    />
    <Route
      path="/welcome"
      render={props => <Welcome {...props} {...storeProps} />}
    />
    <Route path="*" render={props => <Login {...props} {...storeProps} />} />
  </Switch>
);

export default LoginRoutes;
