import queryString from 'query-string';

export function getPageParameters(qs) {
  if (typeof qs !== 'string') return {};
  return queryString.parse(qs, {
    parseNumbers: false,
    parseBooleans: true
  });
}
export function setPageParameters(obj) {
  if (typeof obj !== 'object') return '';
  const keys = Object.keys(obj);
  keys.forEach(key => {
    if (obj[key] === null || obj[key] === '') {
      delete obj[key];
    }
  });

  return queryString.stringify(obj);
}
