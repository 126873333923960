import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import ContactUs from '../Dialogs/ContactUs';
import localstring from '../../services/Localization';
import { VERSION } from '../../constants';

const Footer = () => (
  <footer className="realm--main-footer">
    <div className="realm--main-footer-wrapper">
      <div className="realm--main-footer-links">
        <a
          href="https://www.realm-global.com/get-started/"
          rel="noopener noreferrer"
          target="_blank"
        >
          Getting Started
        </a>
        <Link to="/company?page=terms">{localstring.terms}</Link>
        <Link to="/company?page=privacy">{localstring.privacy_policy}</Link>
        <Link to="/company?page=cookies-policy">
          {localstring.cookies}
        </Link>{' '}
        <ContactUs showText />
        <a href="https://www.realm-global.com/press/" rel="noopener noreferrer">{localstring.press}</a>
      </div>
      <div className="realm--copyright">
        &#169; {localstring.footer_copy1} {new Date().getFullYear()},{' '}
        {localstring.footer_copy2}
      </div>
      <div className="realm--user-version">Version {VERSION}</div>
    </div>
  </footer>
);

export default memo(Footer);
