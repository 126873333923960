/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
export default function setLoginMessage(message, changePassword, openContact) {
    // if the user entered the wrong password
    let title = ''
    if ( message === "Incorrect username or password." ) {
        // change the error message to this
        message = <p>The password you entered does not exist. Please try again, or <u><a style={{cursor:'pointer'}} onClick={changePassword}>click here</a></u> to reset your password.</p>
        title = "Incorrect Password";
    }
    // else if the user entered the wrong username
    else if ( message === "User does not exist.") {
        // change the error message to this
        
        message = <p>The username you entered does not exist. Please try again, or contact <u><a style={{cursor:'pointer'}} onClick={openContact}>REALM Concierge</a></u>.</p>
        title = "Incorrect Username";
    } 
    return {message, title };
}