import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../../images/icons/R_Icon_Community_Messages.svg';

const useStyles = makeStyles(theme => ({
  link: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: 38,
    height: 38,
    isolation: 'isolate',
    position: 'relative'
  },
  badge: {
    color: 'white',
    alignItems: 'center',
    display: 'flex',
    backgroundColor: 'red',
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
    top: 0,
    width: 16,
    height: 16,
    borderRadius: 16,
    zIndex: 2
  },
  badgeLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 10,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.2,
    height: 10
  }
}));

export default function AccountBadge({
  eventTrackingWrapper,
  unreadChats,
  showAmitySocial
}) {
  const classes = useStyles();

  if (!showAmitySocial) {
    return null;
  }

  return (
    <Link
      onClick={() => eventTrackingWrapper(undefined, 'My Profile')}
      to="/social"
      className={classes.link}
    >
      <img src={Icon} />
      {unreadChats > 0 && (
        <div className={classes.badge}>
          <span className={classes.badgeLabel}>{unreadChats}</span>
        </div>
      )}
    </Link>
  );
}
