/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const AlertDialog = memo(props => {
  const {
    actionsMap,
    children,
    describedby,
    labelledby,
    message,
    onClose,
    showCloseButton,
    title,
    ...rest
  } = props;
  return (
    <Dialog
      aria-labelledby={labelledby || 'alert-modal-title'}
      aria-describedby={describedby || 'alert-modal-content'}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
            onClose(event, reason)
        }
      }}
      style={{
        'backdrop-filter': 'blur(10px)'
      }}
      disableEscapeKeyDown
      {...rest}
    >
      {showCloseButton && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: 'absolute',
            top: 10,
            right: 10
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      <DialogTitle
        style={{
          width: showCloseButton ? 'calc(100% - 50px)' : '100%'
        }}
        id={labelledby || 'alert-modal-title'}
      >
        {title || 'Alert'}
      </DialogTitle>
      <DialogContent
        id={describedby || 'alert-modal-content'}
        className="realm--modal-content"
      >
        {children ||
          (message ? (
            <span
              dangerouslySetInnerHTML={{
                __html: message
              }}
            />
          ) : (
            <div>This is an alert message.</div>
          ))}
      </DialogContent>
      {Array.isArray(actionsMap) && actionsMap.length > 0 && (
        <DialogActions>
          {actionsMap.map(btn => (
            <Button
              key={`${title}-${Math.random()}`}
              onClick={btn.onClick || Function.prototype}
              color={btn.color || 'default'}
              variant={btn.variant || 'contained'}
            >
              {btn.text || 'OK'}
            </Button>
          ))}
        </DialogActions>
      )}
    </Dialog>
  );
});

AlertDialog.propTypes = {
  actionsMap: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.oneOf(['default', 'primary']),
      onClick: PropTypes.func,
      text: PropTypes.string,
      variant: PropTypes.oneOf(['contained', 'outlined'])
    })
  ),
  children: PropTypes.any,
  describedby: PropTypes.string,
  labelledby: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  showCloseButton: PropTypes.bool,
  title: PropTypes.string
};

export default AlertDialog;
