/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  InputLabel,
  DialogContent,
  FormControl,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import isFunction from 'lodash.isfunction';
import api from '../../api';
import LoadingIndicator from '../LoadingIndicator';
import localstring from '../../services/Localization';
import { API_ROOT } from '../../constants';

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      email: '',
      subject: props.upgrade
        ? 'Upgrade My Membership'
        : props.subject || 'Feedback/Suggestions',
      message: '',
      isError: false,
      isSending: false,
      isSuccess: false,
      isValid: false,
      isVisible: false,
      showStatusModal: false
    };
    this._delay = null;
    this._isMounted = false;
  }
  
  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    if (this._delay) {
      clearTimeout(this._delay);
      this._delay = null;
    }
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (this.props.open && !prevProps.open) {
      this.open();
    }

    if (this.props.contactUsEmail && prevProps.contactUsEmail != this.props.contactUsEmail) {
      this.setState({
        email: this.props.contactUsEmail
      })
    }
    
    if (this.props.subjectEmail && prevProps.subjectEmail != this.props.subjectEmail) {
      this.setState({
        subject: this.props.subjectEmail
      })
    }
  }

  submit = async () => {
    this.setState({
      showStatusModal: true,
      isSending: true
    });
    const endpoint = this.props.addUserInformation
      ? `${API_ROOT}/user/login/contact`
      : `${API_ROOT}/user/contact`;
    const payload = this.props.addUserInformation
      ? {
          email: this.state.email,
          name: this.state.username,
          siteForm: 'Sign In Contact Us',
          subject: 'Help with My Account',
          message: this.state.message
        }
      : {
          email: this.state.email,
          siteForm: 'AdminSite',
          subject: this.state.subject,
          message: this.state.message
        };
    try {
      // console.log('payload', payload);
      const response = await api.post(endpoint, payload);
      if (response.status === 200 && this._isMounted) {
        if (isFunction(this.props.handleClose)) {
          this.props.handleClose();
        }
        this.setState({
          isVisible: false,
          isSending: false,
          isSuccess: true
        });
      }
    } catch (ex) {
      // const msg = ex.response ? ex.response.data.msg : ex.msg;
      this._isMounted &&
        this.setState({
          isVisible: false,
          isSending: false,
          isError: true
        });
    }
  };

  selectSendButton = () => {
    this.setState({ isVisible: true });
  };

  selectCancelButton = () => {
    this.setState({ isVisible: false });
    if (isFunction(this.props.handleClose)) {
      this.props.handleClose();
    }
  };

  open = e => {
    if (e) {
      e.preventDefault();
    }
    this.setState({ isVisible: true });
  };

  close = () => {
    this.setState(
      {
        subject: 'Feedback/Suggestions',
        message: '',
        showStatusModal: false,
        isVisible: false
      },
      () => {
        this._delay = setTimeout(() => {
          if (isFunction(this.props.handleClose)) {
            this.props.handleClose();
          }
          this.setState({
            isError: false,
            isSuccess: false
          });
        }, 1000);
      }
    );
  };

  renderStatusDialogContent = () => {
    const { isError, isSuccess } = this.state;
    return (
      <>
        {isError && <DialogTitle>Error sending your email.</DialogTitle>}
        {isSuccess && <DialogTitle>Success! Your email was sent.</DialogTitle>}
        <DialogContent className="realm--modal-content">
          {isSuccess && (
            <div>
              Thank you for your message. Please expect a response from a REALM
              representative within 1-2 business days.
            </div>
          )}
          {isError && (
            <div>
              Your email could not be sent at this time. Please either try again
              or contact a REALM representative directly at{' '}
              <a
                style={{ textDecoration: 'underline' }}
                href="mailto:concierge@realm-global.com"
              >
                concierge@realm-global.com
              </a>{' '}
              for further assistance. We apologize for the inconvenience.
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.close} color="primary" variant="contained">
            {localstring.onboarding.ok}
          </Button>
        </DialogActions>
      </>
    );
  };

  forceLower = e => {
    e.target.value = e.target.value.toLowerCase();
  };

  validateEmail = () => {
    const re = /\S+@\S+\.\S+/;
    return re.test(this.state.email);
  };

  validateForm = () => {
    if (this.props.addUserInformation) {
      return this.state.message.length !== 0 && this.validateEmail();
    }
    return this.state.message.length !== 0;
  };

  onInput = e => {
    const { name } = e.target;
    this.setState(
      {
        [name]: e.target.value
      },
      () => {
        this.setState({ isValid: this.validateForm() });
      }
    );
    return null;
  };

  render() {
    return (
      <>
        <div
          style={{
            display: this.props.linkText
              ? 'block'
              : this.props.inline
              ? 'inline'
              : 'inline-block'
          }}
        >
          {this.props.showButton && (
            <Button color="primary" onClick={this.open}>
              {localstring.contact_us}
            </Button>
          )}
          {this.props.linkText && (
            <div style={{ marginTop: 10, textTransform: 'uppercase' }}>
              <Button color="primary" onClick={this.open}>
                {this.props.linkText}
              </Button>
            </div>
          )}
          {this.props.showText && (
            <a href="/" onClick={this.open}>
              {this.props.inline
                ? localstring.contact_us.toLowerCase()
                : localstring.contact_us}
            </a>
          )}
        </div>
        <Dialog
          aria-describedby="contact-us-dialog"
          aria-labelledby="contact-us-dialog-title"
          onClose={this.handleClose}
          open={this.state.isVisible}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="contact-us-dialog-title">
            {this.props.upgrade
              ? 'Contact us to unlock REALM full access'
              : localstring.contact_us_title}
          </DialogTitle>
          <DialogContent className="realm--modal-content">
            {this.props.addUserInformation && (
              <>
                <div>
                  <TextField
                    id="username"
                    type="username"
                    fullWidth
                    label="Your name"
                    margin="dense"
                    name="username"
                    onChange={this.onInput}
                    value={this.state.username}
                  />
                </div>
                <div style={{ marginBottom: 20 }}>
                  <TextField
                    id="email"
                    type="email"
                    fullWidth
                    label="Your email address"
                    helperText={
                      this.state.isValid
                        ? ''
                        : 'Please enter a valid email address'
                    }
                    margin="dense"
                    name="email"
                    onChange={this.onInput}
                    onKeyUp={this.forceLower}
                    value={this.state.email}
                  />
                </div>
              </>
            )}
            {!this.props.addUserInformation && (
              <FormControl className="input250">
                <InputLabel className="muiInputLabel" htmlFor="purpose">
                  {localstring.contact_re}
                </InputLabel>

                {this.props.upgrade ? (
                  <Select
                    value={this.state.subject}
                    name="subject"
                    onChange={this.onInput}
                    style={{ width: '90%' }}
                  >
                    <MenuItem selected value="Upgrade My Membership">
                      Upgrade My Membership
                    </MenuItem>
                  </Select>
                ) : (
                  <Select
                    value={this.state.subject}
                    name="subject"
                    onChange={this.onInput}
                    style={{ width: '90%' }}
                  >
                    <MenuItem value="Feedback/Suggestions">
                      {localstring.contact_re_feedback}
                    </MenuItem>
                    <MenuItem value="Help with My Account">
                      {localstring.contact_re_account}
                    </MenuItem>
                    <MenuItem value="Sandow Media Inquiry">
                      {localstring.contact_re_sandow}
                    </MenuItem>
                    <MenuItem value="Rivian">
                      {localstring.contact_re_rivian}
                    </MenuItem>
                    <MenuItem value="Private Jet Services">
                      {localstring.contact_re_pjs}
                    </MenuItem> 
                    <MenuItem value="Team Changes">
                      {localstring.contact_re_team}
                    </MenuItem>
                    <MenuItem value="Technical Support">
                      {localstring.contact_re_tech}
                    </MenuItem>
                    <MenuItem value="Other">
                      {localstring.contact_re_other}
                    </MenuItem>
                    
                  </Select>
                )}
              </FormControl>
            )}
            <div style={{ marginTop: 18 }}>
              <p>{localstring.contact_message}</p>
            </div>
            <div>
              <textarea
                name="message"
                rows="12"
                style={{ width: '100%', padding: 12 }}
                onChange={this.onInput}
                defaultValue={this.state.message}
                placeholder={
                  this.props.upgrade
                    ? 'Please leave some information about your request and a concierge will get back to you to upgrade your membership.'
                    : 'Please type your message here...'
                }
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.selectCancelButton}
              color="default"
              variant="contained"
            >
              {localstring.cancel}
            </Button>
            <Button
              onClick={this.submit}
              color="primary"
              variant="contained"
              disabled={!this.state.isValid}
            >
              {localstring.contact_send}
            </Button>
          </DialogActions>
        </Dialog>

        {/* email status dialog */}
        <Dialog
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
                this.props.handleClose(event, reason)
            }
          }}
          disableEscapeKeyDown
          maxWidth="md"
          open={this.state.showStatusModal}
        >
          {this.state.isSending && (
            <DialogContent
              className="realm--modal-content"
              style={{ position: 'relative' }}
            >
              <div style={{ width: '100%', textAlign: 'center' }}>
                <p style={{ color: '#fff' }}>Sending email ...</p>
                <div style={{ width: 32, margin: '0 auto' }}>
                  <LoadingIndicator style={{ marginLeft: -16 }} />
                </div>
              </div>
            </DialogContent>
          )}
          {!this.state.isSending && this.renderStatusDialogContent()}
        </Dialog>
      </>
    );
  }
}

ContactUs.propTypes = {
  addUserInformation: PropTypes.bool,
  handleClose: PropTypes.func,
  inline: PropTypes.bool,
  open: PropTypes.bool,
  showButton: PropTypes.bool,
  showText: PropTypes.bool,
  subject: PropTypes.string,
  upgrade: PropTypes.bool,
  contactUsEmail: PropTypes.string,
  subjectEmail: PropTypes.string,
};

ContactUs.defaultProps = {
  inline: false,
  showButton: false,
  showText: false,
  upgrade: false,
  contactUsEmail: '',
  subjectEmail: '',
};

export default ContactUs;
