import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  withStyles
} from '@material-ui/core';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import localstring from '../../../services/Localization';

const StyledAvatar = withStyles({
  root: {
    borderColor: 'var(--border-avatar)',
    borderStyle: 'solid',
    borderWidth: 1,
    fontSize: 14,
    height: 36,
    margin: 0,
    width: 36,
  }
})(Avatar);

const AccountMenu = ({
  handleReferralDialog,
  currentPerson,
  agentData,
  photoURL,
  initials,
  eventTrackingWrapper,
  handleMenu,
  anchorEl,
  classes,
  groupOpen,
  handleClose,
  isAdmin,
  isOnboarding,
  isSubscribing,
  isConcierge
}) => (
  <>
    <IconButton
      aria-haspopup="true"
      color="inherit"
      onClick={e =>
        eventTrackingWrapper(handleMenu(e, 'account'), 'Profile Picture Menu')
      }
      style={{ padding: 0 }}
    >
      <StyledAvatar
        alt={`${currentPerson.name.fullName} Account, Sign Out`}
        src={`${photoURL}`}
        className="headerAvatar"
      >
        {photoURL ? null : `${initials}`}
      </StyledAvatar>
    </IconButton>

    <Menu
      id="menu-account"
      keepMounted
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      className={classNames(classes.menu)}
      open={Boolean(anchorEl) && !groupOpen}
      onClose={handleClose}
      getContentAnchorEl={null}
    >
      {agentData && !isSubscribing && !isOnboarding && (
        <MenuItem
          onClick={() => eventTrackingWrapper(handleClose, 'My Profile')}
        >
          <Link to={`/agent-profile?id=${agentData._id}`}>
            <i className="fa fa-user fa-fw" />
            {localstring.my_profile}
          </Link>
        </MenuItem>
      )}
      {!isSubscribing && !isOnboarding && !isAdmin && !isConcierge && (
        <MenuItem
          onClick={() => eventTrackingWrapper(handleClose, 'My Account')}
        >
          <Link to={'/my-account'}>
            <i className="fa fa-cog fa-fw" />
            {localstring.menu1_user_account}
          </Link>
        </MenuItem>
      )}
      {!isSubscribing && !isOnboarding && !isAdmin && !isConcierge && (
        <MenuItem
          onClick={() =>
            handleReferralDialog({
              agentId: agentData._id,
              agentName: agentData.fullName
            })
          }
          style={{ gap: 4 }}
        >
          <i className="fa fa-handshake" />
          Refer a Member
        </MenuItem>
      )}
      {!isSubscribing && (isAdmin || isConcierge) && (
        <MenuItem
          onClick={() => eventTrackingWrapper(handleClose, 'Edit Account')}
        >
          <Link to={`/edit-account/?id=${currentPerson._id}`}>
            <i className="fa fa-cog fa-fw" />
            {localstring.menu1_user_account}
          </Link>
        </MenuItem>
      )}

      <MenuItem onClick={() => eventTrackingWrapper(handleClose, 'Logout')}>
        <Link to="/logout">
          <i className="fa fa-sign-out-alt fa-fw" />
          {localstring.menu1_user_logout}
        </Link>
      </MenuItem>
    </Menu>
  </>
);
AccountMenu.propTypes = {
  currentPerson: PropTypes.object,
  agentData: PropTypes.object,
  photoURL: PropTypes.string,
  initials: PropTypes.string,
  eventTrackingWrapper: PropTypes.func,
  handleMenu: PropTypes.func,
  anchorEl: PropTypes.node,
  classes: PropTypes.object,
  groupOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  isAdmin: PropTypes.bool,
  isOnboarding: PropTypes.bool,
  isConcierge: PropTypes.bool,
  isSubscribing: PropTypes.bool

};
AccountMenu.defaultProps = {
  anchorEl: null,
  isSubscribing: false,
};
export default AccountMenu;
