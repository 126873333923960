import { schema } from 'normalizr';

const selectedLifestylesSchema = new schema.Entity(
  'lifestyles',
  {},
  { idAttribute: 'lifestyle' }
);
const selectedLifestylesSchemaList = new schema.Array(selectedLifestylesSchema);

const selectedTagsSchema = new schema.Entity(
  'tags',
  {},
  { idAttribute: 'tag' }
);
const selectedTagsSchemaList = new schema.Array(selectedTagsSchema);

const tagsSchema = new schema.Entity('allTags', {}, { idAttribute: 'tagId' });
const tagsSchemaList = new schema.Array(tagsSchema);

// Schemas
const Schemas = {
  SELECTED_LIFESTYLES: selectedLifestylesSchema,
  SELECTED_LIFESTYLES_LIST: selectedLifestylesSchemaList,
  SELECTED_TAGS: selectedTagsSchema,
  SELECTED_TAGS_LIST: selectedTagsSchemaList,
  TAGS: tagsSchema,
  TAGS_LIST: tagsSchemaList
};

export default Schemas;
