import React from 'react';
import classNames from 'classnames';

import classes from './DynamicMenuIcon.module.scss';

export default function DynamicMenuIcon({ isOpen = false }) {
  return (
    <div className={classes.container}>
      <div
        className={classNames(
          classes.line,
          classes.top,
          isOpen && classes.active
        )}
      />
      <div
        className={classNames(
          classes.line,
          classes.bottom,
          isOpen && classes.active
        )}
      />
    </div>
  );
}
