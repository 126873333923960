import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import localstring from '../../services/Localization';
import LoginDialog from '../../components/Login/LoginDialog';
import { IS_MAINTENANCE_MODE, VERSION } from '../../constants';
import getParameterByName from '../../utils/get-parameter-by-name';

class Login extends Component {
  constructor(props) {
    super();
    const id = getParameterByName('utm_campaign') || '';
    const isDemo = getParameterByName('demo') || false;
    if(isDemo === 'true') {
      localStorage.setItem('isDemo', true);
    }
    const isWelcomePage = id === 'user-Welcome';
    const isLogoutPage =
      props.location && props.location.pathname === '/logout';
    this.state = {
      id,
      showPage: !isWelcomePage,
      showMaintenance: props.session.maintenanceMode,
    };
    this._isMounted = false;
    if (isWelcomePage || isLogoutPage) {
      props.push('/login');
    }
  }

  componentDidMount() {
    this._isMounted = true;
    if (!this.props.session.hasOwnProperty('maintenanceMode')) {
      this.props.actions.getSystemSettings()
    }
    if (this.state.id === 'user-Welcome') {
      this.goToWelcomePage();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.session.hasOwnProperty('maintenanceMode')) {
      if (!prevProps.session.hasOwnProperty('maintenanceMode')) {
        this.setState({ showMaintenance: this.props.session.maintenanceMode });
      } else if (this.props.session.maintenanceMode !== prevProps.session.maintenanceMode) {
        this.setState({ showMaintenance: this.props.session.maintenanceMode });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  goToWelcomePage = () => {
    this.props.push('/welcome/?utm_campaign=user-Welcome');
  };

  closeLoginPage = () => {
    this.props.push('/');
  };

  render() {
    const { showMaintenance } = this.state;
    const systemMessage = this.props.session.systemMessage && this.props.session.systemMessage !== '' ? this.props.session.systemMessage : false;
    const paddingTop = systemMessage ? 48 : 0;
    return (
      <>
        <div
          className={`realm--login-screen${
            this.state.showPage ? ' fadeIn' : ''
          }`}
          style={{paddingTop}}
        >
          { systemMessage && (
            <div className='realm--system-message'>
              {systemMessage}
            </div>
          )}
          <header role="banner" className="realm--login-screen-header">
            <span className="realm--login-screen-header-logo">
              <a href="https://www.realm-global.com/">
                <img
                  src="https://wp-realm-marketing.s3.amazonaws.com/images/REALM_LogoWhite_R.png"
                  alt="REALM"
                />
              </a>
            </span>
            <nav className="realm--login-screen-header-signin">
              <a href="/login">
                {showMaintenance
                  ? localstring.login_under_maintenance
                  : localstring.login}
              </a>
            </nav>
          </header>
          <div className="realm--login-screen-content-wrapper">
            <div className="realm--login-screen-backdrop">
              <div className="realm--login-screen-content-container">
                <div className="realm--login-screen-pic-overlay" />
                <div className="realm--login-screen-content">
                  <main
                    className="realm--login-screen-main"
                    role="main"
                    itemProp="mainContentOfPage"
                  >
                    <div className="realm--login-screen-container">
                      <div className="realm--login-screen-wrapper">
                        {IS_MAINTENANCE_MODE ? (
                          <h1
                            className="realm--login-screen-headline"
                            itemProp="headline"
                          >
                            <span className="realm--login-screen-realm">
                              REALM
                            </span>{' '}
                            is conducting scheduled maintenance. We expect to be
                            back shortly. Thank you for your patience.
                          </h1>
                        ) : (
                          <h1
                            className="realm--login-screen-headline"
                            itemProp="headline"
                          >
                            Welcome to{' '}
                            <span className="realm--login-screen-realm">
                              REALM
                            </span>
                            , <br /> we're delighted you're here.
                          </h1>
                        )}
                        <div className="realm--login-screen-divider-container">
                          <div className="realm--login-screen-divider" />
                        </div>
                        <section
                          itemScope="itemscope"
                          itemType="https://schema.org/CreativeWork"
                        >
                          <div
                            className="realm--login-screen-copy"
                            itemProp="text"
                          >
                            <h3>
                              Conceived by world-class real estate professionals
                              from around the world and informed by visionary,
                              thought-leaders from various industries, REALM is
                              a subscription comprised of the world’s most
                              trusted and respected independent real estate
                              professionals. The REALM experience leverages the
                              insight of the agents globally to match qualified
                              buyers to properties. The matching technology is
                              based on lifestyle and passion rather than
                              property criteria via a curated content platform
                              with integration of Machine Learning and
                              Artificial Intelligence. In an encrypted, secure
                              environment, REALM collects and repositions client
                              data to improve the holistic real estate
                              experience.
                            </h3>
                          </div>
                        </section>
                        <div className="realm--login-screen-actions">
                          <a
                            className="realm--login-screen-learn-more"
                            href="https://www.realm-global.com/"
                          >
                            Learn More
                          </a>
                        </div>
                        <div className="realm--login-screen-footer">
                        &#169; {localstring.footer_copy1}{' '}
                        {new Date().getFullYear()}, {localstring.footer_copy2}
                        <span style={{ margin: '0 10px' }} />
                        <div className="realm--login-version">Version {VERSION}</div>
                      </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LoginDialog
          actions={this.props.actions}
          handleClose={this.closeLoginPage}
          open
          session={this.props.session}
        />
      </>
    );
  }
}

Login.propTypes = {
  actions: PropTypes.object.isRequired,
  push: PropTypes.func,
  session: PropTypes.object.isRequired
};

export default connect(null, { push })(Login);
