import React from 'react';
import classNames from 'classnames';

import classes from './DrawerItem.module.scss';

import ChevronIcon from '../../images/icons/R_Icon_Chevron_Down.svg';

export default function DrawerItem({
  icon,
  isOpen,
  showLock,
  selected,
  children,
  ...props
}) {
  const hasIcon =
    typeof isOpen !== 'undefined' || typeof showLock !== 'undefined';

  return (
    <button {...props} className={classNames(classes.button, isOpen && classes.open, props.className)}>
      {icon ? (
        <img src={icon} alt="" className={classes.icon} />
      ) : (
        <div className={classes.icon} />
      )}
      <span
        className={classNames(
          classes.label,
          !hasIcon && classes.expanded,
          selected && classes.selected
        )}
      >
        {children}
      </span>
      {isOpen !== undefined && !showLock && (
        <img
          src={ChevronIcon}
          className={classNames(classes.chevron, isOpen && classes.open)}
        />
      )}
      {isOpen === undefined && showLock && (
        <i className={classNames(classes.chevron, 'fas fa-lock')} />
      )}
    </button>
  );
}
