import * as types from './types';

export function changeActiveStatus(isActive) {
  if (typeof isActive !== 'boolean') {
    return null;
  }
  return isActive
    ? { type: types.WINDOW_ACTIVE, payload: isActive }
    : { type: types.WINDOW_INACTIVE, payload: isActive };
}

export function changeCurrency(currency) {
  return typeof currency === 'string'
    ? { type: types.SWITCH_CURRENCY, payload: currency }
    : null;
}

export function changeDirection(isRTL) {
  return typeof isRTL === 'boolean'
    ? { type: types.SWITCH_DIRECTION, payload: isRTL }
    : null;
}

export function changeLocale(locale) {
  return typeof locale === 'string'
    ? { type: types.SWITCH_LOCALE, payload: locale }
    : null;
}

export function changeMeasure(measure) {
  return typeof measure === 'string'
    ? { type: types.SWITCH_MEASURE, payload: measure }
    : null;
}

export function clearApplication() {
  return { type: types.CLEAR_APPLICATION, payload: null };
}

export function onScroll(isScrolling) {
  if (typeof isScrolling !== 'boolean') {
    return null;
  }
  return {
    type: isScrolling ? types.SCROLLING_START : types.SCROLLING_END,
    payload: isScrolling
  };
}

export function onResize(isResizing) {
  if (typeof isResizing !== 'boolean') {
    return null;
  }
  return {
    type: isResizing ? types.RESIZE_START : types.RESIZE_END,
    payload: isResizing
  };
}
