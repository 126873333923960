// NOTE: THIS COMPENANT NEEDS TO BE UPGRADED TO REFLECT SUBSCRIPTIONSAGENT.JS!!!!
// A NEW STRIPE CHECKOUT PROCESS HAS BEEN IMPLEMENTED
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash.isfunction';
import { Button, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PaymentIcons from '../components/PaymentIcons';
import numberWithCommas from '../../../utils/number-with-commas';

import ContentDialog from '../../../components/Dialogs/ContentDialog';
import PaymentFailedDialog from '../dialogs/PaymentFailedDialog';
import LoadingIndicator from '../../../components/LoadingIndicator';
import ContactHelp from '../dialogs/ContactHelp';
import ErrorDialog from '../../../components/Dialogs/ErrorDialog';
import ListingsOnlySelector from '../../../components/ListingsOnlySelector';
import SubscriptionAgreement from '../../../components/Agreements/SubscriptionAgreement';
import realmLogo from '../../../images/realm-r.png';

import getParameterByName from '../../../utils/get-parameter-by-name';
import api from '../../../api';
import { API_ROOT, API_STRIPE_PUBLIC_KEY } from '../../../constants';

class SubscriptionsListing extends Component {
  constructor(props) {
    super(props);
    this.stripeDialog = React.createRef();
    this._isMounted = false;
    this.state = {
      billingPlan: '',
      contentSlug: 'marketing-company-product-plans-faqs',
      isError: false,
      isLoading: false,
      showContentDialog: false,
      showContactForm: false,
      teamId: props.team ? props.team._id : null,
      selectedListings: [],
      totalPrice: 0,
      existingSubscription: false,
      paymentFailed: getParameterByName('paymentFailed') || false
    };
  }

  componentDidMount() {
    const isListing = getParameterByName('type') === 'Listing';
    const paymentFailed = Boolean(getParameterByName('paymentFailed'));
    if (isListing && !paymentFailed) {
      this.updateSaasAgreement();
    }
    this._isMounted = true;
    const el = document.getElementById('page-wrapper');
    if (!this.props.addSubscription && this.props.hasSubscription) {
      this.props.history.push('/subscription-complete');
    }
    if (el && isFunction(el.scrollTo)) {
      el.scrollTo(0, 0);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.totalPrice !== this.state.totalPrice &&
      this.state.totalPrice > 0
    ) {
      this.createStripeDialog();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateSaasAgreement = async (payButtonClicked = false) => {
    const { pathname } = this.props.history.location;
    await api
      .put(`${API_ROOT}/user/saas/accept`)
      .then(() => {
        setTimeout(() => {
          this.props.actions.updatePerson();
        }, 0);
        if (!payButtonClicked) {
          this.props.history.push(
            pathname === '/add-subscription-listing'
              ? '/'
              : '/subscription-complete'
          );
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  // ***********************************************************
  //
  // create Stripe dialog with hidden fields for listings only
  // called when user clicks PAY NOW
  //
  // ***********************************************************
  createStripeDialog = () => {
    const { currentPerson } = this.props;
    const { totalPrice } = this.state;

    const target = this.stripeDialog.current;
    // removing child nodes in case of refresh which will update props
    if (target.hasChildNodes()) {
      while (target.firstChild) {
        target.removeChild(target.firstChild);
      }
    }
    const script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('src', 'https://checkout.stripe.com/checkout.js');
    script.setAttribute('class', 'stripe-button');
    script.setAttribute('data-key', API_STRIPE_PUBLIC_KEY);
    script.setAttribute('data-amount', totalPrice * 100);
    script.setAttribute('data-billing-address', 'true');
    script.setAttribute('data-name', this.state.billingPlan);
    script.setAttribute('data-description', 'REALM Listings Only');
    script.setAttribute('data-currency', 'USD');
    script.setAttribute(
      'data-email',
      currentPerson ? currentPerson.primaryEmail : 'example@email.com'
    );
    script.setAttribute('data-image', realmLogo);
    script.setAttribute('data-locale', 'auto');
    script.setAttribute('data-label', 'PAY NOW');
    target.appendChild(script);
  };

  toggleContentDialog = e => {
    if (e) e.preventDefault();
    const contentSlug =
      e && e.target && e.target.name
        ? `${e.target.name}-listingsonly`
        : this.state.contentSlug;
    this.setState(
      {
        contentSlug
      },
      () => {
        this.setState({ showContentDialog: !this.state.showContentDialog });
      }
    );
  };

  hideFailedForm = () => {
    const { pathname } = this.props.history.location;
    this.setState({ paymentFailed: false }, () => {
      this.props.history.push({
        pathname,
        search: ''
      });
    });
    // maybe need to remove from history completely
  };

  listingSelectionUpdate = (
    totalPrice,
    selectedListings,
    existingSubscription
  ) => {
    this.setState({
      totalPrice,
      selectedListings,
      existingSubscription
    });
  };

  toggleContactForm = e => {
    if (e) e.preventDefault();
    this.setState({ showContactForm: !this.state.showContactForm });
  };

  render() {
    const { language, agentData, currentPerson } = this.props;
    const { selectedListings, existingSubscription } = this.state;
    const listingsList = selectedListings.map(listing => listing._id);
    const { pathname } = this.props.history.location;
    return (
      <>
        <div className="realm--lo-back">
          <IconButton
            onClick={() => {
              window.history.back();
            }}
            aria-label="Close"
            style={{ margin: '0px 8px', backgroundColor: '#64626286' }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className="realm--product-plans">
          <h1>Payment Options</h1>
          <p className="realm--product-plans-lead text-centered">
            {this.state.existingSubscription
              ? `Update your subscription by completing the information below for your REALM Listings Only Subscription.`
              : `Please complete the information below to start your REALM Listings Only Subscription.`}
          </p>
          <div className="realm--product-plans-selected">
            <h2>
              <span className="realm--bumper">Your Plan:</span>
              REALM Listings Only
            </h2>
            <div className="realm--bumper-form-container">
              <h2>Select Your Listings:</h2>
              <div className="realm--bumper-form">
                <ListingsOnlySelector
                  agentData={this.props.agentData}
                  teamId={this.state.teamId}
                  callback={this.listingSelectionUpdate}
                  isAdmin={this.props.isAdmin}
                  isConcierge={this.props.isConcierge}
                />
              </div>
            </div>

            <div className="realm--bumper-form-container">
              <h2>Plan Investment:</h2>
              <div className="realm--bumper-form">
                <h3 style={{ marginTop: 0 }}>
                  TOTAL PRICE ${numberWithCommas(this.state.totalPrice)}.00
                  <div style={{ display: 'inline-block', marginLeft: 48 }}>
                    Pay with <PaymentIcons />{' '}
                  </div>
                </h3>
                <hr
                  style={{ opacity: 0.25, marginTop: 16, marginBottom: 16 }}
                />
                <SubscriptionAgreement
                  toggleContentDialog={this.toggleContentDialog}
                  buttonName="PAY NOW"
                  listingsOnly
                />
                {this.state.isLoading && (
                  <div
                    style={{
                      width: 280,
                      textAlign: 'center'
                    }}
                  >
                    <div style={{ width: 32, margin: '0 auto' }}>
                      <LoadingIndicator style={{ marginLeft: -16 }} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="realm--bumper-form-container">
            <h2>Payment:</h2>
            <div
              className="realm--bumper-form"
              style={{ display: 'inline-block' }}
            />
            {this.state.totalPrice === 0 ? (
              <Button
                style={{ width: 280, height: 46 }}
                color="primary"
                variant="contained"
                disabled
              >
                Pay Now
              </Button>
            ) : (
              <>
                <div
                  role="dialog"
                  style={{
                    position: 'relative',
                    display: this.state.totalPrice > 0 ? 'inline-block' : 'none'
                  }}
                  onClick={this.disablePage}
                >
                  <form
                    action={`${API_ROOT}/subscriptions/stripe/create`}
                    method="POST"
                    onClick={() => this.updateSaasAgreement(true)}
                  >
                    <div
                      className="realm--stripe-dialog"
                      ref={this.stripeDialog}
                    />
                    <input
                      type="hidden"
                      name="subscriptionPlan"
                      value={'REALM Listings Only'}
                    />
                    {existingSubscription ? (
                      <input
                        type="hidden"
                        name="membershipId"
                        value={existingSubscription}
                      />
                    ) : (
                      <input type="hidden" name="membershipId" value="" />
                    )}
                    <input
                      type="hidden"
                      name="listings"
                      value={JSON.stringify(listingsList)}
                    />
                    <input
                      type="hidden"
                      name="personId"
                      value={this.props.currentPerson._id}
                    />
                    <input
                      type="hidden"
                      name="successRedirect"
                      // value={this.state.existingSubscription ?  `https://${window.location.hostname}${pathname}/?type=Listing`
                      //   : `https://${window.location.hostname}/subscription-complete/?type=Listing`}
                      value={
                        this.state.existingSubscription
                          ? `https://${window.location.hostname}/subscription-complete/?type=Listing`
                          : `https://${window.location.hostname}/subscription-complete/?type=Listing`
                      }
                    />
                    <input
                      type="hidden"
                      name="failureRedirect"
                      value={`https://${window.location.hostname}${pathname}/?type=Listing&paymentFailed=true`}
                    />
                  </form>
                </div>
                <div style={{ marginTop: 98 }}>
                  <h2 style={{ marginTop: 98 }}>Verify Your Details:</h2>
                  <p>
                    <strong className="realm--bumper">Your Brokerage:</strong>
                    {agentData && agentData.currentBrokerage
                      ? agentData.currentBrokerage.name
                      : 'No brokerage provided'}
                  </p>
                  <p>
                    <strong className="realm--bumper">Your Name:</strong>
                    {agentData ? agentData.fullName : 'No name provided'}
                  </p>
                  <p>
                    <strong className="realm--bumper">Your Email:</strong>
                    {currentPerson
                      ? currentPerson.primaryEmail
                      : 'No email provided'}
                  </p>
                  <p>
                    <small>
                      <a
                        onClick={this.toggleContactForm}
                        style={{ textDecoration: 'underline' }}
                        href="/"
                      >
                        Need help? Contact the Concierge.
                      </a>
                    </small>
                  </p>
                </div>
              </>
            )}
          </div>
          <div className="realm--subscription-actions">
            <p className="text-centered">
              <small>
                <a
                  onClick={this.toggleContentDialog}
                  style={{ textDecoration: 'underline' }}
                  href="/"
                >
                  Membership FAQs
                </a>
              </small>
            </p>
          </div>
        </div>
        <ContentDialog
          apiType="fullPost"
          language={language}
          onClose={this.toggleContentDialog}
          open={this.state.showContentDialog}
          slug={this.state.contentSlug}
        />
        <PaymentFailedDialog
          onClose={this.hideFailedForm}
          open={this.state.paymentFailed}
        />
        <ContactHelp
          language={language}
          onClose={this.toggleContactForm}
          open={this.state.showContactForm}
          incomingPage="Getting Started"
        />
        <ErrorDialog
          handleClose={() => {
            this.setState({ isError: false });
          }}
          message={
            <div>
              Your transaction could not be completed. Please either try again
              or contact a REALM representative directly at{' '}
              <a
                style={{ textDecoration: 'underline' }}
                href="mailto:concierge@realm-global.com"
              >
                concierge@realm-global.com
              </a>{' '}
              for further assistance. We apologize for the inconvenience.
            </div>
          }
          open={this.state.isError}
        />
      </>
    );
  }
}

SubscriptionsListing.propTypes = {
  currentPerson: PropTypes.object,
  agentData: PropTypes.object,
  hasSubscription: PropTypes.bool,
  history: PropTypes.object,
  language: PropTypes.string,
  addSubscription: PropTypes.bool
};

SubscriptionsListing.defaultProps = {
  language: 'en'
};

export default SubscriptionsListing;
