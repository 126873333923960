import eventTracking from "./event-tracking";
import getPage from "./getPage";
import { API_ROOT } from "../constants";
import api from "../api";

export default async function trackSocialEvent(event) {
  // console.log('tracking social events from app', event,  ` time: ${new Date()}`);

  if (event.type === 'post') {
    eventTracking(`${getPage()}`, 'Post', `Group: ${event.displayName}`, `${event.isPublic ? 'Public' : 'Private'}`);
    await api.post(`${API_ROOT}/chat/social/logging`, { payload: event });
  }

  if (event.type === 'chat message') {
    eventTracking(`${getPage()}`, 'Chat', `Chat Id: ${event.channel.channelId}`, '');
    await api.post(`${API_ROOT}/chat/social/logging`, { payload: event });
  }
}
