import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const SnackMessage = props => (
  <Snackbar
    style={{ marginTop: `${props.marginTop || '150px'}`, zIndex: 9999 }}
    key="sentMessage"
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    open={props.open}
    onClose={props.onClose}
    autoHideDuration={props.hideDuration || 2250}
    ContentProps={{
      'aria-describedby': 'message-id'
    }}
    message={
      <div id="message-id" style={{ paddingRight: 40, width: '100%' }}>
        {props.message}
      </div>
    }
    action={[
      <div
        style={{
          position: 'absolute',
          width: '100%',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0
        }}
      >
        <IconButton
          style={{ position: 'absolute', top: 0, right: 0 }}
          key="close"
          aria-label="Close"
          color="primary"
          className="snackX"
          onClick={props.onClose}
        >
          <CloseIcon />
        </IconButton>
      </div>
    ]}
  />
);

SnackMessage.propTypes = {
  message: PropTypes.string,
  open: PropTypes.bool
};

export default SnackMessage;
