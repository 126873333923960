import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import LoadingIndicator from '../../components/LoadingIndicator';

const Admins = lazy(() => import('../Admins'));
const AdminActions = lazy(() => import('../AdminActions'));
const Agents = lazy(() => import('../Agents'));
const AgentProfile = lazy(() => import('../AgentProfile'));
const Properties = lazy(() => import('../Properties'));
const BlogPost = lazy(() => import('../BlogPost'));
const Brokerages = lazy(() => import('../Brokerages'));
const Clients = lazy(() => import('../ClientsSearch'));
const ClientProfile = lazy(() => import('../ClientProfile'));
//const ClientSearch = lazy(() => import('../ClientsSearch'))
const Company = lazy(() => import('../Company'));
const CompanyConnect = lazy(() => import('../CompanyConnect'));
const CoOwnership = lazy(() => import('../CoOwnership'));
const Dashboard = lazy(() => import('../Dashboard'));
const Messages = lazy(() => import('../Messages'));
const Social = lazy(() => import('../Social'));
const Dossiers = lazy(() => import('../Dossiers'));
const EditAccount = lazy(() => import('../EditAccount'));
const EditImages = lazy(() => import('../EditImages'));
const Events = lazy(() => import('../Events'));
const Logout = lazy(() => import('../Logout'));
const Logs = lazy(() => import('../Logs'));
const MatchAlerts = lazy(() => import('../MatchAlerts'));
const MatchAlertsDetail = lazy(() => import('../MatchAlertsDetail'));
const MatchClients = lazy(() => import('../MatchClients'));
const MatchDetail = lazy(() => import('../MatchDetail'));
const Matching = lazy(() => import('../Matching'));
const MatchesbyClient = lazy(() => import('../MatchesByClient'));
const MyAccount = lazy(() => import('../MyAccount'));
const NotFoundPage = lazy(() => import('../NotFoundPage'));
const SimpleBlogPost = lazy(() => import('../SimpleBlogPost'))
const SubscriptionLog = lazy(() => import('../SubscriptionLog'));
const Equite = lazy(() => import('../Equite'));
const Users = lazy(() => import('../Users'));
const Usage = lazy(() => import('../Users/Usage'));
const WealthEvents = lazy(() => import('../WealthEvents'));
const WhoViewedMe = lazy(() => import('../WhoViewedMe'));
const SubscriptionsListing = lazy(() =>
  import('../Subscriptions/routes/SubscriptionsListing')
);
const SubscriptionsComplete = lazy(() =>
  import('../Subscriptions/routes/SubscriptionsComplete')
);
const ManageTags = lazy(() => import('../ManageTags'));
const BrandResources = lazy(() => import('../Brand Resources/BrandResources'));
const StrategicPartners = lazy(() => import('../StrategicPartners'));
const NewDevelopments = lazy(() => import('../NewDevelopments'));
const DevelopmentHome = lazy(() => import('../DevelopmentHome'));

const AppRoutes = storeProps => (
  <Suspense
    fallback={
      <div className="realm--loading-container">
        <LoadingIndicator />
      </div>
    }
  >
    <Switch>
      {/* <Route
        exact
        path="/"
        render={props => (
          <Dashboard
            actions={storeProps.actions}
            history={props.history}
            locale={storeProps.application.locale}
            session={storeProps.session}
            subscription={storeProps.session.subscription}
            tags={storeProps.session.tags}
            isMobile={storeProps.application.isMobile}
            isTablet={storeProps.application.isTablet}
            isMobileOnly={storeProps.application.isMobileOnly}
          />
        )}
      /> */}
      <Route
        exact
        path={['/s/*', '/', '/dashboard']}
        render={props => (
          <Dashboard
            agentData={storeProps.session.agentData}
            isAdmin={storeProps.session.isAdmin}
            isAgent={storeProps.session.isAgent}
            actions={storeProps.actions}
            history={props.history}
            locale={storeProps.application.locale}
            session={storeProps.session}
            subscription={storeProps.session.subscription}
            tags={storeProps.session.tags}
            isMobile={storeProps.application.isMobile}
            isMobileOnly={storeProps.application.isMobileOnly}
            isTablet={storeProps.application.isTablet}
            currentPerson={storeProps.session.currentPerson}
            location={props.location}
          />
        )}
      />
      {/* <Redirect from="/login" to={`/s/${new Date().getTime()}`} /> */}
      <Redirect from="/login" to={'/dashboard'} />
      <Redirect from="/welcome" to="/" />
      <Route path="/admin/logs" component={Logs} />
      <Route
        path="/admin/subscription-log"
        render={() => <SubscriptionLog />}
      />
      <Route
        exact
        path="/admin/tools"
        render={props => (
          <AdminActions
            actions={storeProps.actions}
            session={storeProps.session}
            brokerages={storeProps.session.brokerages}
            isMobile={storeProps.application.isMobile}
            location={props.location}
          />
        )}
      />
      <Route path="/admin/logs" component={Logs} />
      <Route
        path="/agent-profile"
        render={props => (
          <AgentProfile
            agentData={storeProps.session.agentData}
            history={props.history}
            isAdmin={storeProps.session.isAdmin}
            isMobile={storeProps.application.isMobile}
            locale={storeProps.application.locale}
            location={props.location}
            tags={storeProps.session.tags}
          />
        )}
      />
      <Route
        path="/agents"
        render={props => (
          <Agents
            allTags={storeProps.session.allTags}
            actions={storeProps.actions}
            session={storeProps.session}
            brokerages={storeProps.session.brokerages}
            currentPerson={storeProps.session.currentPerson}
            history={props.history}
            isAdmin={storeProps.session.isAdmin}
            isAgent={storeProps.session.isAgent}
            isConcierge={storeProps.session.isConcierge}
            isMobile={storeProps.application.isMobile}
            isMobileOnly={storeProps.application.isMobileOnly}
            locale={storeProps.application.locale}
            location={props.location}
            tags={storeProps.session.tags}
            myGroups={storeProps.session.myGroups}
            pid={storeProps.session.pid}
            selectedGroup={storeProps.session.selectedGroup}
            searchSettings={storeProps.session.searchSettings}
          />
        )}
      />
      <Route
        path="/article"
        render={props => (
          <BlogPost
            application={storeProps.application}
            location={props.location}
            agentData={storeProps.session.agentData}
            isAdmin={storeProps.session.isAdmin}
            isAgent={storeProps.session.isAgent}
            tags={storeProps.session.tags}
            isMobile={storeProps.application.isMobile}
            history={props.history}
          />
        )}
      />
      <Route
        path="/story"
        render={props => (
          <SimpleBlogPost
            history={props.history}
            application={storeProps.application}
            location={props.location}
            agentData={storeProps.session.agentData}
            isAdmin={storeProps.session.isAdmin}
            isAgent={storeProps.session.isAgent}
            tags={storeProps.session.tags}
            isMobile={storeProps.application.isMobile}
          />
        )}
      />
      <Route
        path="/brand"
        render={() => (
          <BrandResources
            language={storeProps.application.locale}
            isMobile={storeProps.application.isMobile}
          />
        )}
      />
      <Route
        path="/brokerages/list"
        render={props => (
          <Brokerages
            actions={storeProps.actions}
            brokerages={storeProps.session.brokerages}
            currentPerson={storeProps.session.currentPerson}
            isAdmin={storeProps.session.isAdmin}
            isConcierge={storeProps.session.isConcierge}
            isMobile={storeProps.application.isMobile}
            isMobileOnly={storeProps.application.isMobileOnly}
            locale={storeProps.application.locale}
            history={props.history}
            location={props.location}
          />
        )}
      />
      <Route
        path="/client-profile"
        render={props => (
          <ClientProfile
            history={props.history}
            locale={storeProps.application.locale}
            tags={storeProps.session.tags}
            session={storeProps.session}
          />
        )}
      />
      <Route
        path="/company"
        render={props => (
          <Company
            locale={storeProps.application.locale}
            location={props.location}
          />
        )}
      />
      {/* <Route
        exact
        path="/dashboard"
        render={() => (
          <Dashboard
            actions={storeProps.actions}
            locale={storeProps.application.locale}
            session={storeProps.session}
            subscription={storeProps.session.subscription}
            tags={storeProps.session.tags}
            isMobile={storeProps.application.isMobile}
            isMobileOnly={storeProps.application.isMobileOnly}
            isTablet={storeProps.application.isTablet}
          />
        )}
      /> */}
      <Route
        path="/dossier"
        render={props => (
          <Dossiers
            location={props.location}
            history={props.history}
            isMobile={storeProps.application.isMobile}
            isMobileOnly={storeProps.application.isMobileOnly}
            isTablet={storeProps.application.isTablet}
            searchSettings={storeProps.session.searchSettings}
          />
        )}
      />
      <Route
        path="/edit-account"
        render={props => (
          <EditAccount
            actions={storeProps.actions}
            history={props.history}
            isAdmin={storeProps.session.isAdmin}
            isAgent={storeProps.session.isAgent}
            key={props.location.search}
            userId={storeProps.session.currentPerson._id}
            subscription={storeProps.session.subscription}
          />
        )}
      />
      <Route
        path="/events"
        render={props => (
          <Events
            actions={storeProps.actions}
            currentPerson={storeProps.session.currentPerson}
            history={props.history}
            isAdmin={storeProps.session.isAdmin}
            isAgent={storeProps.session.isAgent}
            key={props.location.search}
            userId={storeProps.session.currentPerson._id}
            subscription={storeProps.session.subscription}
            location={props.location}
            isMobile={storeProps.application.isMobile}
            isMobileOnly={storeProps.application.isMobileOnly}
            searchSettings={storeProps.session.searchSettings}
            globalSearchFilters={storeProps.session.searchFilters}
          />
        )}
      />
      <Route
        path="/strategicPartners"
        render={props => (
          <StrategicPartners
            actions={storeProps.actions}
            history={props.history}
            isAdmin={storeProps.session.isAdmin}
            isAgent={storeProps.session.isAgent}
            key={props.location.search}
            userId={storeProps.session.currentPerson._id}
            subscription={storeProps.session.subscription}
            location={props.location}
          />
        )}
      />
      <Route
        path="/equite"
        render={props => (
          <Equite
            currentPerson={storeProps.session.currentPerson}
            userId={storeProps.session.currentPerson._id}
          />
        )}
      />
      <Route
        path="/logout"
        render={() => (
          <Logout
            actions={storeProps.actions}
            router={storeProps.router}
            session={storeProps.session}
          />
        )}
      />
      <Route
        path="/manage-admins"
        render={() => <Admins session={storeProps.session} />}
      />
      <Route
        path="/manage-users"
        render={() => <Users isAdmin={storeProps.session.isAdmin} />}
      />
      <Route
        path="/match-alerts"
        render={props => (
          <MatchAlerts
            history={props.history}
            isMobile={storeProps.application.isMobile}
            isMobileOnly={storeProps.application.isMobileOnly}
            location={props.location}
            session={storeProps.session}
            selectedGroup={storeProps.session.selectedGroup}
            actions={storeProps.actions}
          />
        )}
      />
      <Route
        path="/match-alerts-detail"
        render={props => (
          <MatchAlertsDetail
            agentData={storeProps.session.agentData}
            avatar={storeProps.session.avatar}
            allTags={storeProps.session.allTags}
            history={props.history}
            isAdmin={storeProps.session.isAdmin}
            isAgent={storeProps.session.isAgent}
            isConcierge={storeProps.session.isConcierge}
            isMobile={storeProps.application.isMobile}
            isMobileOnly={storeProps.application.isMobileOnly}
            isTablet={storeProps.application.isTablet}
            lifestyles={storeProps.session.lifestyles}
            location={props.location}
            subscription={storeProps.session.subscription}
            tags={storeProps.session.tags}
          />
        )}
      />
      <Route
        path="/match-clients"
        render={props => (
          <MatchClients
            agentId={
              storeProps.session.agentData
                ? storeProps.session.agentData._id
                : storeProps.session.currentUser._id || ''
            }
            history={props.history}
            isMobile={storeProps.application.isMobile}
            isMobileOnly={storeProps.application.isMobileOnly}
            location={props.location}
            myGroups={storeProps.session.myGroups}
            selectedGroup={storeProps.session.selectedGroup}
            actions={storeProps.actions}
          />
        )}
      />
      <Route
        path="/match-detail"
        render={props => (
          <MatchDetail
            agentData={storeProps.session.agentData}
            application={storeProps.application}
            history={props.history}
            session={storeProps.session}
            isAdmin={storeProps.session.isAdmin}
            isMobile={storeProps.application.isMobile}
            isMobileOnly={storeProps.application.isMobileOnly}
            isTablet={storeProps.application.isTablet}
            locale={storeProps.application.locale}
            location={props.location}
            tags={storeProps.session.tags}
            lifestyles={storeProps.session.lifestyles}
            myGroups={storeProps.session.myGroups}
            selectedGroup={storeProps.session.selectedGroup}
            actions={storeProps.actions}
          />
        )}
      />
      <Route
        path="/edit-images"
        render={props => (
          <EditImages
            application={storeProps.application}
            history={props.history}
            session={storeProps.session}
            isMobile={storeProps.application.isMobile}
            locale={storeProps.application.locale}
            location={props.location}
            tags={storeProps.session.tags}
            lifestyles={storeProps.session.lifestyles}
            myGroups={storeProps.session.myGroups}
            selectedGroup={storeProps.session.selectedGroup}
            actions={storeProps.actions}
          />
        )}
      />
      <Route
        path="/matching"
        render={props => (
          <Matching
            history={props.history}
            isMobileOnly={storeProps.application.isMobileOnly}
            isMobile={storeProps.application.isMobile}
            locale={storeProps.application.locale}
            location={props.location}
            session={storeProps.session}
            myGroups={storeProps.session.myGroups}
            selectedGroup={storeProps.session.selectedGroup}
            actions={storeProps.actions}
          />
        )}
      />
      <Route
        path="/my-account"
        render={props => (
          <MyAccount
            key={props.location.search}
            history={props.history}
            location={props.location}
            session={storeProps.session}
          />
        )}
      />
      <Route
        path="/my-clients"
        render={props => (
          <Clients
            actions={storeProps.actions}
            allTags={storeProps.session.allTags}
            agentData={storeProps.session.agentData}
            currentPerson={storeProps.session.currentPerson}
            currentUser={storeProps.session.currentUser}
            history={props.history}
            isAdmin={storeProps.session.isAdmin}
            isAgent={storeProps.session.isAgent}
            isMobile={storeProps.application.isMobile}
            isMobileOnly={storeProps.application.isMobileOnly}
            location={props.location}
            locale={storeProps.application.locale}
            subscription={storeProps.session.subscription}
            tags={storeProps.session.tags}
            searchSettings={storeProps.session.searchSettings}
            pid={storeProps.session.pid}
          />
        )}
      />
      <Route
        path="/company-connect"
        render={props => (
          <CompanyConnect
            agentData={storeProps.session.agentData}
            allTags={storeProps.session.allTags}
            currentPerson={storeProps.session.currentPerson}
            currentUser={storeProps.session.currentUser}
            history={props.history}
            isAdmin={storeProps.session.isAdmin}
            isAgent={storeProps.session.isAgent}
            isMobile={storeProps.application.isMobile}
            isMobileOnly={storeProps.application.isMobileOnly}
            location={props.location}
            locale={storeProps.application.locale}
            subscription={storeProps.session.subscription}
            tags={storeProps.session.tags}
            searchSettings={storeProps.session.searchSettings}
          />
        )}
      />
      <Route
        path="/wealth-events"
        render={props => (
          <WealthEvents
            agentData={storeProps.session.agentData}
            allTags={storeProps.session.allTags}
            currentPerson={storeProps.session.currentPerson}
            currentUser={storeProps.session.currentUser}
            history={props.history}
            isAdmin={storeProps.session.isAdmin}
            isAgent={storeProps.session.isAgent}
            isMobile={storeProps.application.isMobile}
            isMobileOnly={storeProps.application.isMobileOnly}
            location={props.location}
            locale={storeProps.application.locale}
            subscription={storeProps.session.subscription}
            tags={storeProps.session.tags}
            searchSettings={storeProps.session.searchSettings}
            key="wealth-events"
          />
        )}
      />
      <Route
        path="/properties/list"
        render={props => (
          <Properties
            allTags={storeProps.session.allTags}
            agentData={storeProps.session.agentData}
            brokerages={storeProps.session.brokerages}
            currentPerson={storeProps.session.currentPerson}
            currentUser={storeProps.session.currentUser}
            history={props.history}
            isAdmin={storeProps.session.isAdmin}
            isAgent={storeProps.session.isAgent}
            isMobile={storeProps.application.isMobile}
            isMobileOnly={storeProps.application.isMobileOnly}
            lifestyles={storeProps.session.lifestyles}
            locale={storeProps.application.locale}
            location={props.location}
            pid={storeProps.session.pid}
            subscription={storeProps.session.subscription}
            tags={storeProps.session.tags}
            myGroups={storeProps.session.myGroups}
            selectedGroup={storeProps.session.selectedGroup}
            actions={storeProps.actions}
            searchSettings={storeProps.session.searchSettings}
            globalSearchFilters={storeProps.session.searchFilters}
            key="properties-1"
          />
        )}
      />
      <Route
        path="/properties/client/list"
        render={props => (
          <MatchesbyClient
            agentData={storeProps.session.agentData}
            brokerages={storeProps.session.brokerages}
            currentPerson={storeProps.session.currentPerson}
            currentUser={storeProps.session.currentUser}
            history={props.history}
            isAdmin={storeProps.session.isAdmin}
            isAgent={storeProps.session.isAgent}
            isMobile={storeProps.application.isMobile}
            isMobileOnly={storeProps.application.isMobileOnly}
            lifestyles={storeProps.session.lifestyles}
            locale={storeProps.application.locale}
            location={props.location}
            subscription={storeProps.session.subscription}
            tags={storeProps.session.tags}
            myGroups={storeProps.session.myGroups}
            selectedGroup={storeProps.session.selectedGroup}
            isDemo={storeProps.session.isDemo}
            actions={storeProps.actions}
            key="matchesbyclinet-1"
          />
        )}
      />
      <Route
        path="/view-client-property"
        render={props => (
          <MatchAlertsDetail
            allTags={storeProps.session.allTags}
            agentData={storeProps.session.agentData}
            isMobile={storeProps.application.isMobile}
            isMobileOnly={storeProps.application.isMobileOnly}
            isTablet={storeProps.application.isTablet}
            location={props.location}
            lifestyles={storeProps.session.lifestyles}
            history={props.history}
            tags={storeProps.session.tags}
            isAdmin={storeProps.session.isAdmin}
          />
        )}
      />
      <Route
        path="/developments"
        render={props => (
          <NewDevelopments
            agentData={storeProps.session.agentData}
            brokerages={storeProps.session.brokerages}
            currentPerson={storeProps.session.currentPerson}
            currentUser={storeProps.session.currentUser}
            history={props.history}
            isAdmin={storeProps.session.isAdmin}
            isAgent={storeProps.session.isAgent}
            isMobile={storeProps.application.isMobile}
            isMobileOnly={storeProps.application.isMobileOnly}
            lifestyles={storeProps.session.lifestyles}
            locale={storeProps.application.locale}
            location={props.location}
            subscription={storeProps.session.subscription}
            tags={storeProps.session.tags}
            allTags={storeProps.session.allTags}
            myGroups={storeProps.session.myGroups}
            selectedGroup={storeProps.session.selectedGroup}
            searchSettings={storeProps.session.searchSettings}
            actions={storeProps.actions}
            usePublicListings={storeProps.session.usePublicListings}
            isDevelopments
            key="developments-2"
          />
        )}
      />
      <Route
        path="/development-home"
        render={props => (
          <DevelopmentHome
            agentData={storeProps.session.agentData}
            brokerages={storeProps.session.brokerages}
            currentPerson={storeProps.session.currentPerson}
            currentUser={storeProps.session.currentUser}
            history={props.history}
            isAdmin={storeProps.session.isAdmin}
            isAgent={storeProps.session.isAgent}
            isMobile={storeProps.application.isMobile}
            isMobileOnly={storeProps.application.isMobileOnly}
            lifestyles={storeProps.session.lifestyles}
            locale={storeProps.application.locale}
            location={props.location}
            subscription={storeProps.session.subscription}
            tags={storeProps.session.tags}
            myGroups={storeProps.session.myGroups}
            session={storeProps.session}
            selectedGroup={storeProps.session.selectedGroup}
            actions={storeProps.actions}
            usePublicListings={storeProps.session.usePublicListings}
            key="development-homepage"
          />
        )}
      />
      <Route
        path="/co-ownership"
        render={props => (
          <CoOwnership
            agentData={storeProps.session.agentData}
            brokerages={storeProps.session.brokerages}
            currentPerson={storeProps.session.currentPerson}
            currentUser={storeProps.session.currentUser}
            history={props.history}
            isAdmin={storeProps.session.isAdmin}
            isAgent={storeProps.session.isAgent}
            isMobile={storeProps.application.isMobile}
            isMobileOnly={storeProps.application.isMobileOnly}
            lifestyles={storeProps.session.lifestyles}
            locale={storeProps.application.locale}
            location={props.location}
            subscription={storeProps.session.subscription}
            tags={storeProps.session.tags}
            myGroups={storeProps.session.myGroups}
            session={storeProps.session}
            searchSettings={storeProps.session.searchSettings}
            selectedGroup={storeProps.session.selectedGroup}
            actions={storeProps.actions}
            key="co-ownership-homepage"
          />
        )}
      />
      <Route
        path="/usage"
        render={() => <Usage session={storeProps.session} />}
      />
      <Route
        path="/view-property"
        render={props => (
          <MatchAlertsDetail
            allTags={storeProps.session.allTags}
            agentData={storeProps.session.agentData}
            isMobile={storeProps.application.isMobile}
            isMobileOnly={storeProps.application.isMobileOnly}
            isTablet={storeProps.application.isTablet}
            location={props.location}
            lifestyles={storeProps.session.lifestyles}
            history={props.history}
            tags={storeProps.session.tags}
            isAdmin={storeProps.session.isAdmin}
          />
        )}
      />
      <Route
        path="/view-property-for-my-clients"
        render={props => (
          <MatchAlertsDetail
            agentData={storeProps.session.agentData}
            avatar={storeProps.session.avatar}
            allTags={storeProps.session.allTags}
            currentUser={storeProps.session.currentUser}
            history={props.history}
            isAdmin={storeProps.session.isAdmin}
            isAgent={storeProps.session.isAgent}
            isConcierge={storeProps.session.isConcierge}
            isMobile={storeProps.application.isMobile}
            isMobileOnly={storeProps.application.isMobileOnly}
            isTablet={storeProps.application.isTablet}
            lifestyles={storeProps.session.lifestyles}
            location={props.location}
            subscription={storeProps.session.subscription}
            tags={storeProps.session.tags}
          />
        )}
      />
      <Route
        path="/whoviewedme"
        render={props => (
          <WhoViewedMe
            history={props.history}
            isMobile={storeProps.application.isMobile}
          />
        )}
      />
      <Route
        path="/whoviewedme"
        render={props => (
          <WhoViewedMe
            history={props.history}
            isMobile={storeProps.application.isMobile}
          />
        )}
      />
      <Route
        exact
        path="/add-subscription-listing"
        render={props => (
          <SubscriptionsListing
            actions={storeProps.actions}
            agentData={storeProps.session.agentData}
            currentPerson={storeProps.session.currentPerson}
            isAdmin={storeProps.session.isAdmin}
            isAgent={storeProps.session.isAgent}
            isConcierge={storeProps.session.isConcierge}
            hasSubscription={storeProps.session.hasSubscription}
            hasPendingSubscription={storeProps.session.hasPendingSubscription}
            history={props.history}
            language={storeProps.application.locale}
            addSubscription
          />
        )}
      />
      <Route exact path="/agreements">
        <Redirect to="/" />
      </Route>
      {/* <Route exact path="/subscription-complete">
      <Redirect to="/" />
    </Route> */}
      <Route
        path="/manage-tags"
        render={props => (
          <ManageTags
            history={props.history}
            isAdmin={storeProps.session.isAdmin}
          />
        )}
      />
      {/* In case we land on a subscription route */}
      <Route exact path="/subscription-complete">
        <Redirect to="/" />
      </Route>
      <Route exact path="/subscription-options">
        <Redirect to="/" />
      </Route>
      <Route exact path="/subscription-agents">
        <Redirect to="/" />
      </Route>
      <Route exact path="/subscription-listings">
        <Redirect to="/" />
      </Route>
      <Route exact path="/subscription-teams">
        <Redirect to="/" />
      </Route>
      <Route
        exact
        path="/messages"
        render={props => (
          <Messages
          agentData={storeProps.session.agentData}
          isAdmin={storeProps.session.isAdmin}
          isAgent={storeProps.session.isAgent}
          actions={storeProps.actions}
          history={props.history}
          locale={storeProps.application.locale}
          session={storeProps.session}
          subscription={storeProps.session.subscription}
          tags={storeProps.session.tags}
          isMobile={storeProps.application.isMobile}
          isMobileOnly={storeProps.application.isMobileOnly}
          isTablet={storeProps.application.isTablet}
          currentPerson={storeProps.session.currentPerson}
          location={props.location}
        />
        )}
      />
       <Route
        exact
        path="/social"
        render={props => (
          <Social
          agentData={storeProps.session.agentData}
          isAdmin={storeProps.session.isAdmin}
          isAgent={storeProps.session.isAgent}
          actions={storeProps.actions}
          history={props.history}
          locale={storeProps.application.locale}
          session={storeProps.session}
          subscription={storeProps.session.subscription}
          tags={storeProps.session.tags}
          isMobile={storeProps.application.isMobile}
          isMobileOnly={storeProps.application.isMobileOnly}
          isTablet={storeProps.application.isTablet}
          currentPerson={storeProps.session.currentPerson}
          location={props.location}
          fullPage={true}
        />
        )}
      />
      <Route path="" component={NotFoundPage} />
    </Switch>
  </Suspense>
);

export default AppRoutes;
