import { useReducer, useEffect, useCallback, useMemo } from 'react';
import { sendSearchResultsMetric } from '../../helpers/metrics';
import api from '../../api';
import USStates from '../../data/us-states';
//import { API_ROOT } from '../../constants';
const ACTIONS = {
  START_SEARCH: 'start-search',
  CLIENTS_SUCCESS: 'clients-data',
  CLIENTS_ERROR: 'clients-error',
  PROPERTIES_SUCCESS: 'properties-data',
  PROPERTIES_ERROR: 'properties-error',
  DEVELOPMENTS_SUCCESS: 'developments-data',
  DEVELOPMENTS_ERROR: 'developments-error',
  AGENTS_SUCCESS: 'agents-data',
  AGENTS_ERROR: 'agents-error',
  BROKERAGES_SUCCESS: 'brokerages-data',
  BROKERAGES_ERROR: 'brokerages-error',
  CLEAR_ERROR: 'clear-error'
};
const initialState = {
  isLoading: {
    agents: false,
    clients: false,
    properties: false,
    developments: false,
    brokerages: false
  },
  agents: [],
  clients: [],
  properties: [],
  developments: [],
  brokerages: [],
  isError: null,
  isSuccess: {}
};

const reducer = (state, action) => {
  let newState;
  switch (action.type) {
    case 'start-search':
      newState = {
        isLoading: {
          clients: true,
          agents: true,
          properties: true,
          developments: true,
          brokerages: true
        },
        clients: [],
        properties: [],
        developments: [],
        agents: [],
        brokerages: [],
        isSuccess: {}
      };
      break;
    case 'agents-data':
      newState = {
        ...state,
        isLoading: { ...state.isLoading, agents: false },
        agents: action.payload.agents,
        isSuccess: { ...state.isSuccess, agents: true }
      };
      break;
    case 'agents-error':
      newState = {
        ...state,
        isLoading: { ...state.isLoading, agents: false },
        agents: [],
        isError: action.payload.error
      };
      break;
    case 'properties-data':
      newState = {
        ...state,
        isLoading: { ...state.isLoading, properties: false },
        properties: action.payload.properties,
        isSuccess: { ...state.isSuccess, properties: true }
      };
      break;
    case 'properties-error':
      newState = {
        ...state,
        isLoading: { ...state.isLoading, properties: false },
        properties: [],
        isError: action.payload.error
      };
      break;
    case 'developments-data':
      newState = {
        ...state,
        isLoading: { ...state.isLoading, developments: false },
        developments: action.payload.developments,
        isSuccess: { ...state.isSuccess, developments: true }
      };
      break;
    case 'developments-error':
      newState = {
        ...state,
        isLoading: { ...state.isLoading, developments: false },
        developments: [],
        isError: action.payload.error
      };
      break;
    case 'clients-data':
      newState = {
        ...state,
        isLoading: { ...state.isLoading, clients: false },
        clients: action.payload.clients,
        isSuccess: { ...state.isSuccess, clients: true }
      };
      break;
    case 'clients-error':
      newState = {
        ...state,
        isLoading: { ...state.isLoading, clients: false },
        clients: [],
        isError: action.payload.error
      };
      break;
    case 'brokerages-data':
      newState = {
        ...state,
        isLoading: { ...state.isLoading, brokerages: false },
        brokerages: action.payload.brokerages,
        isSuccess: { ...state.isSuccess, brokerages: true }
      };
      break;
    case 'brokerages-error':
      newState = {
        ...state,
        isLoading: { ...state.isLoading, brokerages: false },
        brokerages: [],
        isError: action.payload.error
      };
      break;
    case 'clear-error':
      newState = {
        ...state,
        isError: null
      };
      break;
    default:
      newState = state;
  }
  return newState;
};

export const useGlobalSearch = (
  category,
  search,
  agentId,
  personId,
  isAdmin
) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState });
  const stateAbbreviation = useMemo(() => {
    const hasStateAbbreviation = Object.values(USStates).findIndex(
      el => el.toLowerCase() === search.toLowerCase()
    );
    let stateSearch = '';
    if (hasStateAbbreviation !== -1) {
      stateSearch = Object.keys(USStates)[hasStateAbbreviation].toLowerCase();
    }
    return stateSearch;
  }, [search]);
  const getAgentData = useCallback(async () => {
    try {
      const pageFilters = {
        category,
        filter: {},
        groupId: null,
        limit: 5,
        page: 1,
        //searchBrokerage: "", //not used will come through as category:all, searchName
        //searchLocation: category === 'location' ? search : '',
        searchName: search,
        searchState: stateAbbreviation || null,
        sortBy: 'fullName',
        sortDir: 1,
        //tagKey: 1660680355932, DO WE NEED THIS?
        tagNames: []
      };
      const response = await api.post('/agents/search', pageFilters);
      dispatch({
        type: ACTIONS.AGENTS_SUCCESS,
        payload: { agents: response.data.data.data }
      });
    } catch (e) {
      console.log(e);
      dispatch({ type: ACTIONS.AGENTS_ERROR, payload: { error:
      `There was an error fetching Agents. Please try again or contact support.

      ${e.message}` }
    });
    }
  }, [category, search, stateAbbreviation]);
  const getPropertyData = useCallback(async () => {
    try {

      const pageFilters = {
        category,
        currentAgentId: agentId,
        filter: {},
        limit: 5,
        page: 1,
        searchName: search,
        searchState: stateAbbreviation || null,
        showArchived: false,
        showMine: false,
        sortBy: 'price.amount',
        sortDir: -1
      };
      const response = await api.post('/listings/search', pageFilters);
      const { listhubListings } = response?.data?.data || {};
      sendSearchResultsMetric(listhubListings);

      dispatch({
        type: ACTIONS.PROPERTIES_SUCCESS,
        payload: { properties: response.data.data.data }
      });
    } catch (e) {
      console.log(e);
      dispatch({ type: ACTIONS.PROPERTIES_ERROR, payload: { error: `There was an error fetching Properties. Please try again or contact support.

      ${e.message}` }});
    }
  }, [category, search, agentId, stateAbbreviation]);
  const getClientData = useCallback(async () => {
    try {
      const pageFilters = {
        category,
        searchName: search,
        searchState: stateAbbreviation || null,
        person: personId,
        includeMatches: false,
        limit: 5,
        page: 1,
        filter: {},
        sortBy: 'lastNameFirst',
        sortDir: 1,
        tagNames: []
      };
      if (isAdmin) {
        //admins can't have clients, so se empty array
        dispatch({
          type: ACTIONS.CLIENTS_SUCCESS,
          payload: { clients: [] }
        });
      } else {
        const response = await api.post('/agentClients/search', pageFilters);
        dispatch({
          type: ACTIONS.CLIENTS_SUCCESS,
          payload: { clients: response.data.data.docs }
        });
      }
    } catch (e) {
      console.log(e);
      dispatch({ type: ACTIONS.CLIENTS_ERROR, payload: { error: `There was an error fetching Clients. Please try again or contact support.

      ${e.message}` } });
    }
  }, [category, search, personId, isAdmin, stateAbbreviation]);
  const getDevelopmentData = useCallback(async () => {
    try {
      const pageFilters = {
        category: 'global',
        searchName: search,
        filter: { listingType: { $ne: 'Prospect' } },
        searchState: stateAbbreviation || null,
        isDevelopments: true,
        sortBy: 'price.amount',
        sortDir: 1,
        limit: 3,
        page: 1
      };
      const response = await api.post('/development/search', pageFilters);
      dispatch({
        type: ACTIONS.DEVELOPMENTS_SUCCESS,
        payload: { developments: response.data.data.data }
      });
    } catch (e) {
      console.log(e);
      dispatch({ type: ACTIONS.DEVELOPMENTS_ERROR, payload: { error: `There was an error fetching Developments. Please try again or contact support.

      ${e.message}` }});
    }
  }, [category, search, agentId, stateAbbreviation]);
  const getBrokerageData = useCallback(async () => {
    try {
      const pageFilters = {
        category,
        searchName: search,
        searchState: stateAbbreviation || null,
        page: 1,
        limit: 5
        //showArchived: true
      };
      if (isAdmin) {
        const response = await api.post('/brokerages/filter', pageFilters);
        dispatch({
          type: ACTIONS.BROKERAGES_SUCCESS,
          payload: { brokerages: response.data.data.data }
        });
      } else {
        dispatch({
          type: ACTIONS.BROKERAGES_SUCCESS,
          payload: { brokerages: [] }
        });
      }
    } catch (e) {
      console.log(e);
      dispatch({ type: ACTIONS.BROKERAGES_ERROR, payload: { error: `There was an error fetching Brokerages. Please try again.

      ${e.message}` }});
    }
  }, [category, search, isAdmin, stateAbbreviation]);

  const clearError = () => {
    dispatch({ type: ACTIONS.CLEAR_ERROR, payload: null });
  };

  useEffect(() => {
    if (!search) {
      return;
    }

    dispatch({ type: ACTIONS.START_SEARCH });

    const clearDevelopments = async () => {
      dispatch({
        type: ACTIONS.DEVELOPMENTS_SUCCESS,
        payload: { developments: [] }
      });
    };

    const clearProperties = async () => {
      dispatch({
        type: ACTIONS.PROPERTIES_SUCCESS,
        payload: { properties: [] }
      });
    };

    const clearClients = async () => {
      dispatch({
        type: ACTIONS.CLIENTS_SUCCESS,
        payload: { clients: [] }
      });
    };

    const clearAgents = async () => {
      dispatch({
        type: ACTIONS.AGENTS_SUCCESS,
        payload: { agents: [] }
      });
    };

    const clearBrokerages = async () => {
      dispatch({
        type: ACTIONS.BROKERAGES_SUCCESS,
        payload: { brokerages: [] }
      });
    };

    const allDataFetchers = [
      getAgentData,
      getPropertyData,
      getClientData,
      getDevelopmentData,
      getBrokerageData
    ];

    const fetchersMap = {
      developments: [
        getDevelopmentData,
        clearProperties,
        clearClients,
        clearAgents,
        clearBrokerages
      ],
      properties: [
        clearDevelopments,
        getPropertyData,
        clearClients,
        clearAgents,
        clearBrokerages
      ],
      clients: [
        clearDevelopments,
        clearProperties,
        getClientData,
        clearAgents,
        clearBrokerages
      ],
      agents: [
        clearDevelopments,
        clearProperties,
        clearClients,
        getAgentData,
        clearBrokerages
      ]
    };

    const dataFetchers = fetchersMap[category] || allDataFetchers;

    Promise.all(dataFetchers.map(fetcher => fetcher()));
  }, [category, search, isAdmin]);

  return { ...state, clearError };
};
