import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import localstring from '../../services/Localization';

const ErrorDialog = props => (
  <Dialog
    open={props.open || false}
    onClose={props.handleClose}
    aria-labelledby="error-dialog-title"
    aria-describedby="error-dialog"
  >
    <DialogTitle id="delete-dialog-title">{props.altTitle || localstring.error}</DialogTitle>
    <DialogContent className="realm--modal-content">
      <DialogContentText className="error-msg">
        {props.message}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      {props.handleRetry ? (
        <>
          <Button
            onClick={props.handleClose}
            color="default"
            variant="contained"
          >
            {localstring.cancel}
          </Button>
          <Button
            onClick={props.handleRetry}
            color="primary"
            variant="contained"
          >
            {props.okBtnOverride ? props.okBtnOverride : localstring.retry}
          </Button>
        </>
      ) : (
        <Button onClick={props.handleClose} color="primary" variant="contained">
          {props.okBtnOverride ? props.okBtnOverride : localstring.close}
        </Button>
      )}
    </DialogActions>
  </Dialog>
);

ErrorDialog.propTypes = {
  handleClose: PropTypes.func,
  handleRetry: PropTypes.func,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  okBtnOverride: PropTypes.string,
  open: PropTypes.bool
};

export default ErrorDialog;
