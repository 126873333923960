/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Dialog, DialogContent } from '@material-ui/core';
import FormControl from '../Users/FormControl';
import LoadingIndicator from '../LoadingIndicator';
import ResetPassword from './ResetPasswordRequest';
import ChangePassword from './ChangePassword';
import ErrorDialog from '../Dialogs/ErrorDialog';
import ContactUs from '../Dialogs/ContactUs';

import api from '../../api';
import getParameterByName from '../../utils/get-parameter-by-name';
import localstring from '../../services/Localization';
import { API_ROOT } from '../../constants';
import setLoginMessage from '../../utils/login-error-message';

class LoginDialog extends Component {
  constructor(props) {
    super(props);
    const verificationCode = getParameterByName('verificationCode') || '';
    const verificationCodeIsValid = verificationCode.length === 6;
    this.state = {
      attemptRetry: false,
      errorMessage: '',
      errorTitle: '',
      isError: false,
      isLoading: false,
      isValid: false,
      password: '',
      showStepCheckEmail: false,
      showStepRequestNewPassword: false,
      showStepChangePassword:
        verificationCodeIsValid && Boolean(verificationCode),
      showPasswordRequestSuccess: false,
      username: props.username || '',
      verificationCode: verificationCodeIsValid ? verificationCode : '',
      showMaintenance: props.session.maintenanceMode,
      contactModalOpen: false,
      rememberMe: false,
    };
  }


  componentDidMount() {
    if (!this.props.session.hasOwnProperty('maintenanceMode')) {
      this.props.actions.getSystemSettings()
    }

    this.setState({
      rememberMe: false,
    })
  }

    /* reset password stuff */
  requestResetPassword = () => {
      this.setState({
        showStepRequestNewPassword: true
      });
    };

  componentDidUpdate(prevProps) {
    if (this.props.session.hasOwnProperty('maintenanceMode')) {
      if (!prevProps.session.hasOwnProperty('maintenanceMode')) {
        this.setState({ showMaintenance: this.props.session.maintenanceMode });
      } else if (this.props.session.maintenanceMode !== prevProps.session.maintenanceMode) {
        this.setState({ showMaintenance: this.props.session.maintenanceMode });
      }
    }
    if (this.props.session.errorMessage !== prevProps.session.errorMessage && this.props.session.errorMessage !== '') {
      console.log('error message: ', this.props.session.errorMessage, 'new error message:', setLoginMessage(this.props.session.errorMessage))
      const newMessage = setLoginMessage(this.props.session.errorMessage, () => {this.requestResetPassword()}, () => {this.setState({contactModalOpen: true})});
      this.setState({
        errorMessage: newMessage.message,
        errorTitle: newMessage.title,
        isError: true,
        isLoading: false
      });
      //set the session error message to empty string
    }
  }

  validateForm = () => this.validateEmail() && this.state.password.length > 0;

  // for now -- need better validation
  validateEmail = () => {
    const re = /\S+@\S+\.\S+/;
    return re.test(this.state.username);
  };

  goToLogin = () => {
    window.location.replace('/login');
  };

  onRememberMeChange = () => {
    this.setState(previousState => ({
      rememberMe: !previousState.rememberMe,
    }))
  }

  handleChange = e => {
    this.setState(
      {
        [e.target.id]: e.target.value
      },
      () => {
        this.setState({ isValid: this.validateForm() });
      }
    );
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ isLoading: true });
    const authenticationData = {
      username: this.state.username.toLowerCase(),
      password: this.state.password,
      rememberMe: this.state.rememberMe,
    };
    this.props.actions.authenticate(authenticationData);
  };

  handleClose = () => {
    this.props.actions.retryAuthentication();
    this.setState({
      attemptRetry: false,
      errorMessage: '',
      isError: false,
      isLoading: false,
      showStepRequestNewPassword: false,
      showStepChangePassword: false,
      showPasswordRequestSuccess: false
    });
  };

  cancelResetPassword = () => {
    this.setState({
      showStepRequestNewPassword: false,
      showStepCheckEmail: false
    });
  };

  onRetryPassword = () => {
    this.setState({
      attemptRetry: false,
      errorMessage: '',
      isError: false,
      showStepChangePassword: true
    });
  };

  resetPassword = async emailAddress => {
    this.setState({
      showStepRequestNewPassword: true
    });
    try {
      const response = await api.post(`${API_ROOT}/user/reset-password`, {
        username: emailAddress
      });
      if (response.status === 200) {
        this.setState({
          showStepRequestNewPassword: false,
          showStepCheckEmail: true
        });
      }
    } catch (ex) {
      const msg = ex.response ? ex.response.data.msg : ex.msg;
      this.setState({
        errorMessage: msg,
        isError: true,
        showStepRequestNewPassword: false
      });
    }
  };

  changePassword = async authObj => {
    if (!authObj.password || !authObj.username) {
      return null;
    }
    try {
      const response = await api.post(`${API_ROOT}/user/confirm-password`, {
        code: this.state.verificationCode,
        password: authObj.password,
        username: authObj.username
      });
      if (response.status === 200) {
        this.setState({
          showPasswordRequestSuccess: true
        });
      }
    } catch (ex) {
      const msg = ex.response ? ex.response.data.msg : ex.msg;
      this.setState({
        errorMessage: msg,
        isError: true,
        showStepChangePassword: false,
        attemptRetry: true
      });
    }
    return null;
  };

  render() {
    const { session } = this.props;
    const { showMaintenance } = this.state;

    return (
      <>
        <Dialog
          open={this.props.open || true}
          onClose={this.props.handleClose}
          aria-describedby="login-dialog"
        >
          <DialogContent
            className="realm--modal-content"
          >
            <div>
              <h2 style={{ textAlign: 'center' }}>
                {showMaintenance
                  ? localstring.login_under_maintenance
                  : localstring.login}
              </h2>

              <form onSubmit={this.handleSubmit}>
                <div className="form-group form-group-lg">
                  <label className="control-label" htmlFor="username">
                    {localstring.onboarding.email}
                  </label>
                  <FormControl
                    autoFocus
                    id="username"
                    type="text"
                    value={this.state.username}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group form-group-lg">
                  <label className="control-label" htmlFor="password">
                    {localstring.onboarding.password}
                  </label>
                  <FormControl
                    id="password"
                    value={this.state.password}
                    onChange={this.handleChange}
                    type="password"
                  />
                </div>
                <div className="form-group form-group-lg">
                  <label className="control-label" htmlFor="remember_me">
                    {localstring.onboarding.remember_me}
                  </label>
                  <Checkbox
                    id="remember_me"
                    name="remember_me"
                    checked={this.state.rememberMe}
                    onChange={this.onRememberMeChange}
                  />
                </div>
                {this.state.isLoading ? (
                  <div className="realm--loading-minwidth">
                    <LoadingIndicator message={localstring.signing_in} />
                  </div>
                ) : (
                  <div style={{ textAlign: 'center', marginBottom: 20 }}>
                    <Button
                      fullWidth
                      color="default"
                      variant="contained"
                      size="large"
                      style={{
                        margin: '30px 0 20px',
                        opacity: this.state.isValid ? '1' : '.5',
                        pointerEvents: this.state.isValid ? 'auto' : 'none'
                      }}
                      type="submit"
                    >
                      {showMaintenance
                        ? localstring.login_under_maintenance
                        : localstring.login}
                    </Button>
                    <ResetPassword
                      forgot
                      handleOpen={this.requestResetPassword}
                      handleClose={this.cancelResetPassword}
                      handleReset={this.resetPassword}
                      open={this.state.showStepRequestNewPassword}
                      showButton
                      showStepCheckEmail={this.state.showStepCheckEmail}
                      small
                    />
                    <ContactUs
                      addUserInformation
                      linkText="Trouble Logging In?"
                      subject="Help with My Account"
                      open = {this.state.contactModalOpen}
                    />
                  </div>
                )}
              </form>
            </div>
          </DialogContent>
        </Dialog>
        {/* <ErrorDialog
          handleClose={this.handleClose}
          message={session.errorMessage}
          open={session.authenticationFailed}
        /> */}
        <ErrorDialog
          handleClose={this.handleClose}
          handleRetry={
            this.state.attemptRetry ? this.onRetryPassword : undefined
          }
          message={this.state.errorMessage}
          altTitle={this.state.errorTitle}
          open={this.state.isError}
        />
        <ChangePassword
          handleReset={this.changePassword}
          isSuccess={this.state.showPasswordRequestSuccess}
          onSuccess={this.goToLogin}
          open={this.state.showStepChangePassword}
        />
      </>
    );
  }
}

LoginDialog.propTypes = {
  actions: PropTypes.object,
  handleClose: PropTypes.func,
  session: PropTypes.object
};

export default LoginDialog;
