import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash.isfunction';
import moment from 'moment';
import merge from 'lodash.merge';
import DOMPurify from 'dompurify';
import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Select,
  IconButton,
  Grid
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import ContentDialog from '../../../components/Dialogs/ContentDialog';
import ContactHelp from '../dialogs/ContactHelp';
import LoadingIndicator from '../../../components/LoadingIndicator';
import PaymentFailedDialog from '../dialogs/PaymentFailedDialog';
import PaymentIcons from '../components/PaymentIcons';
import ErrorDialog from '../../../components/Dialogs/ErrorDialog';
import realmLogo from '../../../images/realm-r.png';
import SubscriptionAgreement from '../../../components/Agreements/SubscriptionAgreement';

import api from '../../../api';
import { API_ROOT } from '../../../constants';
import getParameterByName from '../../../utils/get-parameter-by-name';
import USStates from '../../../data/us-states';

class SubscriptionsStripeOnly extends Component {
  constructor(props) {
    super(props);
    this.stripeDialog = React.createRef();
    this._isMounted = false;
    this.state = {
      billingPayment: 'now',
      billingPlan: '',
      contentSlug: 'marketing-company-product-plans-faqs',
      isError: false,
      isLoading: false,
      isPageDisabled: false,
      showContentDialog: false,
      showContactForm: false,
      paymentFailed: getParameterByName('paymentFailed') || false,
      code: '',
      street: '',
      city: '',
      st: '',
      postalCode: '',
      isValidForm: false,
      subData: [],
      uagPdf: ''
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const el = document.getElementById('page-wrapper');
    if (el && isFunction(el.scrollTo)) {
      el.scrollTo(0, 0);
    }
    this.props.actions.setPendingSubscription();
    this.createStripeDialog();
    try {
      const response = await api.get('/subscriptions/agent/pricing');
      if (response.data.data[0]?.priceName.includes('Two-Year')){
        this.setState({ subData: response.data.data, billingPlan: response.data.data[0]?.priceName, uagPdf: response.data.data[0]?.uag });
      } else {
        this.setState({ subData: response.data.data });
      }
    } catch (err) {
      alert(err.response.data.msg);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.billingPlan !== this.state.billingPlan) {
      this.createStripeDialog();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  subscribe = async () => {
    this.setState({
      isLoading: true
    });
    const payload = {
      billingAdmins: [this.props.currentPerson._id],
      // billingInfo: {
      //   address: {
      //     line1: DOMPurify.sanitize(this.state.street),
      //     city: DOMPurify.sanitize(this.state.city),
      //     state: this.state.st,
      //     zip: DOMPurify.sanitize(this.state.postalCode)
      //   }
      // },
      billinAdminAddress: {
          streetAddress: DOMPurify.sanitize(this.state.street),
          city: DOMPurify.sanitize(this.state.city),
          state: this.state.st,
          postalCode: DOMPurify.sanitize(this.state.postalCode)
      },
      code: DOMPurify.sanitize(this.state.code),
      endsAt: `${moment()
        .add(1, 'years')
        .format('YYYY-MM-DD')}`,
      // this.state.billingPlan === 'REALM Agent Monthly'
      //   ? `${moment()
      //       .add(30, 'days')
      //       .format('YYYY-MM-DD')}`
      //   : `${moment()
      //       .add(1, 'years')
      //       .format('YYYY-MM-DD')}`,
      status: 'Active',
      startsAt: `${moment().format('YYYY-MM-DD')}`,
      // JD: membership is blank for team subs...back-end will create after checkout now
      membership:
        this.state.subData.length && this.state.subData[0].type === 'Agent'
          ? this.props.agentData._id
          : '',
      name: this.state.billingPlan,
      // True = Send Billing Receipt to Agent and Notify Concierge
      isRenewal: this.props.hasSubscriptionExpired
    };

    try {
      let response;
      const { props } = this;
      if (props.hasSubscription || props.hasSubscriptionExpired) {
        response = await api.put(
          `${API_ROOT}/subscriptions/${props.subscription._id}`,
          payload
        );
      } else {
        response = await api.post(`${API_ROOT}/subscriptions/create`, payload);
      }
      this.props.actions.getPersonData();
      this.props.actions.updateSubscription(response.data.data.data);
      this.props.history.push({
        pathname: '/subscription-complete',
        search: ''
      });
    } catch (err) {
      alert(err);
      this.setState({
        isError: true,
        isLoading: false
      });
    }
  };

  createStripeDialog = () => {
    const { currentPerson } = this.props;
    const target = this.stripeDialog.current;
    // removing child nodes in case of refresh which will update props
    if (target.hasChildNodes()) {
      while (target.firstChild) {
        target.removeChild(target.firstChild);
      }
    }
    const script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('src', 'https://js.stripe.com/v3/');
    // script.setAttribute('class', 'stripe-button');
    // script.setAttribute('data-key', API_STRIPE_PUBLIC_KEY);
    // script.setAttribute(
    //   'data-amount',
    //   this.state.billingPlan === 'REALM Agent Monthly' ? 50000 : 500000
    // );
    // script.setAttribute('data-billing-address', 'true');
    // script.setAttribute('data-name', this.state.billingPlan);
    // script.setAttribute('data-description', 'REALM Agent Subscription Plan');
    // script.setAttribute('data-currency', 'USD');
    // script.setAttribute(
    //   'data-email',
    //   currentPerson ? currentPerson.primaryEmail : 'example@email.com'
    // );
    // script.setAttribute('data-image', realmLogo);
    // script.setAttribute('data-locale', 'auto');
    // script.setAttribute('data-label', 'PAY NOW');
    target.appendChild(script);
  };

  handleSelection = e => {
    // Set Stripe Price Id
    this.setState({ [e.target.name]: e.target.value });

    // Set Agreement Pdf URL
    this.state.subData.forEach(sub => {
      if (sub.priceName === e.target.value) this.setState({ uagPdf: sub.uag });
    });
  };

  toggleContentDialog = e => {
    if (e) e.preventDefault();
    this.setState(
      {
        contentSlug: (e && e.target && e.target.name) || this.state.contentSlug
      },
      () => {
        this.setState({ showContentDialog: !this.state.showContentDialog });
      }
    );
  };

  toggleContactForm = e => {
    if (e) e.preventDefault();
    this.setState({ showContactForm: !this.state.showContactForm });
  };

  hideFailedForm = () => {
    this.setState({ paymentFailed: false }, () => {
      this.props.history.push({
        pathname: '/subscription-agents',
        search: ''
      });
    });
    // maybe need to remove from history completely
  };

  disablePage = () => {
    // this.setState({
    //   isPageDisabled: true
    // });
  };

  onInput = e => {
    const newValue = {};
    newValue[e.target.name] = e.target.value;
    const newState = merge({}, this.state, newValue);
    this.setState(newState, () => {
      this.setState({
        isValidForm: this.isValidForm()
      });
    });
  };

  isValidForm = () => {
    const { code, street, city, st, postalCode } = this.state;
    return (
      code !== '' &&
      code.length >= 3 &&
      street !== '' &&
      city !== '' &&
      st !== '' &&
      postalCode !== '' &&
      postalCode.length >= 5
    );
  };

  getStates = () => {
    const states = Object.keys(USStates);
    return states.map(key => (
      <MenuItem key={key} value={key}>
        {key}
      </MenuItem>
    ));
  };

  updateSaasAgreement = async (payButtonClicked = false) => {
    const { pathname } = this.props.history.location;
    await api
      .put(`${API_ROOT}/user/saas/accept`)
      .then(() => {
        setTimeout(() => {
          this.props.actions.updatePerson();
        }, 0);
        // if (!payButtonClicked) {
        //   this.props.history.push(
        //     pathname === '/add-subscription-listing'
        //       ? '/'
        //       : '/subscription-complete'
        //   );
        // }
      })
      .catch(err => {
        console.log(err);
      });
  };

  renderAddressForm = () => (
    <div
      style={{
        maxWidth: 480,
        padding: '12px 24px',
        marginBottom: 24,
        //  background: 'rgba(0,0,0,.5)'
        background: '#474a6acc'
      }}
    >
      <h3 style={{ marginBottom: 6 }}>Please enter your code:</h3>
      <TextField
        id="code"
        fullWidth
        helperText=""
        label="Code"
        margin="dense"
        name="code"
        onChange={this.onInput}
        style={{ width: '100%' }}
        type="text"
        value={this.state.code}
        autoFocus
      />
      <h3 style={{ marginBottom: 6, marginTop: 24 }}>
        Please enter a valid billing address:
      </h3>
      <TextField
        id="street"
        fullWidth
        helperText=""
        label="Address"
        margin="dense"
        name="street"
        onChange={this.onInput}
        style={{ width: '100%' }}
        type="text"
        value={this.state.street}
      />
      <Grid container spacing={1} style={{ paddingBottom: 40 }}>
        <Grid item xs={12} sm={4}>
          <TextField
            id="city"
            helperText=""
            label="City"
            margin="dense"
            name="city"
            onChange={this.onInput}
            fullWidth
            // style={{ width: '40%', marginRight: '5%' }}
            type="text"
            value={this.state.city}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl
            style={{ display: 'block', width: '100%', marginTop: 5 }}
          >
            <InputLabel htmlFor="State">State</InputLabel>
            <Select
              fullWidth
              value={this.state.st}
              onChange={this.onInput}
              inputProps={{
                name: 'st',
                id: 'st'
              }}
            >
              {this.getStates()}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            id="postalCode"
            label="Zip"
            margin="dense"
            name="postalCode"
            onChange={this.onInput}
            // style={{ width: '25%', marginLeft: '5%' }}
            fullWidth
            type="text"
            value={this.state.postalCode}
          />
        </Grid>
      </Grid>
    </div>
  );

  render() {
    const { currentPerson, language } = this.props;
    const agentData = this.props.agentData ?? {}
    const { billingPayment, billingPlan, subData } = this.state;
    const isDisabled = billingPayment !== 'now' || billingPlan === '';
    const InvoiceMeButton = 'CONTINUE';
    const isTwoYear = subData[0]?.priceName.includes('Two-Year');
    return (
      <div>
        <div className="realm--lo-back">
          {/* <a
            href="javascript:history.back()"
          >CANCEL</a> */}
          <IconButton
            onClick={() => {
              window.history.back();
            }}
            aria-label="Close"
            style={{ margin: '0px 8px', backgroundColor: '#64626286' }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className="realm--product-plans">
          <h1>Payment Options</h1>
          <p className="realm--product-plans-lead text-centered">
            Please complete the information below to start your REALM
            membership.
          </p>
          <div className="realm--product-plans-selected">
            <h2>
              <span className="realm--bumper">Membership:</span>
              {subData[0]?.productName} {subData[0]?.type}
            </h2>
            <div className="realm--bumper-form-container">
              <h2>Plan Investment:</h2>
              <div className="realm--bumper-form">
                <RadioGroup
                  aria-label="Select billing plan"
                  name="billingPlan"
                  value={billingPlan}
                  onChange={this.handleSelection}
                >
                  <FormControlLabel
                    value={subData[0]?.priceName || 'REALM Agent Monthly'}
                    control={<Radio color="primary" style={{ height: 24 }} />}
                    label={`$${subData[0]?.price ||
                      '750'} billed ${subData[0]?.period.toLowerCase()}`}
                  />
                  {!isTwoYear && <FormControlLabel
                    value={subData[1]?.priceName || 'REALM Agent Annual'}
                    control={<Radio color="primary" style={{ height: 24 }} />}
                    label={`$${subData[1]?.price.toLocaleString('en-US') ||
                      '7,500'} billed ${subData[1]?.period.toLowerCase()}`}
                  />}
                  {/* <FormControlLabel
                    value="REALM Agent Daily"
                    control={<Radio color="primary" style={{ height: 24 }} />}
                    label="$5 billed daily"
                  /> */}
                </RadioGroup>
              </div>
            </div>
            <div className="realm--bumper-form-container">
              <h2>&nbsp;</h2>

              {/* **************** BILL ME LATER! *********************** */}
              <div className="realm--bumper-form">
                <RadioGroup
                  aria-label="Select billing plan"
                  name="billingPayment"
                  value={billingPayment}
                  onChange={this.handleSelection}
                >
                  <FormControlLabel
                    value="now"
                    control={<Radio color="primary" style={{ height: 24 }} />}
                    label={
                      <>
                        <span style={{ marginRight: 12 }}>Pay now</span>{' '}
                        <PaymentIcons />
                      </>
                    }
                  />
                </RadioGroup>
              </div>
            </div>

            <div className="realm--bumper-form-container">
              {billingPlan && billingPayment && <h2>Plan Terms:</h2>}
              <div className="realm--bumper-form">
                {billingPlan && billingPayment && (
                  <SubscriptionAgreement
                    userAgreementPdf={this.state.uagPdf}
                    toggleContentDialog={this.toggleContentDialog}
                    buttonName={
                      billingPayment === 'later' ? InvoiceMeButton : 'PAY NOW'
                    }
                  />
                )}
                {billingPayment === 'later' &&
                  (this.state.isLoading ? (
                    <div
                      style={{
                        width: 280,
                        textAlign: 'center'
                      }}
                    >
                      <div style={{ width: 32, margin: '0 auto' }}>
                        <LoadingIndicator style={{ marginLeft: -16 }} />
                      </div>
                    </div>
                  ) : (
                    <Button
                      style={{ width: 280, height: 46 }}
                      color="primary"
                      variant="contained"
                      disabled={billingPlan === '' || !this.state.isValidForm}
                      onClick={this.subscribe}
                    >
                      {InvoiceMeButton}
                    </Button>
                  ))}
                <div
                  role="dialog"
                  style={{
                    position: 'relative',
                    display: billingPayment === 'now' ? 'inline-block' : 'none',
                    opacity: isDisabled ? '.5' : '1',
                    pointerEvents: isDisabled ? 'none' : 'auto'
                  }}
                  onClick={this.disablePage}
                >
                  <form
                    action={`${API_ROOT}/subscriptions/stripe/create-session`}
                    method="POST"
                    onClick={() => this.updateSaasAgreement(true)}
                  >
                    <div
                      className="realm--stripe-dialog"
                      ref={this.stripeDialog}
                    />
                    <input
                      type="hidden"
                      name="subscriptionPlan"
                      value={billingPlan}
                    />
                    <input
                      type="hidden"
                      name="membershipId"
                      value={!this.props.createTeam ? agentData._id : ''}
                    />
                    <input
                      type="hidden"
                      name="personId"
                      value={currentPerson._id}
                    />
                    <input
                      type="hidden"
                      name="successRedirect"
                      value={`${window.location.protocol}//${window.location.host}/subscription-complete/?type=${subData[0]?.type}`}
                    />
                    <input
                      type="hidden"
                      name="failureRedirect"
                      value={`${window.location.protocol}//${window.location.host}/subscription-agents/?type=${subData[0]?.type}&paymentFailed=true`}
                    />
                    <button
                      style={{
                        background: 'rgb(71, 154, 204)',
                        border: 0,
                        color: '#fff',
                        cursor: 'pointer',
                        width: 280,
                        height: 46
                      }}
                      color="primary"
                      variant="contained"
                      type="submit"
                    >
                      PAY NOW
                    </button>
                  </form>
                </div>
              </div>
            </div>

            <h2>Verify Your Details:</h2>
            <p>
              <strong className="realm--bumper">Your Brokerage:</strong>
              {agentData && agentData.currentBrokerage
                ? agentData.currentBrokerage.name
                : 'No brokerage provided'}
            </p>
            <p>
              <strong className="realm--bumper">Your Name:</strong>
              {agentData ? agentData.fullName : 'No name provided'}
            </p>
            <p>
              <strong className="realm--bumper">Your Email:</strong>
              {currentPerson ? currentPerson.primaryEmail : 'No email provided'}
            </p>
            <p>
              <small>
                <a
                  onClick={this.toggleContactForm}
                  style={{ textDecoration: 'underline' }}
                  href="/"
                >
                  Need help? Contact the Concierge.
                </a>
              </small>
            </p>

            <div className="realm--subscription-actions">
              <p style={{ textAlign: 'center' }}>
                <small>
                  <a
                    onClick={this.toggleContentDialog}
                    style={{ textDecoration: 'underline' }}
                    name="marketing-company-product-plans-faqs"
                    href="/"
                  >
                    Membership FAQs
                  </a>
                </small>
              </p>
            </div>
          </div>
        </div>
        <div
          className="realm--click-blocker"
          style={{
            opacity: this.state.isPageDisabled ? 1 : 0,
            pointerEvents: this.state.isPageDisabled ? 'auto' : 'none'
          }}
        />
        <ContentDialog
          apiType="fullPost"
          language={language}
          onClose={this.toggleContentDialog}
          open={this.state.showContentDialog}
          slug={this.state.contentSlug}
        />
        <ContactHelp
          language={language}
          onClose={this.toggleContactForm}
          open={this.state.showContactForm}
        />
        <PaymentFailedDialog
          onClose={this.hideFailedForm}
          open={this.state.paymentFailed}
        />
        <ErrorDialog
          handleClose={() => {
            this.setState({ isError: false });
          }}
          message={
            <div>
              Your transaction could not be completed. Please either try again
              or contact a REALM representative directly at{' '}
              <a
                style={{ textDecoration: 'underline' }}
                href="mailto:concierge@realm-global.com"
              >
                concierge@realm-global.com
              </a>{' '}
              for further assistance. We apologize for the inconvenience.
            </div>
          }
          open={this.state.isError}
        />
      </div>
    );
  }
}

SubscriptionsStripeOnly.propTypes = {
  actions: PropTypes.object,
  agentData: PropTypes.object,
  currentPerson: PropTypes.object,
  hasSubscription: PropTypes.bool,
  hasSubscriptionExpired: PropTypes.bool,
  history: PropTypes.object,
  isRenewing: PropTypes.bool,
  language: PropTypes.string,
  push: PropTypes.func,
  subscriptionType: PropTypes.string
};

SubscriptionsStripeOnly.defaultProps = {
  language: 'en'
};

export default SubscriptionsStripeOnly;
