import {
  browserVersion,
  getUA,
  isAndroid,
  isWinPhone,
  isIOS,
  isIE,
  isMobile,
  isMobileOnly,
  isTablet,
  osVersion,
  osName
} from 'mobile-device-detect';
import * as storage from '../persistence/storage';
import hasStorage from '../utils/has-storage';
import createReducer from './create-reducer';
import {
  CLEAR_APPLICATION,
  RESIZE_START,
  RESIZE_END,
  SWITCH_CURRENCY,
  SWITCH_DIRECTION,
  SWITCH_LOCALE,
  SWITCH_MEASURE
} from '../actions/types';

// The initial state of the App
const initialState = {
  browserVersion,
  currency: !!storage.get('currency') || 'USD',
  getUA,
  isAndroid,
  isWinPhone,
  isIOS,
  isIE,
  isLocalStorageEnabled: hasStorage,
  isMobile,
  isMobileOnly,
  isTablet,
  isResizing: false,
  isRTL: !!storage.get('rtl') || false,
  isPushStateCompat: typeof window.history.pushState === 'function',
  isWindowActive: true,
  locale: storage.get('locale') || 'en',
  measure: storage.get('measure') || 'us',
  osVersion,
  osName,
  version: '1.0.0-beta'
};

const actionHandlers = {
  [RESIZE_START]: (_, action) => ({ isResizing: action.payload }),
  [RESIZE_END]: (_, action) => ({ isResizing: action.payload }),
  [SWITCH_CURRENCY]: (_, action) => ({ currency: action.payload }),
  [SWITCH_DIRECTION]: (_, action) => ({ isRTL: action.payload }),
  [SWITCH_LOCALE]: (_, action) => ({ locale: action.payload }),
  [SWITCH_MEASURE]: (_, action) => ({ measure: action.payload }),
  [CLEAR_APPLICATION]: () => ({
    locale: 'en',
    isRTL: false
  })
};

export default createReducer(initialState, actionHandlers);
