import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import store from './store';
import theme from './theme';
import App from './Application';
import { c10CacheBuster } from './utils/cacheBusting';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const MOUNT_NODE = document.getElementById('app');
const queryClient = new QueryClient();
c10CacheBuster();

serviceWorkerRegistration.register();

const Realm = () => (
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </MuiThemeProvider>
  </Provider>
);

ReactDOM.render(<Realm />, MOUNT_NODE);
