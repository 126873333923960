import { Collapse } from '@material-ui/core';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import classes from './Drawer.module.scss';

import { subscriptionTypes } from '../../constants';
import * as drawerActions from '../../actions/drawer';
import localstring from '../../services/Localization';
import getPage from '../../utils/getPage';
import eventTracking from '../../utils/event-tracking';

import DrawerItem from './DrawerItem';
import DashboardIcon from '../../images/icons/R_Icon_Dashboard.svg';
import MatchesIcon from '../../images/icons/R_Icon_Matches.svg';
import ClientsIcon from '../../images/icons/R_Icon_Clients.svg';
import PropertiesIcon from '../../images/icons/R_Icon_Properties.svg';
import CoOwnershipIcon from '../../images/icons/R_Icon_Co-Ownership.svg';
import NewDevelopmentsIcon from '../../images/icons/R_Icon_New_Developments.svg';
import MembersIcon from '../../images/icons/R_Icon_Members.svg';
import EventsIcon from '../../images/icons/R_Icon_Events.svg';
import InsightsIcon from '../../images/icons/R_Icon_Insights.svg';
import NetworkIcon from '../../images/icons/R_Icon_Network.svg';
import PartnersIcon from '../../images/icons/R_Icon_Partners.svg';
import ResourcesIcon from '../../images/icons/R_Icon_Resources.svg';
import EquiteIcon from '../../images/icons/R_Icon_Empower.svg';

export default function Drawer() {
  const dispatch = useDispatch();

  const { openDrawer, closeDrawer } = useMemo(
    () => (dispatch ? bindActionCreators(drawerActions, dispatch) : {}),
    [dispatch]
  );

  const hideConcierge = true;

  const isAdmin = useSelector(state => state.session.isAdmin);
  const isAgent = useSelector(state => state.session.isAgent);
  const isConcierge = useSelector(state => state.session.isConcierge);
  const isRealmAdmin = useSelector(state => state.session.isRealmAdmin);
  const isMobile = useSelector(state => state.application.isMobile);
  const isRealmExecutive = useSelector(state => state.session.isRealmExecutive);
  const location = useSelector(state => state.router.location.pathname);
  const isOpen = useSelector(state => state.drawer.isOpen);
  const isStuck = useSelector(state => state.drawer.isStuck);
  const limitedAccess = useSelector(
    state =>
      state.session.subscription?.membershipModel === subscriptionTypes.LISTINGS
  );

  const isCollapsedMenuVisible = isOpen || isStuck;

  const [state, setState] = useState({
    matching: false,
    admin: false,
    concierge: false,
    clients: false,
    network: false
  });

  const handleClick = useCallback(prop => {
    setState(previousState => ({
      matching: false,
      admin: false,
      concierge: false,
      clients: false,
      [prop]: !previousState[prop]
    }));
  }, []);

  useEffect(() => {
    if (isStuck || isMobile) {
      return;
    }

    const listener = event => {
      if (event.pageX > 235 && isOpen) {
        closeDrawer();
      } else if (event.pageX <= 60 && !isOpen) {
        openDrawer();
      }
    };

    document.documentElement.addEventListener('mousemove', listener);

    return () => {
      document.documentElement.removeEventListener('mousemove', listener);
    };
  }, [openDrawer, closeDrawer, isOpen, isStuck, isMobile]);

  return (
    <div
      className={classNames(
        classes.drawer,
        isOpen && classes.open,
        isStuck && classes.stuck,
        isMobile && classes.mobile
      )}
    >
      <div className={classes.overlay}>
        <div className={classes.inner}>
          {/* ************* DASHBOARD ******************** */}
          <Link to="/dashboard">
            <DrawerItem
              selected={location === '/dashboard'}
              icon={DashboardIcon}
            >
              {localstring.nav_menu_dashboard}
            </DrawerItem>
          </Link>
          {/* ************* MATCHING ******************** */}
          <DrawerItem
            onClick={() => handleClick('matching')}
            icon={MatchesIcon}
            isOpen={state.matching && isCollapsedMenuVisible}
          >
            {localstring.nav_menu_matching}
          </DrawerItem>
          <Collapse
            in={state.matching && isCollapsedMenuVisible}
            timeout="auto"
            unmountOnExit
          >
            <div className={classes.subMenu}>
              <Link to="/matching">
                <DrawerItem selected={location === '/matching'}>
                  {localstring.my_properties}
                </DrawerItem>
              </Link>
              <Link to={limitedAccess ? '#' : '/match-clients'}>
                <DrawerItem
                  disabled={limitedAccess}
                  showLock={limitedAccess}
                  selected={location === '/match-clients'}
                >
                  {localstring.my_matching_clients}
                </DrawerItem>
              </Link>
              <Link to={limitedAccess ? '#' : '/match-alerts'}>
                <DrawerItem
                  disabled={limitedAccess}
                  showLock={limitedAccess}
                  selected={location === '/match-alerts'}
                >
                  {localstring.my_matching_alerts}
                </DrawerItem>
              </Link>
            </div>
          </Collapse>
          {/* ************* CLIENTS ******************** */}
          {isAgent && (
            <>
              <DrawerItem
                isOpen={state.clients && isCollapsedMenuVisible}
                icon={ClientsIcon}
                onClick={() => handleClick('clients')}
              >
                Clients
              </DrawerItem>
              <Collapse
                in={state.clients && isCollapsedMenuVisible}
                timeout="auto"
                unmountOnExit
              >
                <div className={classes.subMenu}>
                  {!isAdmin && !isConcierge && (
                    <Link to={limitedAccess ? '#' : '/my-clients/list'}>
                      <DrawerItem
                        disabled={limitedAccess}
                        selected={location === '/my-clients/list'}
                      >
                        My Clients
                      </DrawerItem>
                    </Link>
                  )}
                  <Link
                    to={
                      limitedAccess ? '#' : '/wealth-events?groupByClient=true'
                    }
                  >
                    <DrawerItem
                      disabled={limitedAccess}
                      selected={location === '/wealth-events'}
                    >
                      Wealth Events
                    </DrawerItem>
                  </Link>
                </div>
              </Collapse>
            </>
          )}
          {/* ************* PROPERTIES ******************** */}
          <Link to="/properties/list">
            <DrawerItem
              selected={location === '/properties/list'}
              icon={PropertiesIcon}
            >
              {localstring.nav_menu_all_properties}
            </DrawerItem>
          </Link>
          <Link to="/co-ownership">
            <DrawerItem
              icon={CoOwnershipIcon}
              selected={location === '/co-ownership'}
            >
              {localstring.nav_menu_co_ownership}
            </DrawerItem>
          </Link>
          <Link to="/developments">
            <DrawerItem
              icon={NewDevelopmentsIcon}
              selected={location === '/developments'}
            >
              {localstring.nav_menu_developments}
            </DrawerItem>
          </Link>
          {/* ************* AGENTS ******************** */}
          <Link
            to={
              limitedAccess
                ? '#'
                : isAdmin
                ? '/agents?&status=active'
                : '/agents'
            }
          >
            <DrawerItem
              disabled={limitedAccess}
              showLock={limitedAccess}
              selected={location === '/agents'}
              icon={MembersIcon}
            >
              {localstring.nav_menu_agents}
            </DrawerItem>
          </Link>
          {/* ***************** Équité ***************** */}
          <Link to="/equite">
            <DrawerItem icon={EquiteIcon}  selected={location === '/equite'}>
                REALM Empower
            </DrawerItem>
          </Link>
          {/* <Link
            to={
              limitedAccess
                ? '#'
                : isAdmin
                ? '/agents-map?&status=active'
                : '/agents-map'
            }
          >
            <DrawerItem
              disabled={limitedAccess}
              showLock={limitedAccess}
              selected={location === '/agents-map'}
              icon={MembersIcon}
            >
              {localstring.nav_menu_agents} Map
            </DrawerItem>
          </Link> */}
          {/* ************* BROKERAGES ******************** */}
          {(isRealmExecutive || isConcierge || isAdmin) && (
            <Link to="/brokerages/list">
              <DrawerItem
                disabled={limitedAccess}
                showLock={limitedAccess}
                selected={location === '/brokerages/list'}
              >
                {localstring.nav_menu_brokerages}
              </DrawerItem>
            </Link>
          )}
          {/* ***************** EVENTS ***************** */}
          <Link to="/events">
            <DrawerItem icon={EventsIcon} selected={location === '/events'}>
              {localstring.nav_menu_events}
            </DrawerItem>
          </Link>
          {/* ************* Network ******************** */}
          <DrawerItem
            isOpen={state.network && isCollapsedMenuVisible}
            icon={NetworkIcon}
            onClick={() => handleClick('network')}
          >
            Network
          </DrawerItem>
          <Collapse
            in={state.network && isCollapsedMenuVisible}
            timeout="auto"
            unmountOnExit
          >
            <div className={classes.subMenu}>
              <Link to={limitedAccess ? '#' : '/dossier'}>
                <DrawerItem
                  disabled={limitedAccess}
                  showLock={limitedAccess}
                  selected={location === '/dossier'}
                >
                  {localstring.nav_menu_prospective_clients}
                </DrawerItem>
              </Link>
              {isAgent && (
                <Link to={limitedAccess ? '#' : '/company-connect'}>
                  <DrawerItem
                    disabled={limitedAccess}
                    showLock={limitedAccess}
                    selected={location === '/company-connect'}
                  >
                    Company Connect
                  </DrawerItem>
                </Link>
              )}
            </div>
          </Collapse>
          {/* ************* STRATEGIC PARTNERS ******************** */}
          <Link to={limitedAccess ? '#' : '/strategicPartners'}>
            <DrawerItem
              disabled={limitedAccess}
              showLock={limitedAccess}
              icon={PartnersIcon}
              selected={location === '/strategicPartners'}
              onClick={() =>
                eventTracking(
                  `${getPage()}-Nav`,
                  'Strategic Partners Click',
                  'Left Nav'
                )
              }
            >
              {localstring.nav_menu_strategic_partners}
            </DrawerItem>
          </Link>
          {/* ************* BRAND RESOURCES ******************** */}
          <Link to={limitedAccess ? '#' : '/brand'}>
            <DrawerItem
              disabled={limitedAccess}
              showLock={limitedAccess}
              icon={ResourcesIcon}
              selected={location === '/brand'}
            >
              {localstring.nav_menu_brand}
            </DrawerItem>
          </Link>
          {/* ************* CONCIERGE ******************** */}
          {!hideConcierge && (
            <>
              <DrawerItem
                isOpen={state.concierge && isCollapsedMenuVisible}
                icon={PartnersIcon}
                onClick={() => handleClick('concierge')}
              >
                {localstring.nav_menu_concierge}
              </DrawerItem>
              <Collapse
                in={state.concierge && isCollapsedMenuVisible}
                timeout="auto"
                unmountOnExit
              >
                <div className={classes.subMenu}>
                  <DrawerItem>
                    {localstring.nav_menu_concierge_item1}
                  </DrawerItem>
                  <DrawerItem>
                    {localstring.nav_menu_concierge_item2}
                  </DrawerItem>
                </div>
              </Collapse>
            </>
          )}
          {/* ************* REALM EXECUTIVE ******************** */}
          {(isRealmExecutive || isConcierge) && (
            <Link to="/usage">
              <DrawerItem icon={InsightsIcon} selected={location === '/usage'}>
                Recent Usage
              </DrawerItem>
            </Link>
          )}
          {/* ************* ADMIN ******************** */}
          {(isAdmin || isRealmAdmin) && (
            <>
              <DrawerItem
                isOpen={state.admin && isCollapsedMenuVisible}
                onClick={() => handleClick('admin')}
              >
                {localstring.nav_menu_administration}
              </DrawerItem>
              <Collapse
                in={state.admin && isCollapsedMenuVisible}
                timeout="auto"
                unmountOnExit
              >
                <div className={classes.subMenu}>
                  <Link to="/usage">
                    <DrawerItem selected={location === '/usage'}>
                      Recent Usage
                    </DrawerItem>
                  </Link>
                  <Link to="/manage-admins">
                    <DrawerItem selected={location === '/manage-admins'}>
                      {localstring.nav_menu_administration_item1}
                    </DrawerItem>
                  </Link>
                  <Link to="/manage-users">
                    <DrawerItem selected={location === '/manage-users'}>
                      {localstring.nav_menu_administration_item2}
                    </DrawerItem>
                  </Link>
                  <Link to="/admin/logs">
                    <DrawerItem selected={location === '/admin/logs'}>
                      {localstring.nav_menu_administration_item4}
                    </DrawerItem>
                  </Link>
                  <Link to="/admin/subscription-log">
                    <DrawerItem
                      selected={location === '/admin/subscription-log'}
                    >
                      Subscriptions Log
                    </DrawerItem>
                  </Link>
                  {isAdmin && (
                    <Link to="/admin/tools">
                      <DrawerItem selected={location === '/admin/tools'}>
                        Tools
                      </DrawerItem>
                    </Link>
                  )}
                </div>
              </Collapse>
            </>
          )}
          {!isOpen && !isStuck && (
            <div className={classes.more}>
              <div className={classes.dot} />
              <div className={classes.dot} />
              <div className={classes.dot} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
