import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash.isfunction';
import merge from 'lodash.merge';
import isEmpty from 'lodash.isempty';
import { API_ROOT } from '../../../constants';
import api from '../../../api';
import ContactHelp from '../dialogs/ContactHelp';
import RealmButton from '../components/RealmButton';

const SubscriptionsComplete = ({
  actions,
  currentPerson,
  history,
  language,
  subscription
}) => {
  const [showContactForm, setShowContactForm] = useState(false);

  const completeOnboarding = useCallback(async () => {
    const person = { ...currentPerson };
    const updatedDate = new Date().toISOString();
    const updatedUserAccount = merge(person.realmData.userAccount, {
      onBoardedAgentAt: updatedDate
    });
    const updatedPerson = merge(person, {
      realmData: {
        userAccount: updatedUserAccount
      }
    });
    await api
      .put(`${API_ROOT}/people/${person._id}`, updatedPerson)
      .then(response => {
        actions.updateOurPerson(response);
        actions.completeOnboarding();
      })
      .catch(err => {
        actions.rejectOurPerson(err);
      });
  }, [currentPerson, actions]);
  useEffect(() => {
    const updateSaasAgreement = async () => {
      await api
        .put(`${API_ROOT}/user/saas/accept`)
        .then(() => {
          actions.updatePerson();
          completeOnboarding();
        })
        .catch(err => {
          console.log(err);
        });
    };
    updateSaasAgreement();
    const el = document.getElementById('page-wrapper');
    if (el && isFunction(el.scrollTo)) {
      el.scrollTo(0, 0);
    }
  }, []);
  const toggleContactForm = e => {
    if (e) e.preventDefault();
    setShowContactForm(prevState => !prevState);
  };
  const goToHome = () => setTimeout(history.push('/'), 500);
  const goHome = async () => {
    try {
      if (isEmpty(subscription)) {
        const subscriptionResponse = await api.get(
          `${API_ROOT}/subscriptions/me`
        );
        if (subscriptionResponse?.data?.data?.data) {
          actions.updateSubscription(subscriptionResponse.data.data.data);
        }
      }
    } catch (err) {
      console.log(err);
    }
    actions.endPendingSubscription();
    goToHome();
  };
  return (
    <div className="realm--subscription">
      <h1
        className="realm-onboarding--title centered"
        style={{ paddingTop: 50, paddingBottom: 30 }}
      >
        {`${currentPerson.name.firstName.trim()}, thank you for joining REALM`}
      </h1>
      <div style={{ maxWidth: 800, margin: '0 auto' }}>
        <h2 className="realm-onboarding--welcome centered">
          Thank you for your payment. You will receive an order confirmation
          email shortly with your invoice and plan details. Click the button
          below to explore your matches and upload your clients.
        </h2>
        <p className="realm-onboarding--welcome centered">
          If you have any issues or questions, please{' '}
          <a
            onClick={toggleContactForm}
            style={{ textDecoration: 'underline' }}
            href="/"
          >
            contact us
          </a>{' '}
          for further assistance.
        </p>
      </div>
      <div className="realm-onboarding--actions">
        <RealmButton onClick={goHome} title="Get Started" />
      </div>
      <ContactHelp
        language={language}
        onClose={toggleContactForm}
        open={showContactForm}
      />
    </div>
  );
};
SubscriptionsComplete.propTypes = {
  actions: PropTypes.object,
  currentPerson: PropTypes.object,
  history: PropTypes.object,
  language: PropTypes.string
};
export default SubscriptionsComplete;
