import classNames from 'classnames';
import React, { memo } from 'react';

import classes from './LoadingIndicator.module.scss';

const LoadingIndicator = props => (
  <div>
    {props.message && (
      <div className={classes.container}>
        <span className={classNames(props.classes, classes.message)}>
          {props.message}
        </span>
      </div>
    )}
    <div className="realm--loading-indicator" style={props.style || undefined}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);

export default memo(LoadingIndicator);
