import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

import { API_ROOT } from '../../constants';
import api from '../../api';
import LoadingIndicator from '../LoadingIndicator';
import genericHouse from '../../images/img/estateblur2.jpg';
import AddListingDialog from '../Dialogs/AddListingDialog';
import AlertDialog from '../Dialogs/AlertDialog';

class ListingsOnlySelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availableListings: [],
      isLoading: true,
      selectedListings: [],
      manualListings: [],
      pricePerListing: 1100,
      totalPrice: 0,
      warnUserAfter: 4,
      over5WarningWasShown: false,
      displayOver5Warning: false,
      isEditing: false,
      openListingDialog: false,
      propertyToEdit: null,
      totalAvailableListings: 0,
      existingSubscription: false
    };
  }

  componentDidMount() {
    this.getListings(false);
  }

  getListings = async preserveSelected => {
    try {
      this.setState({ isLoading: true });
      const response = await api.get(
        `${API_ROOT}/listings/listing-only-listings/${this.props.agentData._id}`
      );
      const { subscribedListings, availableListings } = response.data.data;
      const { selectedListings, manualListings } = this.state;
      const existingSubscription =
        subscribedListings && subscribedListings.length > 0
          ? subscribedListings[0].itemsForSale._id
          : false;

      let { displayOver5Warning, over5WarningWasShown } = this.state;
      if (preserveSelected) {
        availableListings.forEach(updatedListing => {
          if (
            updatedListing.source === 'manual' &&
            !manualListings.includes(updatedListing._id)
          ) {
            // a manual listing.. if it's not in the current list of manual listings... set it to selected
            // then put it in the manual listing list
            updatedListing.selected = true;
            selectedListings.push(updatedListing);
            manualListings.push(updatedListing._id);
          }
        });

        this.state.availableListings.forEach(stateListing => {
          availableListings.forEach(updatedListing => {
            if (
              stateListing._id === updatedListing._id &&
              stateListing.selected === true
            ) {
              updatedListing.selected = true;
            }
          });
        });

        // see if we need to warn the user about too many properties!
        const totalListings = (subscribedListings && subscribedListings.length > 0) ? subscribedListings.length + selectedListings.length : selectedListings.length;
        if (totalListings > this.state.warnUserAfter && !this.state.over5WarningWasShown) {
          over5WarningWasShown = true;
          displayOver5Warning = true;
        }

      } else {
        // first read in..
        // see if this is an existing subscription

        // put all manual listings in the list!

        availableListings.forEach(listing => {
          if (listing.source === 'manual') {
            manualListings.push(listing._id);
          }
        });
      }

      let totalAvailableListings = 0;
      availableListings.forEach(listing => {
        if (listing.selected === false) totalAvailableListings++;
      });

      this.setState(
        {
          isLoading: false,
          subscribedListings,
          selectedListings,
          availableListings,
          manualListings,
          totalAvailableListings,
          totalPrice: this.state.pricePerListing * selectedListings.length,
          existingSubscription,
          displayOver5Warning,
          over5WarningWasShown,
        },
        () => {
          if (this.props.callback) {
            this.props.callback(
              this.state.totalPrice,
              selectedListings,
              this.state.existingSubscription,
              this.state.manualListings
            );
          }
        }
      );
    } catch (e) {
      this.setState({ isLoading: false });
    }
    return null;
  };

  createListingClose = isEditing => {
    !isEditing && this.getListings(true);
    this.setState(
      {
        openListingDialog: false
      },
      () => {
        setTimeout(() => {
          this.setState({
            isEditing: false,
            propertyToEdit: null
          });
        }, 250);
      }
    );
  };

  selectListing = (listingId, index) => {
    const { availableListings, selectedListings, manualListings,subscribedListings } = this.state;

    // 1) make the selected availableListing selected
    availableListings[index].selected = true; // now won't show up
    if (availableListings[index].archived !== null) {
      manualListings.push(availableListings[index]._id);
    }

    // 2) move a copy to the selectedListings
    selectedListings.push(availableListings[index]);

    // 3) calculate total number of listings
    let totalAvailableListings = 0;
    availableListings.forEach(listing => {
      if (listing.selected === false) totalAvailableListings++;
    });

    // 4) store the data while calculating the price
    this.setState(
      {
        availableListings,
        selectedListings,
        manualListings,
        totalAvailableListings,
        totalPrice: this.state.pricePerListing * selectedListings.length
      },
      () => {
        if (this.props.callback) {
          this.props.callback(
            this.state.totalPrice,
            selectedListings,
            this.state.existingSubscription,
            this.state.manualListings
          );
        }
      }
    );
    const totalListings = (subscribedListings && subscribedListings.length > 0) ? subscribedListings.length + selectedListings.length : selectedListings.length;
    if (totalListings > this.state.warnUserAfter && !this.state.over5WarningWasShown) {
      // alert(
      //   'Please contact the REALM Concierge to discus a Full Access Membership as you may be eligible for additional features and access for a smaller investment.'
      // );
      this.setState({
        displayOver5Warning: true,
        over5WarningWasShown: true });
    }
  };

  removeListing = (listingId, index) => {
    const { availableListings, selectedListings, manualListings } = this.state;

    // 1) remove a copy to the selectedListings
    selectedListings.splice(index, 1);
    const mIndex = manualListings.indexOf(listingId);
    if (mIndex) {
      manualListings.splice(mIndex, 1);
    }

    // 2) make the selected availableListing UNselected
    availableListings.forEach(listing => {
      if (listing._id === listingId) {
        listing.selected = false;
      }
    });

    // 3) calculate the price
    this.setState(
      {
        availableListings,
        selectedListings,
        manualListings,
        totalPrice: this.state.pricePerListing * selectedListings.length
      },
      () => {
        if (this.props.callback) {
          this.props.callback(
            this.state.totalPrice,
            selectedListings,
            this.state.existingSubscription,
            this.state.manualListings
          );
        }
      }
    );
  };

  render() {
    const {
      availableListings,
      selectedListings,
      subscribedListings,
      totalAvailableListings,
    } = this.state;
    const { tags } = this.props;
    const agentData = this.props.agentData ?? {person : {}, currentBrokerage : {}}

    return (
      <div className="realm--lo-container">
        {this.state.isLoading ? (
          <LoadingIndicator message="Looking for available listings..." />
        ) : (
          <div>
            {subscribedListings && subscribedListings.length > 0 && (
              <>
                <h3>Currently Subscribed Listings</h3>
                <div className="realm--lo-section">
                  {subscribedListings.map(listing => (
                    <>
                      {!listing.selected && (
                        <div className="realm--lo-listing">
                          <div className="realm--lo-label">SUBSCRIBED:</div>
                          {listing.thumbnail.photoUrl}
                          <img
                            src={listing.thumbnail || genericHouse}
                            style={{ width: '50px' }}
                            className="realm--lo-img"
                            alt={`Property ${listing.fullAddress}`}
                          />
                          {listing.fullAddress}
                        </div>
                      )}
                    </>
                  ))}
                </div>
                <hr className="realm--lo-hr" />
              </>
            )}

            <div className="realm--lo-section">
              {selectedListings.length > 0 && (
                <div className="realm--lo-sub-title">Selected Listings:</div>
              )}
              {selectedListings.map((listing, index) => (
                <div className="realm--lo-listing">
                  <div className="realm--lo-label">
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      className="btn-xs"
                      onClick={() => this.removeListing(listing._id, index)}
                    >
                      Remove
                    </Button>
                  </div>
                  {listing.thumbnail.photoUrl}
                  <img
                    src={listing.thumbnail || genericHouse}
                    className="realm--lo-img"
                    style={{ width: '50px' }}
                    alt={`Property ${listing.fullAddress}`}
                  />
                  {listing.fullAddress}
                </div>
              ))}
              {selectedListings.length > 0 && <hr className="realm--lo-hr" />}
              <div className="realm--lo-sub-title">
                {
                  availableListings > 0 && (
                    <>Available Listings</>
                  )
                }
                {(subscribedListings + totalAvailableListings) === 0 &&
                (
                  <>We did not find any active listings for you in REALM. Click ADD A LISTING below to get started.</>
                )
                }
              </div>
              {availableListings.map((listing, index) => (
                <>
                  {!listing.selected && (
                    <div className="realm--lo-listing">
                      <div className="realm--lo-label">
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          className="btn-xs"
                          onClick={() => this.selectListing(listing._id, index)}
                        >
                          Select
                        </Button>
                      </div>
                      {listing.thumbnail.photoUrl}
                      <img
                        src={listing.thumbnail || genericHouse}
                        style={{ width: '50px' }}
                        className="realm--lo-img"
                        alt={`Property ${listing.fullAddress}`}
                      />
                      {listing.fullAddress}
                    </div>
                  )}
                </>
              ))}

              <Button
                style={{ marginLeft: -20 }}
                variant={`${
                  this.state.totalAvailableListings === 0 ? 'contained' : ''
                }`}
                onClick={() => {
                  this.setState({ openListingDialog: true });
                }}
              >
                Add A Listing
              </Button>
              <AddListingDialog
                agentId={agentData._id}
                brokerage={agentData.currentBrokerage || null}
                handleClose={this.createListingClose}
                isEditing={this.state.isEditing}
                tagsList={tags}
                propertyData={this.state.propertyToEdit}
                // isMobile={this.props.isMobile}
                // language={locale}
                personId={agentData.person._id}
                open={this.state.openListingDialog}
                isListingsOnly
                archived={`LOProp-${new Date().toISOString()}`}
              />
            </div>
          </div>
        )}
        <AlertDialog
          aria-describedby="error-dialog"
          open={this.state.displayOver5Warning}
          message={`The REALM Agent Membership starts at $5,000 with annual billing. With an Agent Membership, you will receive unlimited listings and all of the other benefits REALM has to offer. For more information, please contact the REALM Concierge.`}
          title={`From the Concierge:`}
          actionsMap={[
            {
              color: 'primary',
              onClick: () => {
                this.setState({ displayOver5Warning: false });
              },
              text: 'OK'
            }
          ]}
        />
      </div>
    );
  }
}

ListingsOnlySelector.propTypes = {
  agentData: PropTypes.object,
  callback: PropTypes.func
};

export default ListingsOnlySelector;
