import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ContactHelp from '../dialogs/ContactHelp';
import RealmButton from '../components/RealmButton';
import api from '../../../api';
import eventTracking from '../../../utils/event-tracking';
import AlertDialog from '../../../components/Dialogs/AlertDialog';
import ErrorDialog from '../../../components/Dialogs/ErrorDialog';
import { getPageParameters } from '../../../utils/page-parameters';

const OverduePayment = ({ location, history, language, session, actions }) => {
  const { subscription, currentPerson } = session;
  const [showContactForm, setShowContactForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const handleStripeCC = async () => {
    eventTracking(
      'overdue-payment',
      'Update Credit Card',
      subscription?.stripeData?.subscription
    );
    try {
      const response = await api.post('subscriptions/stripe/update-payment', {
        subscription: subscription.stripeData.subscription,
        successRedirect: `http://${window.location.host}/collect-payment?updatedCard=true`,
        cancelRedirect: `http://${window.location.host}/collect-payment`
      });
      window.location.replace(response.data);
    } catch (err) {
      console.log(err);
      setErrorMessage(err.message);
      eventTracking(
        'overdue-payment',
        'Update Credit Card Failure',
        subscription?.stripeData?.subscription
      );
    }
  };
  const toggleContactForm = e => {
    if (e) e.preventDefault();
    setShowContactForm(prevState => !prevState);
  };
  const handleCloseSuccess = () => {
    actions.clearPastDue();
    setShowSuccessModal(false);
    history.push('/');
  };
  useEffect(() => {
    const { updatedCard } = getPageParameters(location.search);
    // From checkout session success
    if (updatedCard === true) {
      eventTracking(
        'overdue-payment',
        'Update Credit Card Success',
        subscription?.stripeData?.subscription
      );
      setShowSuccessModal(true);
    }
  }, []);
  const renderBillingAdminMessage = () => (
    <div className="realm--subscription">
      <h1
        className="realm-onboarding--title centered"
        style={{ paddingTop: 50, paddingBottom: 30 }}
      >
        Update Your Payment
      </h1>
      <div style={{ maxWidth: 800, margin: '0 auto' }}>
        <h2 className="realm-onboarding--welcome centered">
          Our records indicate that your last payment failed.
        </h2>
        <p className="realm-onboarding--welcome centered">
          Please update your payment details or{' '}
          <a
            onClick={toggleContactForm}
            style={{ textDecoration: 'underline' }}
            href="/"
          >
            contact us
          </a>{' '}
          for further assistance.
        </p>
      </div>
      <div className="realm-onboarding--actions">
        <RealmButton onClick={handleStripeCC} title="Update Payment Method" />
      </div>
    </div>
  );
  const isTeamMember =
    subscription?.membershipModel === 'Team' &&
    subscription?.billingAdmins[0] !== currentPerson._id;
  const renderTeamMemberMessage = () => (
    <div className="realm--subscription">
      <h1
        className="realm-onboarding--title centered"
        style={{ paddingTop: 50, paddingBottom: 30 }}
      >
        Your Account Needs to be Updated
      </h1>
      <div style={{ maxWidth: 800, margin: '0 auto' }}>
        <h2 className="realm-onboarding--welcome centered">
          Our records indicate the Billing Administrator for this team needs to
          update the payment details for this subscription.
        </h2>
        <p className="realm-onboarding--welcome centered">
          Please contact them directly and ask them to sign in to REALM so they
          can update the payment details for this account. If you do not know
          who the Billing Administrator is for your team, please{' '}
          <a
            onClick={toggleContactForm}
            style={{ textDecoration: 'underline' }}
            href="/"
          >
            contact us
          </a>{' '}
          for further assistance.
        </p>
      </div>
    </div>
  );
  return (
    <>
      {isTeamMember ? renderTeamMemberMessage() : renderBillingAdminMessage()}
      <ContactHelp
        language={language}
        onClose={toggleContactForm}
        open={showContactForm}
      />
      <ErrorDialog
        handleClose={() => setErrorMessage('')}
        message={errorMessage}
        open={!!errorMessage}
      />
      <AlertDialog
        describedby="updat-details"
        labelledby="update-success"
        title={'Success'}
        open={showSuccessModal}
        actionsMap={[
          {
            color: 'primary',
            onClick: handleCloseSuccess,
            text: 'Close',
            variant: 'contained'
          }
        ]}
      >
        <h3 id="update-success">
          Your Credit Card Details Were Successfully Updated
        </h3>
        <p>
          Any outstanding invoices will be processed shortly. Please check your
          inbox for payment receipts.
        </p>
      </AlertDialog>
    </>
  );
};
OverduePayment.propTypes = {
  actions: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  language: PropTypes.string,
  session: PropTypes.object,
};
export default OverduePayment;
