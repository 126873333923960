import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash.isfunction';
import { Button, Grid } from '@material-ui/core';
import ContentDialog from '../../../components/Dialogs/ContentDialog';
import ContactUs from '../dialogs/ContactUs';
import getParameterByName from '../../../utils/get-parameter-by-name';
import agentAvatar from '../../../images/img/avatar-portrait.png';
import listingAvatar from '../../../images/img/avatar-listing.png';
import api from '../../../api';
import AlertDialog from '../../../components/Dialogs/AlertDialog';
import localstring from '../../../services/Localization';

class SubscriptionsOptions extends Component {
  constructor(props) {
    super(props);
    const showListingOnly = getParameterByName('showLO') === 'true' || null;
    this.state = {
      showContactForm: false,
      showContentDialog: false,
      showManualToStripeDialog: false,
      showListingOnly,
      subData: []
    };
  }

  async componentDidMount() {
    const el = document.getElementById('page-wrapper');
    if (el && isFunction(el.scrollTo)) {
      el.scrollTo(0, 0);
    }
    if(this.props.currentPerson.realmData.convertAccount?.status === 'S') this.setState({showManualToStripeDialog: true});
    //Team Member that doesn't have a sub means they are in conversion
    if(this.props.isAgent && this.props.isTeamMemberSub && !this.props.hasSubscription) this.setState({showManualToStripeDialog: true});
    if (!this.props.isRenewing && this.props.hasSubscription) {
      this.props.history.push('/subscription-complete');
    }
    try {
      const response = await api.get('/subscriptions/agent/pricing');
      this.setState({ subData: response.data.data });
    } catch (err) {
      alert(err.response.data.msg);
    }
  }

  toggleContactForm = e => {
    if (e) e.preventDefault();
    this.setState({ showContactForm: !this.state.showContactForm });
  };

  toggleContentDialog = e => {
    if (e) e.preventDefault();
    this.setState({ showContentDialog: !this.state.showContentDialog });
  };

  onPurchase = e => {
    const { push } = this.props.history;
    switch (e.currentTarget.name) {
      case 'agent':
        push('/subscription-agents');
        break;
      case 'team':
        push('/subscription-teams');
        break;
      case 'listing':
        push('/subscription-listing');
        break;
      default:
        return null;
    }
    return null;
  };

  /* DEPRECIATED LISTING ONLY PLAN DESCRIPTION */
  
  // renderListingsOnlyPlan() {
  //   return (
  //     <>
  //       <div className="realm--product-separator"> </div>
  //       <div className="realm--product-plan listings-only">
  //         <div className="realm--product-plan-header">
  //           <h3>
  //             <div
  //               style={{ width: 48, marginRight: 14, display: 'inline-block' }}
  //             >
  //               <img src={listingAvatar} width="100%" alt="" />
  //             </div>
  //             Listings Only
  //           </h3>
  //           <div className="realm--product-price" style={{ marginBottom: 34 }}>
  //             <span>$1,100</span> per listing
  //             <small>1+ Agents</small>
  //           </div>
  //           <Button
  //             name="listing"
  //             fullWidth
  //             color="primary"
  //             variant="contained"
  //             onClick={this.onPurchase}
  //           >
  //             Select
  //           </Button>
  //         </div>
  //         <div className="realm--product-plan-info">
  //           <p>
  //             <strong>
  //               Pay per listing.
  //               <br />
  //               No annual commitment.
  //             </strong>
  //           </p>
  //           <p>Access to REALM intelligence</p>
  //           <p>Access to insights from REALM Founding Partners</p>
  //           <p>Access to REALM events</p>
  //           <p>Access to proprietary content and analytics</p>
  //           <p>Data feed integration</p>
  //           <p>Concierge-level customer support</p>
  //         </div>
  //         <div className="realm--product-plan-actions">
  //           <Button
  //             name="listing"
  //             fullWidth
  //             color="primary"
  //             variant="contained"
  //             onClick={this.onPurchase}
  //           >
  //             Select
  //           </Button>
  //         </div>
  //       </div>
  //     </>
  //   );
  // }

  renderPlan() {
    const { subData } = this.state;
    const isTeam = subData[0]?.type === 'Team';
    const isTwoYear = subData[0]?.priceName.includes('Two-Year');
    return (
      <>
        <div className="realm--product-plan">
          <div className="realm--product-plan-header">
            <h3>
              <div
                style={{ width: 48, marginRight: 14, display: 'inline-block' }}
              >
                <img src={agentAvatar} width="100%" alt="" />
              </div>
              REALM {isTeam ? 'Team' : 'Member'}
            </h3>
            <div className="realm--product-price">
              <span>{isTwoYear ? 'Payment Option' : 'Payment Options'}</span>
            </div>
            {isTwoYear ? <p>
              Monthly installments of ${subData[0]?.price || '500'}.
            </p>
            :
            <p>
              ${subData[0]?.price || '750'} per month OR $
              {subData[1]?.price
                ? subData[1]?.price.toLocaleString('en-US')
                : '7,500'}{' '}
              with annual billing.
            </p>}
          </div>
          {/* <div className="realm--product-plan-info">
            <p>
              <strong>
                Sized for {isTeam ? 'teams of 2 or more members' : '1 member'}.
              </strong>
            </p>
            <p>Access to REALM intelligence</p>
            <p>Access to REALM member network</p>
            <p>Access to insights from REALM Founding Partners</p>
            <p>Access to REALM events</p>
            <p>Unlimited listings</p>
            <p>Access to client information from proprietary database</p>
            {isTeam ? (
              <p>Unlimited profiles in a common database of clients</p>
            ) : (
              <p>Discounted membership for multiple users</p>
            )}
            <p>Unlimited clients &amp; client profiles</p>
            <p>Unlimited qualified buyer matches</p>
            <p>Access to proprietary content and analytics</p>
            <p>Data feed integration</p>
            <p>Concierge-level customer support</p>
            {isTeam && <p>Discounted membership for multiple users</p>}
          </div> */}
          <div className="realm--product-plan-actions">
            <Button
              color="primary"
              disabled={this.state.showListingOnly}
              fullWidth
              name={isTeam ? 'team' : 'agent'}
              onClick={this.onPurchase}
              variant="contained"
            >
              Continue
            </Button>
          </div>
        </div>
      </>
    );
  }
  renderManualToStripeMessage = () => {
    const { convertAccount } = this.props.currentPerson.realmData || '';
    const showCredit = Boolean(convertAccount?.freeTrialAnnual && (convertAccount.freeTrialAnnual >  0 || convertAccount.freeTrialMonthly >  0))
    if( this.props.isTeamMemberSub && !this.props.isTeamLeadSub ) return <p>Your Team Lead is converting from manual invoicing to automatic payment, but has not completed the setup process. You will be able to sign in once your Team Lead has submitted new billing information and renewed your subscription</p>;

      return (
        <>
        <p>Your billing has been changed from manual invoicing to automatic payment via your credit card. You will need to select your preferred payment option (monthly/annual billing) and enter your credit card information.</p>
        {showCredit && 
          <p>Your Manual subscription has time left that will be applied as {convertAccount.freeTrialMonthly} days for a monthly subscription and {convertAccount.freeTrialAnnual} days for an annual subscription</p>}
        </>
      )
  }

  renderConversionTitle = () => {
    if(this.props.isTeamLeadSub) return "Update Payment"
    return "Your Subscription Has Lapsed";
  }

  closeManualToStripeModal = () => {
    this.setState({
      showManualToStripeDialog: false
    })
    if (this.props.isTeamMemberSub && !this.props.isTeamLeadSub) this.props.history.push('/logout')
  }

  render() {
    const { subData } = this.state;
    return (
      <div>
        <div className="realm--product-plans">
          <h1>{subData[0]?.productName || 'REALM Membership plans'}</h1>
          <p className="realm--product-plans-lead text-centered">
            Pricing shown in USD
          </p>
          <Grid
            container
            spacing={4}
            className="realm--product-plans-container"
            style={{ margin: '0px auto' }}
          >
            <Grid item xs={12} md={this.state.showListingOnly ? 4 : 4}>
              {this.state.showListingOnly ? (
                <div className="realm--product-plans-row">
                  {this.renderPlan()}
                </div>
              ) : (
                <>{this.renderPlan()}</>
              )}
            </Grid>
            {/* <p
              className="realm--product-plans-lead text-centered"
              style={{ width: '100%' }}
            >
              <a
                href="/"
                onClick={this.toggleContentDialog}
                style={{ textDecoration: 'underline' }}
              >
                Membership FAQs
              </a>
            </p> */}
          </Grid>
        </div>
        <ContactUs
          language={this.props.language}
          onClose={this.toggleContactForm}
          open={this.state.showContactForm}
          onSubmit={() => {
            this.props.history.push({
              pathname: '/subscription-complete',
              search: 'type=Team&ts=L'
            });
          }}
        />
        <ContentDialog
          apiType="fullPost"
          language={this.props.language}
          onClose={this.toggleContentDialog}
          open={this.state.showContentDialog}
          slug="marketing-company-product-plans-faqs"
        />
        <AlertDialog 
          aria-describedby="manual-to-stripe-dialog"
          open={this.state.showManualToStripeDialog}
          title={this.renderConversionTitle()}
          actionsMap={[
            {
              color: 'primary',
              onClick: this.closeManualToStripeModal,
              text: this.props.isTeamMemberSub && !this.props.isTeamLeadSub ? localstring.close : localstring.continue 
            }
          ]}>
            {this.renderManualToStripeMessage()}
          </AlertDialog>
      </div>
    );
  }
}

SubscriptionsOptions.propTypes = {
  hasSubscription: PropTypes.bool,
  history: PropTypes.object,
  isRenewing: PropTypes.bool,
  language: PropTypes.string
};

SubscriptionsOptions.defaultProps = {
  language: 'en'
};

export default SubscriptionsOptions;
