// Application
export const DEFAULT_LOCALE = 'en';
export const RESIZE_START = 'RESIZE_START';
export const RESIZE_END = 'RESIZE_END';
export const SCROLLING_START = 'SCROLLING_START';
export const SCROLLING_END = 'SCROLLING_END';
export const SWITCH_CURRENCY = 'SWITCH_CURRENCY';
export const SWITCH_DIRECTION = 'SWITCH_DIRECTION';
export const SWITCH_LOCALE = 'SWITCH_LOCALE';
export const SWITCH_MEASURE = 'SWITCH_MEASURE';
export const WINDOW_ACTIVE = 'WINDOW_ACTIVE';
export const WINDOW_INACTIVE = 'WINDOW_INACTIVE';

// Session
export const ACCEPT_COOKIES = 'ACCEPT_COOKIES';
export const AUTHENTICATE_PENDING = 'AUTHENTICATE_PENDING';
export const AUTHENTICATE_SUCCESS = 'AUTHENTICATE_SUCCESS';
export const AUTHENTICATE_REJECTED = 'AUTHENTICATE_REJECTED';
export const AUTHENTICATE_RETRY = 'AUTHENTICATE_RETRY';
export const CLEAR_APPLICATION = 'CLEAR_APPLICATION';
export const CLEAR_SESSION = 'CLEAR_SESSION';
export const CLEAR_PASTDUE_PAYMENT = 'CLEAR_PASTDUE_PAYMENT';
export const COMPLETE_ONBOARDING = 'COMPLETE_ONBOARDING';
export const COMPLETE_SUBSCRIBING = 'COMPLETE_SUBSCRIBING';
export const FETCH_AGENT_PENDING = 'FETCH_AGENT_PENDING';
export const FETCH_AGENT_SUCCESS = 'FETCH_AGENT_SUCCESS';
export const FETCH_AGENT_REJECTED = 'FETCH_AGENT_REJECTED';
export const FETCH_AMITY_TOKEN = 'FETCH_AMITY_TOKEN';
export const FETCH_BROKERAGES_PENDING = 'FETCH_BROKERAGES_PENDING';
export const FETCH_BROKERAGES_SUCCESS = 'FETCH_BROKERAGES_SUCCESS';
export const FETCH_BROKERAGES_REJECTED = 'FETCH_BROKERAGES_REJECTED';
export const FETCH_LIFESTYLES_PENDING = 'FETCH_LIFESTYLES_PENDING';
export const FETCH_LIFESTYLES_SUCCESS = 'FETCH_LIFESTYLES_SUCCESS';
export const FETCH_LIFESTYLES_REJECTED = 'FETCH_LIFESTYLES_REJECTED';
export const FETCH_PERSON_PENDING = 'FETCH_PERSON_PENDING';
export const FETCH_PERSON_SUCCESS = 'FETCH_PERSON_SUCCESS';
export const FETCH_PERSON_REJECTED = 'FETCH_PERSON_REJECTED';

export const FETCH_SETTINGS_PENDING = 'FETCH_SETTINGS_PENDING';
export const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS';
export const FETCH_SETTINGS_REJECTED = 'FETCH_SETTINGS_REJECTED';

export const FETCH_SEARCH_PENDING = 'FETCH_SEARCH_PENDING';
export const FETCH_SEARCH_SUCCESS = 'FETCH_SEARCH_SUCCESS';
export const FETCH_SEARCH_REJECTED = 'FETCH_SEARCH_REJECTED';

export const FETCH_DASHBOARD_PENDING = 'FETCH_DASHBOARD_PENDING';
export const FETCH_DASHBOARD_SUCCESS = 'FETCH_DASHBOARD_SUCCESS';
export const FETCH_DASHBOARD_REJECTED = 'FETCH_DASHBOARD_REJECTED';
export const NEXT_DASHBOARD = 'NEXT_DASHBOARD';

export const CHECK_SUBSCRIPTION = 'CHECK_SUBSCRIPTION';
export const CHECK_TRIAL_SUBSCRIPTION = 'CHECK_TRIAL_SUBSCRIPTION';
export const END_PENDING_SUBSCRIPTION = 'END_PENDING_SUBSCRIPTION';
export const REONBOARD = 'REONBOARD';
export const SET_PENDING_SUBSCRIPTION = 'SET_PENDING_SUBSCRIPTION';
export const SUBSCRIPTION_EXPIRED = 'SUBSCRIPTION_EXPIRED';
export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION';

export const UPDATE_AGENT_PENDING = 'UPDATE_AGENT_PENDING';
export const UPDATE_AGENT_SUCCESS = 'UPDATE_AGENT_SUCCESS';
export const UPDATE_AGENT_REJECTED = 'UPDATE_AGENT_REJECTED';
export const UPDATE_BROKERAGE_PENDING = 'UPDATE_BROKERAGE_PENDING';
export const UPDATE_BROKERAGE_SUCCESS = 'UPDATE_BROKERAGE_SUCCESS';
export const UPDATE_BROKERAGE_REJECTED = 'UPDATE_BROKERAGE_REJECTED';
export const UPDATE_PERSON_PENDING = 'UPDATE_PERSON_PENDING';
export const UPDATE_PERSON_SUCCESS = 'UPDATE_PERSON_SUCCESS';
export const UPDATE_PERSON_REJECTED = 'UPDATE_PERSON_REJECTED';
export const UPDATE_TAGS_PENDING = 'UPDATE_TAGS_PENDING';
export const UPDATE_TAGS_SUCCESS = 'UPDATE_TAGS_SUCCESS';
export const UPDATE_TAGS_REJECTED = 'UPDATE_TAGS_REJECTED';
export const UPDATE_PLACE = 'UPDATE_PLACE'

export const UPDATE_CLIENT_FILTERS = 'UPDATE_CLIENT_FILTERS';
export const SET_GROUP = 'SET_GROUP';
export const OPEN_REFERRAL_DIALOG = 'OPEN_REFERRAL_DIALOG';

// Drawer
export const OPEN_DRAWER = 'OPEN_DRAWER';
export const CLOSE_DRAWER = 'CLOSE_DRAWER';
export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const STICK_DRAWER = 'STICK_DRAWER';
export const UNSTICK_DRAWER = 'UNSTICK_DRAWER';
export const TOGGLE_DRAWER_STICK = 'TOGGLE_DRAWER_STICK';

// Properties
export const SET_PROPERTY_IDS = 'SET_PROPERTY_IDS';
export const SET_FILTERS = 'SET_FILTERS';