/**
 * Return string containing numbers to a number with commas
 *
 * @param {x} incoming string to be transformed and returned
 * @returns {string}
 */
export default function numberWithCommas(x) {
  try {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } catch (err) {
    return '';
  }
}
