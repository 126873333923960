import isEmpty from 'lodash.isempty';
import createReducer from './create-reducer';
import * as storage from '../persistence/storage';
import * as types from '../actions/types';
import getRoles from '../utils/get-roles';

function hasSubscription(record) {
  const isRecord = Array.isArray(record) && record.length !== 0;
  let hasRecordICanUse = 'no';
  if (isRecord) {
    record.map((r, i) => {
      if (!isEmpty(r)) {
        hasRecordICanUse = i;
      }
      return null;
    });
  }
  return hasRecordICanUse;
}

function getSubscriptionRecord(record) {
  const possibleIndex = hasSubscription(record);
  return possibleIndex !== 'no' ? record[possibleIndex] : {};
}

// The initial state of the App
const initialState = {
  acceptCookies: JSON.parse(storage.get('acceptCookies')) || false,
  agentData: JSON.parse(storage.get('agentData')) || null,
  amityToken: storage.get('amityToken') || null,
  apiError: false,
  authenticationFailed: false,
  avatar: storage.get('avatar') || '',
  brokerages: JSON.parse(storage.get('brokerages')) || null,
  currentPerson: JSON.parse(storage.get('person')) || null,
  currentUser: JSON.parse(storage.get('me')) || null,
  dashboardSettings: JSON.parse(storage.get('dashboardSettings')) || null,
  dashboardRotation: JSON.parse(storage.get('dashboardRotation')) || 0,
  errorMessage: '',
  hasCancelledSubscription:
    storage.get('hasCancelledSubscription') === 'true' || false,
  hasPendingSubscription:
    storage.get('hasPendingSubscription') === 'true' || false,
  hasSuspendedSubscription:
    storage.get('hasSuspendedSubscription') === 'true' || false,
  hasSubscription: storage.get('hasSubscription') === 'true' || false,
  hasSubscriptionExpired:
    storage.get('hasSubscriptionExpired') === 'true' || false,
  hasTrialExpired: storage.get('hasTrialExpired') === 'true' || false,
  hasTrialSubscription: storage.get('hasTrialSubscription') === 'true' || false,
  hasPastDuePayment: storage.get('hasPastDuePayment') === 'true' || false,
  subscriptionType: storage.get('subscriptionType') === 'true' || false,
  isAdmin: storage.get('isAdmin') === 'true' || false,
  isAgent: storage.get('isAgent') === 'true' || false,
  isAuthenticated: Boolean(storage.get('token')) || false,
  isBillingAdmin: storage.get('isBillingAdmin') === 'true' || false,
  isConcierge: storage.get('isConcierge') === 'true' || false,
  isDemo: storage.get('isDemo') === 'true' || false,
  isDeveloper: storage.get('isDeveloper') === 'true' || false,
  isOnboarding: storage.get('isOnboarding') === 'true' || null,
  isRealmAdmin: storage.get('isRealmAdmin') === 'true' || false,
  isRealmExecutive: storage.get('isRealmExecutive') === 'true' || false,
  isRenewing: false,
  loading: false,
  referralAgent: null,
  lifestyles: JSON.parse(storage.get('lifestyles')) || null,
  allTags: JSON.parse(storage.get('allTags')) || null,
  clientFilters: {},
  searchSettings: JSON.parse(storage.get('searchSettings')) || null,
  subscription: JSON.parse(storage.get('subscription')) || null,
  tags: JSON.parse(storage.get('tags')) || {},
  token: storage.get('token') || '',
  refreshToken: storage.get('refreshToken') || '',
  totalBrokerages: Number.parseInt(storage.get('totalBrokerages'), 10) || 0,
  myGroups: JSON.parse(storage.get('groups')) || false,
  selectedGroup: JSON.parse(storage.get('selectedGroup')) || false,
  pid: JSON.parse(storage.get('pid')) || '',
  usePublicListings: storage.get('usePublicListings') === 'true' || false,
  checkTokenStatus: '',
};

const checkForOnboarding = payload => {
  const { person, roles } = payload;
  if (!person || !roles) {
    return false;
  }
  const isAgent = roles.includes('agent');
  if (isAgent && !person?.realmData?.userAccount?.onBoardedAgentAt) {
    storage.put('isOnboarding', 'true');
    return true;
  }
  return false;
};

const actionHandlers = {
  [types.ACCEPT_COOKIES]: () => ({ acceptCookies: true }),
  [types.AUTHENTICATE_PENDING]: () => ({ loading: true }),
  [types.AUTHENTICATE_SUCCESS]: (_, action) => ({
    amityToken: action.payload.amityToken,
    currentUser: action.payload.user,
    isAuthenticated: true,
    isAdmin: getRoles(action.payload, 'admin'),
    isAgent: getRoles(action.payload, 'agent'),
    isBillingAdmin: getRoles(action.payload, 'billingAdmin'),
    isConcierge: getRoles(action.payload, 'concierge'),
    isDemo: getRoles(action.payload, 'demo'),
    isRealmAdmin: getRoles(action.payload, 'realmAdmin'),
    loading: false,
    token: action.payload.token,
    refreshToken: action.payload.rememberMe ? action.payload.refreshToken : undefined,
  }),
  [types.FETCH_AMITY_TOKEN]: (_, action) => ({
    amityToken: action.payload.amityToken,
  }),
  [types.AUTHENTICATE_REJECTED]: (_, action) => ({
    authenticationFailed: true,
    errorMessage: action.payload.response
      ? action.payload.response.data.msg.message ||
        action.payload.response.data.msg
      : action.payload.message,
    loading: false
  }),
  [types.AUTHENTICATE_RETRY]: () => ({
    authenticationFailed: false,
    errorMessage: ''
  }),
  [types.CLEAR_SESSION]: () => ({
    agentData: null,
    allTags: null,
    amityToken: null,
    apiError: false,
    authenticationFailed: false,
    avatar: null,
    brokerages: null,
    currentPerson: null,
    currentUser: null,
    dashboardSettings: null,
    dashboardRotation: null,
    errorMessage: '',
    hasCancelledSubscription: false,
    hasPendingSubscription: false,
    hasSuspendedSubscription: false,
    hasSubscription: false,
    hasSubscriptionExpired: false,
    hasTrialExpired: false,
    hasTrialSubscription: false,
    hasPastDuePayment: false,
    subscriptionType: false,
    isAdmin: false,
    isAgent: false,
    isAuthenticated: false,
    isBillingAdmin: false,
    isConcierge: false,
    isDemo: false,
    isDeveloper: false,
    isOnboarding: false,
    isRealmAdmin: false,
    isTeamMemberSub: false,
    isTeamLeadSub: false,
    lifestyles: null,
    loading: false,
    pid: null,
    searchSettings: null,
    subscription: null,
    tags: {},
    token: '',
    refreshToken: '',
    usePublicListings: false
  }),
  [types.FETCH_AGENT_PENDING]: () => ({ loading: true, loaded: false }),
  [types.FETCH_AGENT_SUCCESS]: (state, action) => ({
    agentData: action.payload,
    apiError: false,
    avatar: state.avatar || action.payload.photoURL,
    isDeveloper: action.payload.currentBrokerage.isDeveloper,
    loading: false
  }),
  [types.FETCH_AGENT_REJECTED]: (_, action) => ({
    apiError: true,
    errorMessage: action.payload.response
      ? action.payload.response.data.msg.message
      : action.payload.message,
    loading: false
  }),
  [types.FETCH_SEARCH_PENDING]: () => ({ loading: true, loaded: false }),
  [types.FETCH_SEARCH_SUCCESS]: (state, action) => ({
    searchSettings: action.payload.searchSettings.data,
    loading: false
  }),
  [types.FETCH_SEARCH_REJECTED]: (_, action) => ({
    apiError: true,
    errorMessage: action.payload.response
      ? action.payload.response.data.msg.message
      : action.payload.message,
    loading: false
  }),
  [types.FETCH_DASHBOARD_PENDING]: () => ({ loading: true, loaded: false }),
  [types.FETCH_DASHBOARD_SUCCESS]: (state, action) => ({
    dashboardSettings: action.payload.dashboardSettings.data,
    dashboardRotation: action.payload.dashboardRotation,
    loading: false
  }),
  [types.NEXT_DASHBOARD]: (state, action) => ({
    dashboardRotation: action.payload
  }),
  [types.FETCH_DASHBOARD_REJECTED]: (_, action) => ({
    apiError: true,
    errorMessage: action.payload.response
      ? action.payload.response.data.msg.message
      : action.payload.message,
    loading: false
  }),
  [types.FETCH_BROKERAGES_PENDING]: () => ({
    loading: true,
    loaded: false
  }),
  [types.FETCH_BROKERAGES_SUCCESS]: (state, action) => ({
    brokerages: action.payload.brokerages,
    totalBrokerages: action.payload.totalBrokerages,
    apiError: false,
    loading: false
  }),
  [types.FETCH_BROKERAGES_REJECTED]: (_, action) => ({
    apiError: true,
    errorMessage: action.payload.response
      ? action.payload.response.data.msg.message
      : action.payload.message,
    loading: false
  }),
  [types.FETCH_SETTINGS_SUCCESS]: (state, action) => ({
    apiError: false,
    maintenanceMode: action.payload.maintenanceMode,
    systemMessage: action.payload.message,
    showAmitySocial: action.payload.showAmitySocial,
    showChat: action.payload.showChat,
    showTribe: action.payload.showTribe,
    showTeamSubscription: action.payload.showTeamSubscription,
    usePublicListings: action.payload.usePublicListings,
    loading: false
  }),
  [types.FETCH_LIFESTYLES_PENDING]: () => ({ loading: true, loaded: false }),
  [types.FETCH_LIFESTYLES_SUCCESS]: (state, action) => ({
    apiError: false,
    allTags: action.payload.allTags,
    lifestyles: action.payload.lifestyles,
    tags: action.payload.tags,
    loading: false
  }),
  [types.FETCH_LIFESTYLES_REJECTED]: (_, action) => ({
    apiError: true,
    errorMessage: action.payload.response
      ? action.payload.response.data.msg.message
      : action.payload.message,
    loading: false
  }),
  [types.FETCH_PERSON_PENDING]: () => ({ loading: true, loaded: false }),
  [types.FETCH_PERSON_SUCCESS]: (state, action) => {
    const subscription = getSubscriptionRecord(action.payload.subscriptions);
    const hasRealmData =
      action.payload.person && action.payload.person.realmData;
    return {
      apiError: false,
      avatar:
        hasRealmData && hasRealmData.photoUrl
          ? hasRealmData.photoUrl
          : state.avatar,
      currentPerson: action.payload.person,
      currentUser: action.payload.impersonation
        ? action.payload
        : state.currentUser,
      hasCancelledSubscription:
        !isEmpty(subscription) && subscription.status === 'Cancelled',
      hasSuspendedSubscription:
        !isEmpty(subscription) && subscription.status === 'Suspended',
      hasSubscription: !isEmpty(subscription),
      hasSubscriptionExpired:
        !isEmpty(subscription) && subscription.endsAt
          ? new Date(subscription.endsAt).getTime() < Date.now()
          : false,
      hasTrialExpired:
        !isEmpty(subscription) && subscription.trialExpires
          ? new Date(subscription.trialExpires).getTime() < Date.now()
          : false,
      hasTrialSubscription:
        !isEmpty(subscription) && subscription.status === 'Trial',
      hasPastDuePayment:
        !isEmpty(subscription) && subscription.status === 'Past_due',
      isTeamLeadSub: action.payload.isTeamLeadSub,
      isTeamMemberSub: action.payload.isTeamMemberSub,
      subscriptionType: !isEmpty(subscription)
        ? subscription.membershipModel
        : null,
      isOnboarding: checkForOnboarding(action.payload),
      loading: false,
      subscription,
      myGroups: action.payload.groups || false
    };
  },
  [types.FETCH_PERSON_REJECTED]: (_, action) => ({
    apiError: true,
    errorMessage: action.payload.message,
    loading: false
  }),
  [types.UPDATE_CLIENT_FILTERS]: (state, action) => ({
    clientFilters: action.payload
  }),
  [types.UPDATE_PERSON_SUCCESS]: (state, action) => ({
    currentPerson: action.payload
  }),
  [types.UPDATE_TAGS_SUCCESS]: (state, action) => {
    const newObj = {};
    newObj[action.payload.category] = action.payload.tags;
    const newTagState = { ...state.tags, ...newObj };
    return {
      tags: newTagState
    };
  },
  [types.UPDATE_TAGS_PENDING]: () => ({ loading: true, loaded: false }),
  [types.UPDATE_TAGS_REJECTED]: (_, action) => ({
    apiError: true,
    errorMessage: action.payload.message,
    loading: false
  }),
  [types.COMPLETE_ONBOARDING]: () => ({
    hasSubscription: true,
    isOnboarding: false,
    isRenewing: false
  }),
  [types.CHECK_SUBSCRIPTION]: state => ({
    hasSubscriptionExpired:
      new Date(state.subscription.endsAt).getTime() > Date.now()
  }),
  [types.END_PENDING_SUBSCRIPTION]: () => ({
    hasPendingSubscription: false
  }),
  [types.SET_PENDING_SUBSCRIPTION]: () => ({
    hasPendingSubscription: true
  }),
  [types.SUBSCRIPTION_EXPIRED]: () => ({
    hasSubscriptionExpired: true
  }),
  [types.REONBOARD]: () => ({
    hasSubscription: false,
    isOnboarding: true,
    isRenewing: true
  }),
  [types.CHECK_TRIAL_SUBSCRIPTION]: state => ({
    hasTrialExpired:
      new Date(state.subscription.trialExpires).getTime() > Date.now()
  }),
  [types.UPDATE_SUBSCRIPTION]: (_, action) => {
    const { payload: subscription } = action;
    return {
      hasSubscription: !isEmpty(subscription),
      hasSubscriptionExpired:
        !isEmpty(subscription) && subscription.endsAt
          ? new Date(subscription.endsAt).getTime() < Date.now()
          : false,
      hasTrialExpired:
        !isEmpty(subscription) && subscription.trialExpires
          ? new Date(subscription.trialExpires).getTime() < Date.now()
          : false,
      hasTrialSubscription:
        !isEmpty(subscription) && subscription.status === 'Trial',
      hasPastDuePayment:
        !isEmpty(subscription) && subscription.status === 'Payment_due',
      subscriptionType: !isEmpty(subscription)
        ? subscription.membershipModel
        : null,
      subscription
    };
  },
  [types.CLEAR_PASTDUE_PAYMENT]: () => ({ hasPastDuePayment: false }),
  [types.SET_GROUP]: (state, action) => ({
    selectedGroup: action.payload
  }),
  [types.UPDATE_PLACE]: (state, action) => ({
    pid: action.payload
  }),
  [types.OPEN_REFERRAL_DIALOG]: (state, action) => ({
    referralAgent: action.payload
  }),
};

export default createReducer(initialState, actionHandlers);
