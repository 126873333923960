import axios from 'axios';
import { API_ROOT } from '../constants';
import * as storage from '../persistence/storage';

const token = storage.get('token') || '';
const refreshToken = storage.get('refreshToken') || '';

const api = axios.create({
  baseURL: API_ROOT,
  timeout: 24000
});
// api.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     console.log(error.toJSON())
//     if (error && error.message.includes('timeout')){
//       error.message = 'Your request cannot be processed at this time. Please try again later. Sorry for the inconvenience'
//     }
//     console.log(error)
//     return Promise.reject(error)
//   }
// );

api.interceptors.response.use(
  response => response,
  async error => {
    if (error.response.status === 403) {
      try {
        const response = await api.post(`${API_ROOT}/user/checkToken`, {}, {
          headers: {
            'X-Token': storage.get('token') || '',
            'X-Refresh-Token': storage.get('refreshToken') || '',
          }
        });

        if (response.status === 200) {
          const { token, refreshToken } = response.data;

          storage.put('token', token)
          storage.put('refreshToken', refreshToken)
          api.defaults.headers.common['X-Token'] = token;
          api.defaults.headers.common['X-Refresh-Token'] = refreshToken;
          error.config.headers['X-Token'] = token;
          error.config.headers['X-Refresh-Token'] = refreshToken;
          return axios.request(error.config);
        }
      } catch (checkTokenError) {
        console.warn(checkTokenError);
        storage.clear();
        window.location.assign('/login');
      }
    }

    return Promise.reject(error);
  }
)

if (token) {
  api.defaults.headers.common['X-Token'] = token;
}

if (refreshToken) {
  api.defaults.headers.common['X-Refresh-Token'] = refreshToken;
}

api.updateToken = () => {
  const token = storage.get('token') || '';
  const refreshToken = storage.get('refreshToken') || '';

  if (token) {
    api.defaults.headers.common['X-Token'] = token;
  }
  if (refreshToken) {
    api.defaults.headers.common['X-Refresh-Token'] = refreshToken;
  }
}

api.setToken = ({ token: newToken, refreshToken: newRefreshToken }) => {
  if (newToken) {
    api.defaults.headers.common['X-Token'] = newToken;
  }
  if (newRefreshToken) {
    api.defaults.headers.common['X-Refresh-Token'] = newRefreshToken;
  }
}

export default api;
