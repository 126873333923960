/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash.isfunction';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '@material-ui/core';

import LoadingIndicator from '../LoadingIndicator';

import api from '../../api';
import { API_ROOT } from '../../constants';

class ContentDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: null,
      errorMessage: '',
      isLoading: false
    };
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.props.open && this.loadContent();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadContent = async () => {
    const { apiType, language, slug } = this.props;
    const endpoint =
      apiType === 'fullPost'
        ? `${API_ROOT}/cms/full-post`
        : `${API_ROOT}/cms/content`;
    const params =
      apiType === 'fullPost'
        ? {
            slug: `${slug}-${language}`,
            language
          }
        : {
            site: 'marketing',
            section: 'company',
            content: slug,
            language
          };
    this.setState({ isLoading: true });
    try {
      const response = await api.get(endpoint, { params });
      const data = response.data.data.data[0];
      const {
        content: { rendered: htmlContent },
        title: { rendered: htmlTitle }
      } = data;
      this.setState({
        content: {
          htmlContent,
          htmlTitle
        },
        isLoading: false
      });
    } catch (err) {
      this.setState({
        errorMessage: `Content failed to load: ${err}`,
        isLoading: false
      });
    }
    return null;
  };

  hideContentDialog = () => {
    this.setState({
      content: null,
      errorMessage: '',
      isLoading: false
    });
    if (isFunction(this.props.onClose)) {
      this.props.onClose();
    }
  };

  renderTitle = () => {
    if (this.state.content) {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: this.state.content.htmlTitle
          }}
        />
      );
    }
    return '';
  };

  renderContent = () => {
    if (this.state.errorMessage) {
      return <p className="text-centered">{this.state.errorMessage}</p>;
    }
    if (this.state.content) {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: this.state.content.htmlContent
          }}
        />
      );
    }
    return null;
  };

  render() {
    return (
      <Dialog
        className="realm-onboarding--dialog"
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="md"
        open={this.props.open}
      >
        <DialogTitle>
          {this.state.isLoading ? 'Loading ...' : this.renderTitle()}
        </DialogTitle>
        <DialogContent className="realm--modal-content">
          {this.state.isLoading ? <LoadingIndicator /> : this.renderContent()}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.hideContentDialog}
            color="primary"
            variant="contained"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ContentDialog.propTypes = {
  apiType: PropTypes.oneOf(['fullPost', 'content']),
  language: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  slug: PropTypes.string
};

ContentDialog.defaultProps = {
  apiType: 'content',
  language: 'en',
  open: false
};

export default ContentDialog;
