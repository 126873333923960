import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import application from './application';
import session from './session';
import drawer from './drawer';
import propertiesReducer from './properties';

const createRootReducer = history =>
  combineReducers({
    application,
    router: connectRouter(history),
    session,
    drawer,
    properties: propertiesReducer,
  });

export default createRootReducer;
