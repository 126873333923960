import React, { Component } from 'react';
import { AmityUiKitRecentChat } from '@rg-npmjs-dev/rg-amity-social-ui';
import trackSocialEvent from '../../utils/event-tracking-social';

const Chat = (props) => {
  const divStyle = props.isMobile ?
  {
    position: 'absolute', top: -4, height: 0
  }
  :
   {
    position: 'absolute', right: 0, bottom: 0, zIndex: 1000
  };
  return (
    <div style={divStyle}>
      <AmityUiKitRecentChat 
        setUnreadChats={props.setUnreadChats}  
        trackSocialEvent={trackSocialEvent} 
      />
    </div>
  )
}

export default Chat;
