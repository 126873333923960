import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import isFunction from 'lodash.isfunction';
import * as storage from '../../persistence/storage';
import LoadingIndicator from '../../components/LoadingIndicator';

class Logout extends Component {
  _delay = null;

  componentDidMount() {
    this._delay = setTimeout(() => {
      storage.clear();
      if (this.props.session.acceptCookies) {
        storage.put('acceptCookies', this.props.session.acceptCookies);
      }
      this.props.actions.clearApplication();
      this.props.actions.clearSession();
      if (isFunction(this.props.clearSubscribeLink)) {
        this.props.clearSubscribeLink();
      }
      window.location.reload(true);
    }, 1350);
  }

  componentWillUnmount() {
    clearTimeout(this._delay);
    this._delay = null;
  }

  render() {
    return (
      <div
        className="realm--logout-screen"
        style={{ background: this.props.bg ? 'transparent' : undefined }}
      >
          <h1>Thank you for visiting REALM</h1>
          <div className="realm--loading-container">
            <LoadingIndicator />
          </div>
          <div>Signing out ...</div>
      </div>
    );
  }
}

Logout.propTypes = {
  actions: PropTypes.object,
  bg: PropTypes.string,
  push: PropTypes.func,
  session: PropTypes.object
};

export default connect(null, { push })(Logout);
