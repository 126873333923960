import * as types from './types';

export const openDrawer = () => ({
  type: types.OPEN_DRAWER,
})

export const closeDrawer = () => ({
  type: types.CLOSE_DRAWER,
})

export const toggleDrawer = () => ({
  type: types.TOGGLE_DRAWER,
})

export const stickDrawer = () => ({
  type: types.STICK_DRAWER,
})

export const unstickDrawer = () => ({
  type: types.UNSTICK_DRAWER,
})

export const toggleDrawerStick = () => ({
  type: types.TOGGLE_DRAWER_STICK,
})
